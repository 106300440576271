import { AJAX_POST, AJAX_GET, AJAX_PUT, AJAX_DELETE } from '../helpers/ajax'
import {httpReq} from "@/htttpReq";

const status = {
  SUCCESS: 'success',
  ERROR: 'error',
  LOADING: 'loading'
}

export default {
    status,
    async getDiscountTypes() {
      return await AJAX_GET('discount-types')
    },

}
