
<template>
    <teleport to="#sticky-footer">
    <!-- :style="isOpen? 'overflow: unset;': ''" -->
        <div class="sticky-footer tw-fixed tw-bottom-0 tw-w-full" :class="props.elementClass" ref="el">
            <slot></slot>
        </div>
    </teleport>
</template>

<script setup>
import { onUnmounted, ref, defineProps, onMounted } from 'vue';
import _ from 'lodash'
const props = defineProps({
  offset: {
    type: Number,
    default: 70
  },
  elementClass: {
    type: String,
    default: ''
  }
})

const el = ref()

onMounted(() => {
  let elHeader = document.querySelector("#sticky-footer-ref");
  let observer = new IntersectionObserver(
      (entries) => {
        if (!entries[0].isIntersecting) {
          if (el.value) {
            el.value.classList.add('tw-fixed')
            el.value.classList.remove('tw-relative')
          }
        } else {
          if (el.value) {
            el.value.classList.remove('tw-fixed')
            el.value.classList.add('tw-relative')
          }
        }
      },
      {
        threshold: 0.01,
      }
  );
  observer.observe(elHeader);
})

</script>

<style lang="scss">
    .sticky-footer {
        background: white;
        box-shadow: 0px 0px 10px rgba(28, 40, 51, 0.3);
    }
</style>
