<template>
    <div class="summary">
        <template v-if="canSeePrice">
            <div class="summary__title">Order Summary</div>
            <div class="summary__content">
                <div class="summary__left">
                    <Receipt :summary="summary" v-if="summary"/>
                </div>
            </div>
        </template>
        <StickyFooter elementClass="order-summary-sticky">
            <div class="st-footer container">
                <div class="tw-flex tw-gap-0 md:tw-gap-2">
                    <div v-if="extraActions.length" class=" tw-relative">
                        <div v-if="showExtraActionPanel" class="panel-action" v-click-outside="() => showExtraActionPanel = false">
                            <Buttons
                                :data="extraActions"
                                :key="`actions-${extraActions.length}`"
                                v-if="extraActions"
                                @acceptBtnClick="$emit('acceptBtnClick')"
                                @rejectBtnClick="$emit('rejectBtnClick')"
                                @cloneBtnClick="$emit('cloneBtnClick')"
                                @deleteBtnClick="$emit('deleteBtnClick')"
                                @specificationBtnClick="$emit('specificationBtnClick')"
                                @mainBtnClick="$emit('mainBtnClick')"
                                @addnewitemBtnClick="$emit('addnewitemBtnClick')"
                                @clickAddANewAccessoryBtn="$emit('clickAddANewAccessoryBtn')"
                                @submitorderBtnClick="$emit('submitorderBtnClick')"
                                @bulkeditBtnClick="$emit('bulkeditBtnClick')"
                                @bulkDeleteBtnClick="$emit('bulkDeleteBtnClick')"
                                @converttojobBtnClick="$emit('convertToJobBtnClick')"
                                @placeorderBtnClick="$emit('placeOrderBtnClick')"
                                @sendestimateBtnClick="$emit('sendestimateBtnClick')"
                                @sendquoteBtnClick="$emit('sendquoteBtnClick')"
                                @cancelBtnClick="$emit('cancelBtnClick')"
                                @additemBtnClick="$emit('additemBtnClick')"
                                @addaccessoryBtnClick="$emit('clickAddAccessoryBtn')"
                                class="tw-flex"
                            />
                        </div>
                        <div class="btn-action tw-h-full text-primary" :class="{'highlight': highlightBution}" @click="showExtraActionPanel = !showExtraActionPanel">
                            <svg-icon-qouting data="fas fa-ellipsis-h" />
                        </div>
                    </div>
                    <Buttons
                        :data="mainActions"
                        :key="`actions-${mainActions.length}`"
                        v-if="mainActions"
                        class="summary-action-buttons-wrapper tw-flex tw-gap-1.5 tw-flex-1"
                        @acceptBtnClick="$emit('acceptBtnClick')"
                        @rejectBtnClick="$emit('rejectBtnClick')"
                        @cloneBtnClick="$emit('cloneBtnClick')"
                        @deleteBtnClick="$emit('deleteBtnClick')"
                        @specificationBtnClick="$emit('specificationBtnClick')"
                        @mainBtnClick="$emit('mainBtnClick')"
                        @additemBtnClick="$emit('additemBtnClick')"
                        @addaccessoryBtnClick="$emit('clickAddAccessoryBtn')"
                        @bulkeditBtnClick="$emit('bulkeditBtnClick')"
                        @bulkDeleteBtnClick="$emit('bulkDeleteBtnClick')"
                        @sendestimateBtnClick="$emit('sendestimateBtnClick')"
                        @sendquoteBtnClick="$emit('sendquoteBtnClick')"
                        @submitorderBtnClick="$emit('submitorderBtnClick')"
                        @converttojobBtnClick="$emit('convertToJobBtnClick')"
                        @placeorderBtnClick="$emit('placeOrderBtnClick')"
                        @cancelBtnClick="$emit('cancelBtnClick')"
                    />
                    <div class="tw-ml-auto">
                        <Dropdown title="" :summary="summary" v-if="summary && canSeePrice" :isHideChevron="isHideChevron" />
                        <div v-else class="tw-h-12"></div>
                    </div>

                </div>
            </div>
        </StickyFooter>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import Receipt from './summary/Receipt'
import Dropdown from './header/Dropdown.vue'
import Buttons from '../elements/Buttons.vue'
import { ref, computed, watch, nextTick, onMounted } from 'vue'
import StickyFooter from '../../elements/StickyFooter.vue'
import _ from 'lodash'
export default ({
    props: ['summary', 'buttons', 'data', 'quantityItems', 'isEditable', 'canSeePrice'],
    components: {
        Buttons,
        Receipt,
        StickyFooter,
        Dropdown,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    // mounted() {
    //     window.addEventListener('resize', reportWindowSize);
    // },
    async mounted() {
        this.reportWindowSize()
    },
    setup(props) {
      const key = ref(0)
      const actions = computed(() => {
        let array = props.buttons.map(el => el)
        array.forEach((el) => el.event = (el.action === 'SEND_ESTIMATE' ? 'sendestimate' : el.name && el.name.split(' ').join('').toLowerCase()) + 'BtnClick')
        return array
      })
      const isHideChevron = ref(props.summary.hasOwnProperty('Offered price'))
        getButtons()

        function getButtons() {

            const mainBtn = props.buttons.find(el => el.type == 'main')
            const cloneBtn = props.buttons.find(el => el.name.toLowerCase().includes('clone'))
            const deleteBtn = props.buttons.find(el => el.name.toLowerCase().includes('delete'))
            const addANewAccessoryBtn = props.buttons.find(el => el.name.toLowerCase().includes('add accessory'))
            const convertToJobBtn = props.buttons.find(el => el.name.toLowerCase().includes('convert to job'))
            const placeOrderJobBtn = props.buttons.find(el => el.name.toLowerCase().includes('place order'))
            const object = {}
            if(mainBtn) object.mainBtn = { ...mainBtn, event: 'clickMainBtn' }
            if(cloneBtn) object.cloneBtn = { ...cloneBtn, event: 'clickCloneBtn' }
            if(deleteBtn) object.deleteBtn = { ...deleteBtn, event: 'clickDeleteBtn', type: 'outline-primary' }
            if(addANewAccessoryBtn) object.addANewAccessoryBtn = { ...cloneBtn, event: 'clickAddANewAccessoryBtn' }
            if(convertToJobBtn) object.convertToJobBtn = { ...convertToJobBtn, event: 'convertToJobBtnClick' }
            if(placeOrderJobBtn) object.placeOrderJobBtn = { ...placeOrderJobBtn, event: 'placeOrderJobBtnClick' }
            // icon: 'fas fa-trash-alt', iconColor: 'rgba(11, 53, 83, 0.8)',
            actions.value = object
        }

        watch(() => props.isEditable, (val) => {
          if(!val) key.value ++
        })

        watch(() => props.buttons, (val) => {
            reportWindowSize()
        })

        const mainActions = ref([])
        const extraActions = ref([])

        const getElementWidth = (el, container = false) => {
            let cStyle = el.currentStyle || window.getComputedStyle(el),
                    cWidth = parseFloat(cStyle.width),//el.offsetWidth, // or use style.width
                    cMargin = parseFloat(cStyle.marginLeft) + parseFloat(cStyle.marginRight),
                    cPadding = parseFloat(cStyle.paddingLeft) + parseFloat(cStyle.paddingRight),
                    cBorder = parseFloat(cStyle.borderLeftWidth) + parseFloat(cStyle.borderRightWidth);
            let result = cWidth + (container? 0: cMargin) + cBorder + (container? cPadding * -1 : 0)
            return result
        }

        const reportWindowSize = _.throttle(async () => {
            const w = window.innerWidth
            let result = []
            result.push(...actions.value.filter(x => x.type === 'main'))
            result.push(...actions.value.filter(x => x.type === 'primary'))
            result.push(...actions.value.filter(x => x.type === 'secondary'))
            result.push(...actions.value.filter(x => x.type === 'additional'))
            result.push(...actions.value.filter(x => !['additional','secondary', 'primary', 'main'].includes(x.type)))


            if(w <= 768) {
                mainActions.value = result.slice(0,1)
                extraActions.value = result.slice(1)
                return
            }

            mainActions.value = result
            extraActions.value = []

            await nextTick()
            const c = document.querySelector('.summary-action-buttons-wrapper')
            c.classList.add('tw-invisible')

            setTimeout(async () => {
                 await nextTick()
                 const container = document.querySelector('.summary-action-buttons-wrapper')
                if(!container) return

                let maxW = getElementWidth(container, true)


                const btns = Array.from(document.querySelectorAll('.summary-action-buttons-wrapper > button'))
                const btnsWidth = btns.reduce((prevVal, cNode) => prevVal + getElementWidth(cNode) , 0)
                if(btnsWidth < maxW) {
                    container.classList.remove('tw-invisible')
                    return
                }
                maxW = maxW - 50 - 15 - 10 // Remove the container gap and the dot action button
                const main = []
                const ext = []
                let currentWidth = 0
                btns.forEach((btn, i) => {

                    let eleWidth = getElementWidth(btn)
                    currentWidth = currentWidth + eleWidth

                    if(currentWidth > maxW) {
                        ext.push(result[i])
                    }else {
                        main.push(result[i])
                    }
                })

                mainActions.value = main
                extraActions.value = ext
                container.classList.remove('tw-invisible')

            }, 400)

        },300)

        window.addEventListener('resize', reportWindowSize);

        const showExtraActionPanel = ref(false)

        const highlightBution = computed(() => {
            return !!extraActions.value.find(x => x.name === 'Bulk Edit')
        })

        return {
            highlightBution,
            actions,
            key,
            mainActions,
            extraActions,
            showExtraActionPanel,
            reportWindowSize,
          isHideChevron,
        }
    },
})
</script>

<style lang="scss">
    .order-summary-sticky.sticky-footer {
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;

    }
</style>

<style scope lang="scss">

.st-footer {
    .summary-action-buttons-wrapper {
        // overflow: hidden;
        max-height: 50px;
    }
    .btn {
        svg {
            color: black!important;
        }

        &.btn_main, &.btn_primary{
            color: var(--PrimaryButtonTextColour);
            svg {
                color: var(--PrimaryButtonTextColour)!important;
            }
        }

        &.btn_secondary{
            color: var(--PrimaryButtonColour);
            border-color: var(--PrimaryButtonColour)!important;
            svg {
                color: var(--PrimaryButtonColour)!important;
            }
        }
    }

    @media(max-width: 768px) {
        padding-left: 0;
        background: -webkit-gradient(linear, left bottom, left top, from(#0B3553), to(#0B3553)), #FFFFFF;
        background: linear-gradient(0deg, #0B3553, #0B3553), #FFFFFF;

        .dropdown__btn .btn {
                color: white;
        }
        .btn {
            border: none!important;
            border-radius: 0;
            gap: 10px;
            color: black;

            .icons__wrapper.btn__icon {
                margin: 0;
            }

            // color: var(--SecondaryTextColour);

            &.btn_main, &.btn_primary {
                color: var(--PrimaryButtonColour);
                svg {
                    color: var(--PrimaryButtonTextColour)!important;
                }
            }
            &.btn_clone {
                svg {
                    color: black!important;
                }
            }
        }
        .panel-action {
            .actions {
                .btn {
                    width: auto;
                    padding: revert;
                    justify-content: flex-start;
                    background: transparent;
                    // color: black;
                    color: var(--PrimaryColour);
                    border: none;
                    underline: none;
                    &.btn_additional {
                        padding: revert;
                    }
                    .btn__title {
                        display: block;
                    }

                    svg {
                        // color: black!important;
                        color: var(--PrimaryColour);
                    }
                }
            }

        }
    }


    .actions {
        .btn {
            height: 30px!important;
            margin-right: 0;
            width: auto;
        }

        @media(max-width: 768px) {
            padding-left: 0;
            gap: 0;
            .btn {
                min-width: auto;
                height: 100%!important;
                padding: 0;
                width: 50px;
                background: var(--PrimaryButtonColour);

                .btn__title {
                    display: none;
                }
            }
        }
    }

    .dropdown {
        width: auto;
    }
    .dropdown__items {
        top: auto;
        bottom: 100%;
        width: max-content;
        right: 0;
        max-width: 253px;
    }
}

.btn-action {
    border: none;
    background: rgba(11, 53, 83, 0.3);
    color: white;
    width: 50px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.3), rgba(11, 53, 83, 0.3)), #FFFFFF;
    cursor: pointer;
    transition: all .2s linear;

    &.highlight {
        background: #F1C40F;
        &:hover {
            background: rgba(241, 196, 15,0.5);
        }
    }

    &:hover {
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.2), rgba(11, 53, 83, 0.2)), #FFFFFF;
    }

    &.text-primary{
      svg{
        color: var(--PrimaryColour);
      }
    }
}

.panel-action {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    padding: 10px;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.2), rgba(11, 53, 83, 0.2)), #FFFFFF;
    // padding-right: 0;
    .actions {
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: initial;
        .btn {
            justify-content: flex-start;
            align-items: center;
            border: none;
            color: var(--PrimaryColour);
            &.btn_additional {
                //padding: 10px 34px;
            }
            .btn__title{
              text-decoration: underline;

            }

            svg{
              color: var(--PrimaryColour) !important;
            }
        }
    }
}

.summary {
    position: relative;
    &__content {
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    &__left {
        width: 350px;
        max-width: 100%;
    }
    &__primary {
      margin-right: 12px;
      margin-bottom: 10px;
    }
    &__title {
        font-weight: bold;
        font-size: 21px;
        line-height: 140%;
        text-align: left;
        margin-bottom: 30px;
        position: relative;
        font-family: Sansation;
    }
    &__btns {
        display: flex;
    }
    &__actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        & > div {
            margin-bottom: 20px;
        }
    }
    .btn {
        &__main {
            width: 251px;
            height: 60px;
            margin-bottom: 40px;
        }
        &__secondary {
            width: 160px;
            height: 30px;
            margin-right: 30px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

@media(max-width: 768px) {
    .summary {
        &__content {
            flex-direction: column;
        }
        &__actions {
            margin-top: 20px;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
</style>
