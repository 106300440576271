<script setup>
import { ref, defineProps, computed } from 'vue'
import  { useRoute, useRouter } from 'vue-router'
import { bulkEditStore } from './store'
import { useFormatter } from './../../../composables/formatter'
import { useOptionLogic } from './../../../composables/estimate/create'
import { useStore } from 'vuex'

const props = defineProps({
    item: Object,
    customerType: String,
    area: String,
})

const store = useStore()

const showPrice = computed(() => store.getters['auth/showPrice'](props.area))

const optionLogic = useOptionLogic({})

const route = useRoute()

const formatter  = useFormatter()

const isBusy = ref(false)

async function setInitialData() {
    isBusy.value = true
    const params = {
        position_id: props.item.section_items[0].id,
        is_additional_accessory: false,
        location: 'bedroom 1'
    }

    if(props.customerType == 'dealer') params.dealer_order_id = route.params.id
    else params.customer_order_id = route.params.id

    await bulkEditStore.setInitialData(props.item.id, params)
    isBusy.value = false
}

setInitialData()



const showWizard = (item) => {
    if(!clickable.value) return
    bulkEditStore.selectItem(item)
}

const getName = (val) => {
    const { width, height, item } = val
    return `${width} x ${height} ${item.product_type.name}`
}

const clickable = computed(() => {
    return props.item.initial_data?.current_step?.id === bulkEditStore.currentStepId && (props.item.initial_data?.current_step?.option_code || '') === bulkEditStore.currentOptionCode
})


const currentPrice = computed(() => {
    // if(props.item.currentPrice) return props.item.currentPrice
    const sizeStep = props.item.initial_data?.steps?.find(el => el.name == 'Size')
    if(!sizeStep) return
    const sections = sizeStep?.data?.sections
    const sectionsPrice = optionLogic.getPrice(sections, false)

    if(!props.item.initial_data.dry_price) return 0
    let value = 0
    if(Array.isArray(sectionsPrice)) {
        sectionsPrice.forEach(section => {
        for(const key in section) {
            value += section[key]
        }
        })
    }
    return props.item.initial_data.dry_price + value
})

</script>

<template>
    <div class="tw-bg-gray-100- tw-shadow tw-p-2 tw-text-left tw-border-l-4 tw-border-solid tw-border-transparent" @click="showWizard(item)" :class="[{'tw-bg-gray-100': item.selected, 'tw-animate-pulse': isBusy || bulkEditStore.loadingData}, [(item.selectionAvailable !== undefined && !item.selectionAvailable)? 'tw-border-yellow-500': '', item.resolved? 'tw-border-green-500': '', !clickable? 'tw-opacity-60': 'tw-cursor-pointer']]">
        <div class="tw-inline-block tw-text-yellow-500">
            <span v-if="item.selectionAvailable !== undefined && !item.selectionAvailable" class="tw-mr-2">
                <svg-icon-qouting data="fas fa-triangle-exclamation" />
            </span>

            <span v-if="item.selectionAvailable && !item.resolved && bulkEditStore.activeScreen === 'itemWizard'" class="tw-mr-2">
                <svg-icon-qouting data="fas fa-spinner" />
            </span>

            <span v-if="item.resolved" class="tw-text-green-500 tw-mr-2">
                <svg-icon-qouting data="fas fa-circle-check" style="font-size: 11px;"/>
            </span>
        </div>

        <span class="title">{{item.section_info?.layout?.name}}</span>
        <span class="tw-block description">{{ getName(item.section_items[0])}}</span>
        <div v-if="showPrice" class="tw-flex tw-justify-between">
            <span class="description">{{formatter.formatMoney(item.original_price)  }}</span>
            <span class="tw-flex tw-items-end">
                <span class="description tw-font-bold tw-mr-1">{{formatter.formatMoney(currentPrice)}}</span>

                <!-- <span class="tw-leading-normal" v-if="currentPrice === item.original_price">
                    <svg-icon-qouting data="fas fa-equals" style="font-size: 11px;"/>
                </span> -->
                <span class="tw-leading-normal" v-if="currentPrice > item.original_price">
                    <svg-icon-qouting data="fas fa-up" style="font-size: 11px;"/>
                </span>
                <span class="tw-leading-normal" v-if="currentPrice < item.original_price">
                    <svg-icon-qouting data="fas fa-down" style="font-size: 11px;"/>
                </span>
            </span>
        </div>
    </div>
</template>

<style scoped>
.description {
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    color: rgba(28, 40, 51, 0.8);
}

.title {
    font-size: 13px;
    width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
    text-align: left;
    font-family: Sansation;
}
</style>
