import { AJAX_POST, AJAX_GET, AJAX_PUT, AJAX_DELETE } from '../helpers/ajax'

const status = {
  SUCCESS: 'success',
  ERROR: 'error',
  LOADING: 'loading'
}

export default {
    status,
    async init(payload) {
        return await AJAX_POST('/product-builder/initialise', payload, this.getArgAuth())
    },
    async nextStep(payload) {
        return await AJAX_POST('/product-builder/next', payload, this.getArgAuth())
    },
    async setOptionColor(payload) {
        return await AJAX_PUT('/product-builder/option-colour', payload, this.getArgAuth())
    },
    async routeBack(payload) {
        return await AJAX_POST('/product-builder/back', payload, this.getArgAuth())
    },
    async routeJumpTo(id, payload) {
        return await AJAX_POST(`/product-builder/jump-to/${id}`, payload, this.getArgAuth())
    },
    async save(type, payload) {
        return await AJAX_POST(`/product-builder/${type}`, payload)
    },
    async getPackages(type_id, style_id, grade_id ) {
      return await AJAX_GET(`/product-builder/packages?type_id=${type_id}&style_id=${style_id}&grade_id=${grade_id}}`)
    },
    async getPackage(id) {
      return await AJAX_GET(`/product-builder/packages/${id}`)
    },
    async createPackage(payload) {
      return await AJAX_POST(`/product-builder/packages`, payload, this.getArgAuth())
    },
    async updatePackage(id, payload){
      return await AJAX_PUT(`/product-builder/packages/${id}`, payload, this.getArgAuth())
    },
    async deletePackage(id){
      return await AJAX_DELETE(`/product-builder/packages/${id}`, id, this.getArgAuth())
    },
    async getFromPrice(type, id, payload) {
        return await AJAX_POST(`/product-builder/from-price/${type}/${id}`, payload, this.getArgAuth())
    },
    async getColors(containerId, colorGroup, params) {
      let p = this.getArgAuth()
      if (params)
        p = params
        return await AJAX_GET(`/product-builder/${containerId || 0}/option/colours/${colorGroup}`, p)
    },
    async getColor(color) {
        return await AJAX_GET(`/core-general/colour/${color}`,'', true, true)
    },

    async cloneItem(area, type = 'position', id) {
        return await AJAX_POST(`/${area}/${type}/${id}/clone`)
    },
    getArgAuth() {
        const isQuoting = localStorage.getItem('isQuoting') && JSON.parse(localStorage.getItem('isQuoting'))
        if(isQuoting) return 'noAuth'
        return false
    },
    async getTypeAndStyles (search = ''){
      return await AJAX_GET(`product-builder/accessories/types-and-styles?search=${search}`)
    },
    async getListItems (search = '',style = '', type = ''){
      const safeSearch = search.replace(/(?<!')'(?!')/g, "''");
      return await AJAX_GET(`product-builder/accessories/items?search=${safeSearch}&style_id=${style}&type_id=${type}`)
    },
    async getLengthTypes (){
      return await AJAX_GET(`product-builder/length-type`)
    },
    async getLimitsAndColours (item_matrice_id,frame_colour_id = 0){
      return await AJAX_GET(`product-builder/accessories/limits-and-colours/${item_matrice_id}/${frame_colour_id}`)
    },
  async getPriceOfSelectedAccessories (payload){
    return await AJAX_POST(`product-builder/accessories/get-price-of-selected-accessories`,payload)
  },
  async saveNewAccessoryForOrder (payload){
    return await AJAX_POST(`product-builder/order-accessories`,payload)
  },
  async showASavedAccessoryViaPosition (position_id){
    return await AJAX_GET(`product-builder/order-accessories/${position_id}`)
  }

}
