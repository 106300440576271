<script setup>
import { ref, computed, watch, getCurrentInstance, defineProps  } from 'vue'
import Wizard from './../../../wizard.vue'
import { bulkEditStore } from './store'
const props = defineProps({
    isModal: Boolean,
    isQuoting: Boolean,
    showAddNewAccessories: Boolean,
    mode: String
})

const refWizard = ref(null)

const updatedData = computed(() => {
    return refWizard.value?.data
})

const currentPrice = computed(() => {
    return refWizard.value?.price
})

watch(currentPrice, (val) => {
 bulkEditStore.selectedItem['currentPrice'] = val   
})

watch(updatedData, (val) => {
    if(!val) return

    console.log('Changed.....', val.current_step.name)
    bulkEditStore.selectedItem.initial_data = val

    bulkEditStore.selectedItem['resolved'] = val.current_step.name === 'All Options'
    if(bulkEditStore.selectedItem.resolved) {
        bulkEditStore.selectNextUnresolvedItem()
    }

    // bulkEditStore.selectNextUnresolvedItem()

    // if(val.current_step.name === 'All Options') {
    //     console.log('First ifffffffffffffffff')
    //     bulkEditStore.selectedItem['resolved'] = true
    //     bulkEditStore.selectNextUnresolvedItem()
    // }else {
    //     console.log('First else........')

    //     bulkEditStore.selectedItem['resolved'] = false
    // }
}, {deep: true})

const beforeSubmit = (selection, method) => {
    console.log('Before Submit..........', selection, method)
    bulkEditStore.setTargetSelection(selection, bulkEditStore.selectedItem.initial_data.current_step.id, (bulkEditStore.selectedItem.initial_data.current_step.option_code || ''))
    return false
}

const closeWizard = () => {
    console.log('close wizard...')
}

// const sId = computed(() => bulkEditStore.selectedItem.id)

// watch(sId, () => {
//     console.log('ID changed.....................................')
//     const instance = getCurrentInstance();
//     instance.proxy.forceUpdate();
// }, {deep: true})


</script>

<template>
    <!-- <Wizard
        ref="refWizard"
        :isModal="true"
        :isQuoting="isQuoting"
        :showAddNewAccessories="false"
        @showRegisterModal="() => `showModal('register')`"
        @closeModal="closeWizard"
        :mode="mode"
    /> -->
    <template v-if="bulkEditStore.selectedItem.resolved">
        <div class="tw-h-full tw-flex tw-justify-center tw-items-center tw-text-3xl tw-font-normal tw-text-gray-400">
            This item is resolved
        </div>
    </template>

    <Wizard
        v-else
        :key="bulkEditStore.selectedItem.id"
        ref="refWizard"
        :isModal="false"
        :isQuoting="isQuoting"
        :showAddNewAccessories="false"
        @showRegisterModal="() => `showModal('register')`"
        @closeModal="closeWizard"
        :mode="mode"
        :isBulkEdit="true"
        :initialDataForBulkEdit="bulkEditStore.selectedItem.initial_data"
        :beforeSubmit="beforeSubmit"
    />
</template>