
<script>
    const LayoutPriceFrom = defineAsyncComponent(() => import('./../../steps/LayoutPriceFrom.vue'));
    const Type = defineAsyncComponent(() => import('./../../steps/Type.vue'))
    const Color = defineAsyncComponent(() => import('./../../steps/Color'))
    const Location = defineAsyncComponent(() => import('./../../steps/Location'))


    export default {
        components: {
            LayoutPriceFrom,
            Type,
            Color,
          Location
        }
    };
</script>

<script setup>
import { defineProps, ref, computed, defineAsyncComponent, onUnmounted, defineEmits } from 'vue';
import  { useRoute, useRouter } from 'vue-router'
import Item from '../../steps/elements/Item.vue';
import Wizard from './../../../wizard.vue'
import WizardWrapperBulkEdit from './WizardWrapperBulkEdit.vue'
import { wizardSteps } from './../../../store/constant'
import api from './../../../services/wizard.service'
import BulkEditListItem from './BulkEditListItem.vue'
import { bulkEditStore } from './store'
const HelpTopic = defineAsyncComponent(() => import('./../../elements/HelpTopic.vue'))
import _ from 'lodash'
import Loader from './../../elements/Loader.vue'
import Button from '../../../components/form/Button.vue'

const props = defineProps({
    isQuoting: {
        type: Boolean,
    },
    mode: String,
    type: String,
    area: String,
})
const wizard = ref(wizardSteps)
const route = useRoute()

const emits = defineEmits(['closeWizard', 'bulkEditSuccess'])


onUnmounted(() => {
    exit()
})

const exit = () => {
    bulkEditStore.clean()
    emits('closeWizard')
}

const onTypeSelect = () => {
    bulkEditStore.selectBulkEditOption({...wizardSteps.type, name: 'type'})
}

const onStyleSelect = () => {
    bulkEditStore.selectBulkEditOption({...wizardSteps.style, name: 'style'})
}

const onGradeSelect = () => {
    bulkEditStore.selectBulkEditOption({...wizardSteps.grade, name: 'grade'})
}

const onColourSelect = () => {
    bulkEditStore.selectBulkEditOption({...wizardSteps.frame_colour, name: 'frame_colour'})
}

const onLocationSelect = () => {
  bulkEditStore.selectBulkEditOption({...wizardSteps.location, name: 'location'})
}

const showWizardModal = ref(false)

const closeWizard = () => {
    showWizardModal.value = false
    emits('closeWizard')
}

const showWizard = (item) => {
    console.log('showwizard', item)
    const params = {
        position_id: item.section_items[0].id,
        is_additional_accessory: false,
        location: '1atest'
    }

    if(props.type == 'dealer') params.dealer_order_id = route.params.id
        else params.customer_order_id = route.params.id

    sessionStorage.setItem('wizardParams', JSON.stringify(params))
    showWizardModal.value = !showWizardModal.value
}

const submit = (e) =>{
    bulkEditStore.setTargetSelection(e)
    console.log('Submitting', e)
}

const wizardHeight = computed(() => {
    if(currentStep.value.component == 'type') return 'calc(100% - 49px)'
    if(currentStep.value.buttons) return 'calc(100% - 129px)'
    return 'calc(100% - 90px)'
})

const currentStep = computed(() => {
    const stepType = bulkEditStore.selectedItem.initial_data?.current_step?.name.toLowerCase().split(' ').join('_')

    if(stepType) return wizard.value[stepType]
    return null
})

const itemsLoaded = computed(() => {
    return bulkEditStore.items.every(x => x.original_price)
})


const allItemsResolved = computed(() => !bulkEditStore.items.some(i => !i.resolved))
const allItemsSaved = computed(() => !bulkEditStore.items.some(i => !i.saved))

const saveChanges = async () => {
    bulkEditStore.saveChanges().then(res => {
        bulkEditStore.clean()
        emits('bulkEditSuccess')
    })
}

const savingStatus = computed(() => {
    const total = bulkEditStore.items.length
    const processed = bulkEditStore.items.filter(x => x.saved)
    return bulkEditStore.savingChanges? `Processing ${processed.length + 1}/${total}`: null
})

const dontShowTypeSelection = computed(() => {
    return bulkEditStore.items.some(item => {
        let type = item.initial_data?.steps.find(step => step.id === 0).data.name
        return !["Window 9mm", "Window 11mm"].includes(type)
    })
})

</script>




<template>
    <div class="bulk-edit tw-h-full tw-w-full">
        <div class="tw-flex tw-h-full ">
            <div class="tw-w-1/4 tw-max-w-300 tw-flex tw-flex-col tw-gap-1" style="max-height: 70vh; overflow: auto;">
                <h3 class="title main tw-p-4">Bulk Edit</h3>
                <div class="tw-shadow-md tw-h-full tw-flex tw-flex-col tw-gap-1 tw-overflow-auto">
                    <BulkEditListItem :area="area" v-for="item in bulkEditStore.items" :item="item" :key="item.id" :customerType="type"/>
                </div>

            </div>
            <div v-if="bulkEditStore.loadingData || !itemsLoaded || bulkEditStore.savingChanges" style="height: 70vh;" class="tw-h-full tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-flex-1">
                
                <Loader class="wizard__loader" customClass="big" >
                    <p v-if="bulkEditStore.savingChanges && savingStatus" class="tw-text-xl tw-font-normal tw-text-gray-400 tw-mt-4">{{ savingStatus }}  </p>
                </Loader>
                
            </div>
            <div v-else class="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-h-full" style="max-height: 70vh; height: 70vh; overflow: auto; align-items: flex-start;">
                <div v-if="bulkEditStore.activeScreen === 'initialBulkEditSelection'" class="wizard">
                    <div class="wizard__item step">
                        <div class="step__title" v-if="currentStep.title">
                            <div>
                                <!-- <svg-icon-qouting class="wizard__btn_back qp_back" data="fas fa-arrow-left" v-if="isQuoting && data.history && data.history.length > 0 && data.current_step.id != 999" @click="goToRoute('prev')" /> -->
                                <span class="title-text">{{ !bulkEditStore.selectedItem.initial_data.current_step.option ? bulkEditStore.selectedItem.initial_data.current_step.name : bulkEditStore.selectedItem.initial_data.current_step.option }}</span>
                                <div class="tw-ml-2 tw-inline-block">
                                    <HelpTopic
                                        v-if="bulkEditStore.selectedItem.initial_data.current_step.help_topic_url"
                                        :iframe-data="bulkEditStore.selectedItem.initial_data.current_step.help_topic_url"
                                    />
                                </div>
                                <div>
                                <small class="title-note">{{bulkEditStore.selectedItem.initial_data.current_step.note}}</small>
                                </div>
                            </div>

                        </div>

                        <div class="wizard__content tw-pt-11 tw-px-4"
                            :class="currentStep.className"
                            :style="{height: wizardHeight}">
                            <component
                                ref="refComponent"
                                :area="area"
                                :key="bulkEditStore.selectedItem.id"
                                :is="currentStep.component"
                                :stepName="bulkEditStore.selectedItem.initial_data.current_step.name"
                                :items="bulkEditStore.selectedItem.propItems"
                                :allData="bulkEditStore.selectedItem.initial_data"
                                :isQuoting="isQuoting"
                                :showAddNewAccessories="false"
                                :isBulkEdit="true"
                                @submit="submit"
                                @optionSubmit="() => 'optionSubmit'"
                                @nextOption="() => 'nextOption'"
                                @jumpTo="() => 'goToRoute'"
                                @updColorSection="() => 'updateOptionColour'"
                                @updColor="() => `nextStep('updColor')`"
                                @hideButtons="(value) => `hideButtons = value`"
                            />

                        </div>

                    </div>
                </div>
                <div v-if="bulkEditStore.activeScreen === 'bulkEditOption'" class="tw-my-auto">
                    <div class="tw-flex tw-items-center tw-justify-evenly tw-gap-16 tw-flex-wrap icon-primary">
                        <Item v-if="!dontShowTypeSelection && !isQuoting" @selected="onTypeSelect" :item="{disabled: false, icon: 'fak fa-type-icon-hinged-door', name: 'Type' }"></Item>
                        <Item v-if="!isQuoting" @selected="onStyleSelect" :item="{disabled: false, icon: 'fak fa-style-icon-insect-screen', name: 'Style' }"></Item>
                        <Item v-if="!isQuoting" @selected="onGradeSelect" :item="{disabled: false, icon: 'fak fa-grade-icon-hinged-door-standard', name: 'Grade' }"></Item>
                        <Item @selected="onColourSelect" :item="{disabled: false, icon: 'fas fa-palette', name: 'Colour' }" class="primary-colour"></Item>
                        <Item @selected="onLocationSelect" :item="{disabled: false, icon: 'fak fa-options-icon-location', name: 'Location' }" class="primary-colour"></Item>
                    </div>
                    <div v-if="allItemsResolved" class="tw-gap-2 tw-mt-4 tw-justify-center tw-items-center tw-flex tw-flex-col tw-text-3xl tw-font-normal tw-text-gray-400">
                        <!-- <p>All items resolved.</p> -->
                        <Button  class="panel__btn tw-block" @saveChanges="saveChanges" :data="{title: 'Save Changes', type: 'primary', event: 'saveChanges', loading: bulkEditStore.savingChanges}" />
                    </div>
                </div>

                <div v-if="allItemsResolved && bulkEditStore.activeScreen === 'itemWizard'" class="tw-h-full tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center tw-text-xl tw-font-normal tw-text-gray-400">
                    <div v-if="allItemsSaved" class="tw-space-y-2 tw-justify-center tw-flex tw-flex-col">
                        <p>Great Job!</p>
                        <Button  class="panel__btn tw-block" @exit="exit" :data="{title: 'Close', type: 'secondary', event: 'exit'}" />

                    </div>
                    <div v-else class="tw-space-y-2 tw-justify-center tw-items-center tw-inline-flex tw-flex-col">
                        <p>All items resolved.</p>
                        <p>Go to to the main page to Save Changes</p>
                        <Button  class="panel__btn tw-block tw-w-28" @saveChanges="bulkEditStore.activeScreen='bulkEditOption'" :data="{title: 'OK', type: 'primary', event: 'saveChanges', loading: bulkEditStore.savingChanges}" />
                    </div>
                </div>
                <div v-if="!allItemsResolved && (bulkEditStore.activeScreen === 'itemWizard' && bulkEditStore.selectedItem)" class="tw-h-full tw-w-full">
                    <WizardWrapperBulkEdit
                        ref="refWizard"
                        :isModal="true"
                        :isQuoting="isQuoting"
                        :showAddNewAccessories="false"
                        @showRegisterModal="() => `showModal('register')`"
                        @closeModal="closeWizard"
                        :mode="mode"
                    />
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.bulk-edit {
    color: rgba(28, 40, 51, 0.8);
    font-size: 13px;
    line-height: 140%;
    color: rgba(28, 40, 51, 0.8);
    text-align: left;

    width: 90vw;
    max-height: 85vh;


    .icon-primary {
        color: var(--LayoutIconPrimary);
    }
    .title {
        font-family: Sansation;
        font-weight: bold;
        &.main {
            width: 100%;
            max-width: 100vw;
            font-size: 24px;
            line-height: 27px;
            text-transform: uppercase;
            color: #000000;
            font-weight: bold;
            text-align: left;
            font-family: Sansation;
        }
    }
}

.wizard {

    height: 100%;
    width: 100%;
    &__loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__item {
     // height: auto!important;
     // flex-direction: unset!important;
     // padding: 10px 0!important;
    //  display: flex;
    //  flex-wrap: wrap;
        height: calc(100vh - 160px);
        width: 100%;
        margin: 0 auto;
        background: #fff;
        padding: 25px 37px;
        position: relative;
        overflow: hidden;
        // @media(max-width: 768px) {
        //     padding: 10px;
        //     height: auto;
        //     min-height: calc(100vh - 160px);
        //     display: flex;
        //     flex-direction: column;

        // }
    }
    &__nav {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

    }
    &__btn_back {
        margin-right: 10px;
        // margin-top: 2px;
        color: var(--PrimaryButtonColour);
        cursor: pointer;
      /*padding-left: 40px;
      padding-top: 40px;*/
      @media(max-width: 768px) {
        padding-left: 10px;
        padding-top: 0;
      }

      &.qp_back{
        font-size:18px;
      }
    }
    @media(max-width: 768px) {
        overflow: scroll;
        background: white;
        &__item {
          display: flex;
          height: auto!important;
          height: 100%!important;
          flex-direction: unset!important;
          padding: 10px 0!important;
          padding-bottom: 0!important;
          display: flex;
          flex-wrap: wrap;
        }

        .panel {
            position: unset!important;
            min-height: unset;
            left: -37px;
            width: calc(100% + 74px);
            bottom: unset;
            display: flex;
            flex-direction: row;
            &__actions {
              display: inline-flex!important;
              justify-content: space-between!important;
              width: 100%;
            }
        }
    }
}
.step  {
    &__title {
        width: 100%;
        max-width: 100vw;
        font-size: 24px;
        line-height: 27px;
        text-transform: uppercase;
        color: #000000;
        font-weight: bold;
        text-align: left;
        font-family: Sansation;
     // padding-top: 40px;
     // margin-left: 40px;
      @media(max-width: 768px) {
        padding-left:10px;
        margin-bottom: 20px;
        font-size: 22px!important;
        padding-top: 0;
        margin-left: 0;
      }
    }
    .title-text {
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        max-width: 70%;
        text-overflow: ellipsis;
        vertical-align: middle;

    }

  .title-note {
    text-transform: none;
    font-weight: normal;
    font-size: 15px;
    color: #000000;
    display: inline;
  }
}
.wizard__content {
  /*padding: 0 40px 40px 40px;
  width: 100%;
  margin-top: 0!important;
  height: auto!important;*/
    display: block;
    margin-top: 16px;
    height: calc(100% - 90px);
  position: relative;
    overflow-y: auto;
    @media(max-width: 768px) {
     //   height: auto;
        overflow-x: hidden;
      //  margin-top: 10px;
      padding: 0 10px;
      width: 100%;
      margin-top: 0!important;

    }
    & > div {
        min-height: 100%;
    }
    &.overflow-hidden{
        overflow: hidden;
        & > div {
            height: 100%;
        }
    }
}

</style>
