<template>
  <div class="item" v-if="data">
    <div class="item__top">
      <div class="top__title"
      >{{ name }}
      </div>
    </div>
    <div class="item__content">
      <div class="content__main" v-for="(accessory,index) in data" :key="index">
        <div class="content__img addition_accessory" v-if="accessory.item.icon">
          <img :src="accessory.item.icon"/>
        </div>
        <div class="content__text">
          <span title="Requires powder coating" v-if="accessory.has_pc"><svg-icon name="solid/paint-roller" /></span>
          {{ getAccessoryName(accessory) }}
          {{ accessory.item.product_style.name }} - {{ accessory.item.name }}
          <br>
          {{ accessory.frame_colour && accessory.frame_colour.name }}
          <template
            v-if="accessory.has_only_insert_colour == 0 && accessory.insert_colour.name !== accessory.frame_colour.name">
            <br>
            {{ accessory.insert_colour && accessory.insert_colour.name }}
          </template>

        </div>
        <div class="content__desc">
          <div class="options__actions" v-if="isEditable">
            <Button class="content__btn" v-for="(btn,index) in actions" :key="index" :data="btn"
                    @click="$emit(`${btn.event}`,accessory)"/>

          </div>
          <div v-if="canSeePrice" class="options__price">
            {{ formatter.formatMoney(accessory.price) }}
          </div>
        </div>
      </div>
      <div v-if="canSeePrice" class="content__desc">
        <div class="options__total_price">{{ formatter.formatMoney(total) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../../../form/Button.vue'
import Options from './Options.vue'
import {computed, ref} from 'vue'
import DotActions from './DotActions.vue'
import {useFormatter} from '../../../../composables/formatter'
import { useStore } from 'vuex'

export default ({
  props: ['data', 'isEditable', 'name', 'area'],
  components: {
    Button,
    Options,
    DotActions
  },
  setup(props) {
    const formatter = useFormatter()
    const showToolip = ref(false)
    const store = useStore()

    const canSeePrice = computed(() => props.area === 'my-orders'? store.getters['auth/canSeeCost']: store.getters['auth/canSeePrice'])

    const actions = [
      {icon: 'fas fa-edit', type: 'primary', event: 'showWizard'},
      {type: 'outline', event: 'remove', icon: 'fas fa-trash-alt',}
    ]

    const getAccessoryName = (accessory) => {
      const {quantity, length1, length2, length3, length4} = accessory
      return `${quantity} - ${length1 ? `${length1}` : ``} ${length2 ? `X ${length2}` : ``} ${length3 ? `X ${length3}` : ``} ${length4 ? `X ${length4}` : ``}`
    }

    const total = computed(() => {
      let price = 0
      props.data.forEach(item => {
        price += parseFloat(item.price)
      })
      return price
    })

    return {
      canSeePrice,
      actions,
      showToolip,
      formatter,
      getAccessoryName,
      total
    }
  },
})
</script>


<style scoped lang="scss">
.item {
  border: 1px solid rgba(11, 53, 83, 0.3);
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 2px;
  width: 320px;
  min-width: 255px;
  height: fit-content;
  // &.disabled {
  //     opacity: 0.7;
  // }
  &__top {
    background: rgba(11, 53, 83, 0.1);
    height: 31px;
    padding-left: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &.toolip {
      white-space: nowrap;
      width: auto;
    }
  }

  &__content {
    padding: 10px;
    width: 100%;
    overflow-y: scroll;
    max-height: 350px;
  }
}

.top {
  &__title {
    font-size: 13px;
    width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
    font-family: Sansation;

    &.toolip {
      position: absolute;
      top: -20px;
      overflow: visible;
      text-overflow: none;
    }
  }

  &__actions {
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15)), #FFFFFF;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.content {
  &__main {
    display: flex;
    min-height: 100px;
  }

  &__desc{
    position: relative;
    bottom: 0px;
  }

  &__img {
    // width: 65px;
    // height: 80px;
    margin-right: 10px;
    font-size: 80px;

    ::v-deep .icons__wrapper {
      display: flex;
    }

  }

  &__text {
    font-size: 13px;
    line-height: 140%;
    color: rgba(28, 40, 51, 0.8);
    text-align: left;
  }


  &__desc {
    margin-bottom: 10px;
    padding-left: 10px;

    .options__actions{
      display: flex;

      button{

        &:last-child{
          padding-right: 0px !important;
          border-right: 0px;
        }
      }
    }

    .options__price {
      text-align: right;
    }

    .options__total_price {
      text-align: right;
      font-weight: bold;
    }
  }

  &__btn {
    width: 100%;
  }
}

.actions {
  &__dropdown {
    position: absolute;
    right: 0;
    bottom: -100%;
    transform: translateY(55%);
    min-width: 142px;
    padding: 10px 0;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.05), rgba(11, 53, 83, 0.05)), #FFFFFF;
    border: 1px solid rgba(11, 53, 83, 0.2);
    box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.1);
    z-index: 10;
  }

  &__item {
    margin-bottom: 10px;
    text-align: right;
    text-transform: uppercase;
    padding: 0 10px;
    font-weight: 600;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      display: inline-block;
      margin-left: 5px;
    }
  }

}

::v-deep .svg-inline {
  width: 50px;

  &.width_2 {
    width: 100px;
  }

  &.width_0_5 {
    height: 100px;
  }
}

::v-deep .addition_accessory {
  font-size: 0 !important;
  margin-right: 0 !important;

  & img {
    width: 80px;
  }
}

::v-deep .btn_outline svg {
  color: #444E58 !important
}

::v-deep .content__desc .btn {
  padding: 0 10px !important;

  & .btn__icon {
    margin-right: 0 !important;
  }
}
</style>
