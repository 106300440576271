<template>
  <div class="event_scheduler">
    <div class="event_scheduler__inputs">
      <div class="event_scheduler__date" style="margin-right: 30px">
        <div class="schedule_modal__label">{{title || 'Event'}} date</div>
        <CalendarDate
          @change-date-value="changeDateValue"
          :readonly="true"
          :date-value="dateValue"
          :is-error-date="isErrorDate"
          :hideCalendar="true"
        />
      </div>
      <div class="event_scheduler__time">
        <div class="schedule_modal__label">Time:</div>
        <CalendarTime
          @change-time-value="changeTimeValue"
          @am="function() {activeIsAm = true}"
          @pm="function() {activeIsAm = false}"
          :active-is-am="activeIsAm"
          :timeValue="timeValue"
          :is-error-time="isErrorTime"
        />
      </div>
    </div>
    <div class="event_scheduler__calendar">
      <Calendar
        @pass-date="chooseDate"
        :min-date="true"
        :hasEmits="true"
        :selectedDate="dateValue"
      />
    </div>
    <div class="event_scheduler__buttons">
      <button class="btn btn_default"
              @click="saveSchedule"
      >
        <svg-icon name="solid/check"></svg-icon>
        {{ buttonLabel || 'Schedule' }}
      </button>
    </div>
  </div>
</template>

<script>
import CalendarTime from "@/components/commons/CalendarTime";
import CalendarDate from "@/components/commons/CalendarDate";
import Calendar from "@/components/commons/Calendar";
import {computed, ref} from "vue";

export default {
  name: "ScheduleEventComponent",
  components: {
    Calendar,
    CalendarTime,
    CalendarDate,

  },
  props: ['dateTimeValue', 'events', 'buttonLabel', 'title'],
  setup(props, {emit}) {
    const activeIsAm = ref(true)
    const fullDate = ref(null)

    function initialise(){

      if (props.dateTimeValue)
        fullDate.value =new Date(props.dateTimeValue)
      else {
        let d = (new Date()).addDays(1)
        d.setHours(8, 0, 0)
        fullDate.value = d
      }

    }

    initialise()

    const dateValue = computed({
      get: () => {
        const offset = fullDate.value.getTimezoneOffset()
        const d = new Date(fullDate.value.getTime() - (offset*60*1000))
        return d.toISOString().split('T')[0]
      },
      set: val => {
        const value = new Date(val)
        fullDate.value = new Date(value.getFullYear(), value.getMonth(), value.getDate(), timeValue.value.split(':')[0], timeValue.value.split(':')[1])
      }
    })


    const timeValue = computed({
      get: () => {

        let h = fullDate.value.getHours();
        let m = fullDate.value.getMinutes()

        if (h < 10)
          h = '0' + h.toString()

        if (m < 10)
          m = '0' + m.toString()

        return h + ':' + m
      },
      set: value => {
        const d = fullDate.value
        fullDate.value = new Date(d.getFullYear(), d.getMonth(), d.getDate(), value.split(':')[0], value.split(':')[1])
      }
    })

    let changeDateValue = (value) => {
      dateValue.value = value
    }
    let changeTimeValue = (value) => {
      timeValue.value = value
    }
    let chooseDate = (value) => {
      changeDateValue(value)
    }

    function saveSchedule(){
      const d = fullDate.value
       emit('update:modelValue', `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`)
    }

    return {
      fullDate,
      dateValue,
      timeValue,
      changeDateValue,
      changeTimeValue,
      chooseDate,
      saveSchedule
    }
  }
}
</script>

<style scoped lang="scss">
.event_scheduler{

  display: flex;
  flex-direction: column;

  width: 450px;
  max-width: 100%;

  &__inputs{
    display: flex;
    flex-direction: row;
  }

  &__buttons{

    button {
      width: 100%;
    }
  }

}

</style>
