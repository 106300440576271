<template>
  <Modal v-model="show" class="confirmation-modal" @update:modelValue="$emit('update:show', false)">
    <div class="confirmation-modal__content">
    <div class="confirmation-modal__title">
      <slot name="message">
        <h2>Action required</h2>
        <br>
        <p>Selected colour may incur powder coating charge depending on volume. Do you wish to proceed?</p>
        <br>
        {{supressPCAlert}}
        <Checkbox
          class="position__item"
          :data="{key: 'doNotShowPcAlert', label : 'Do not show this alert for this order'}"
          :disabled="false"
          @update:modelValue="updatePcFlag"
        />
      </slot>
    </div>
      <div class="confirmation-modal__actions">
        <Button :data="{ type: 'primary', title: 'No, let me pick another colour',icon: 'far fa-arrow-left' }"
                @click="$emit('cancelClicked'); $emit('update:show', false);"/>
        <Button :data="{ type: 'primary', title: 'Yes', icon: 'fa fa-check', }" @click="$emit('confirmClicked'); $emit('update:show', false);"/>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../elements/Modal'
import Button from '../form/Button'
import  { ref } from 'vue'
import Checkbox from '../form/Checkbox.vue'
import {pcChargeStore} from "../estimate/pc-charge/store";
import {computed} from "@vue/reactivity";

export default ({
  props: ['show','message','cancel_btn_data','confirm_btn_data', 'title', 'showCheckbox', 'checkboxText'],
  components: {
    Modal,
    Button,
    Checkbox
  },
  setup(props, {emit}) {
    const cancelBtnData = ref(props.cancel_btn_data  ??  { type: 'primary', title: 'Back',icon: 'far fa-arrow-left' })
    const confirmBtnData = ref(props.confirm_btn_data  ??  { type: 'primary', title: 'Submit', icon: 'fa fa-archive', })
    const supressPCAlert = computed(() => {pcChargeStore.supressAlert})

    function updatePcFlag(){
      console.log(pcChargeStore.supressAlert)
      pcChargeStore.supressAlert = !pcChargeStore.supressAlert
      console.log(pcChargeStore.supressAlert)
    }

    return {
      cancelBtnData,
      confirmBtnData,
      updatePcFlag
    }
  },

})
</script>

<style lang="scss" scoped>
.confirmation-modal {

  height: 100%;
  top: 0;
  min-width: 100%;
  max-width: 100vw;
  &__title{
    padding: 10px;
    width: 100%;
    max-width: 90vw;
    height: auto;
    text-align: center;

    p{
      max-width: 100%;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;

    button{
      margin: 5px;
    }
  }

  ::v-deep .modal {
    max-width: 100%;

    min-height: 180px;

    max-height: 80vh;
  }

  ::v-deep .btn_outline {
    border-color: var(--PrimaryButtonColour);
    color: var(--PrimaryButtonColour);
    margin-right: 20px;
  }
}

</style>
