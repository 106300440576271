<script setup>
import { ref } from "@vue/reactivity";

let data = ref({});
const props = defineProps({
  tableData: {
    type: Object,
    default: () => ({}),
  },
});
</script>
<template>
  <div style="border: 1px solid; display: inline-block">
    <div style="display: grid; grid-template-columns: max-content auto">
      <template v-for="(i, v) in tableData" :key="v">
        <div
          class="px-2"
          style="
            widthx: 100px;
            border: 1px solid;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          {{ i.name }}
        </div>
        <div>
          <div v-for="v in i.values" :key="v.name" style="display: flex">
            <div style="display: flex; flex: 1">
              <div
                style="
                  width: 8em;
                  border: 1px solid;
                  padding: 5px;
                  text-align: left;
                "
              >
                {{ v.name }}
              </div>
              <div
                style="
                  flex: 1;
                  border: 1px solid;
                  padding: 5px 20px;
                  text-align: left;
                "
              >
                {{ v.value }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "RenderOSQRTable",
};
</script>

<style scoped></style>
