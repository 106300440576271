<template>
    <div class="item" v-if="data">
        <div class="item__top" :class="[showToolip && name.length > maxToolipTextLength && 'toolip']">
            <!-- <div class="top__title toolip" v-if=>{{ name }}</div> -->
            <div class="top__title"
                @mouseenter="showToolip = true"
                @mouseleave="showToolip = false"
            >
                <div class="tw-flex tw-items-center" >
                    <div class="form-group" style="margin-bottom: 0;" v-if="showBulkEditSelection">
                        <input
                            type="checkbox"
                            :disabled="false"
                            :id="data.id"
                            :value="item"
                            v-model="bulkEditStore.itemSelection"
                        >
                        <label :for="data.id"> </label>
                    </div>
                    <span>{{ name }}</span>
                </div>
            </div>
            <DotActions v-if="!noActions && isEditable" @clone="$emit('clone')" @remove="$emit('remove')" @cloneMultipleTimes="(times) => $emit('cloneMultipleTimes', times)"/>
        </div>
        <div class="item__content">
            <div class="content__main">
                 <div class="content__img" :class="{'addition_accessory' : data.is_additional_accessory}">
                      <svg-icon-qouting v-if="data.layout_image && data.position_layout && data.position_layout.layout?.is_make_size_only != 1" :data="data.layout_image"/>
                      <svg-icon-qouting v-else-if="!data.icon.startsWith('http')" :data="data.icon"/>
                       <img :src="data.icon" v-else class="orderItem__img"/>
                </div>
                <div class="content__text">
                  <span title="Parts or accessories of this item require powder coating" v-if="data.has_pc"><svg-icon name="solid/paint-roller" /></span>
                  {{ data.item.product_style.name }} - {{ data.item.product_grade.name }}
                    <br>
                    {{ data.frame_colour && data.frame_colour.name }}
                    <template v-if="data.insert_colour && data.has_only_insert_colour == 0 && data.insert_colour.name !== data.frame_colour.name">
                        <br>
                        {{ data.insert_colour && data.insert_colour.name }}
                    </template>
                    <Options :data="data" :area="area" />
                </div>
            </div>
            <div class="content__desc">
              <div class="options__price" v-if="canSeePrice">{{ formatter.formatMoney(data.price) }}</div>
            </div>
            <Button class="content__btn" :data="{title: 'Edit', icon: 'fas fa-edit', type: 'primary'}" v-if="isEditable" @click="$emit('showWizard')"/>
        </div>
    </div>
</template>

<script>
import Button from '../../../form/Button.vue'
import Checkbox from '../../../form/Checkbox'
import Options from './Options.vue'
import { ref, computed } from 'vue'
import DotActions from './DotActions.vue'
import { useFormatter } from '../../../../composables/formatter'
import { bulkEditStore } from '../../bulk-edit/store'
import { useStore } from 'vuex'

import {isWidthIsTheSizeToEnterFirst} from "@/helpers/portalCommons";
export default ({
    props: ['data', 'noActions', 'isEditable', 'selected', 'showBulkEditSelection', 'bulkEditSelection', 'item', 'area'],
    components: {
        Button,
        Options,
        DotActions,
        Checkbox,
    },
    setup(props, {emit}) {
        const formatter = useFormatter()
        const showToolip = ref(false)
        const maxToolipTextLength = 30
        const actions = [
            {
                title: 'Clone',
                event: 'clone',
                icon: 'fas fa-clone',
            },
            {
                title: 'Delete',
                event: 'delete',
                icon: 'fas fa-trash-alt',
            }
        ]

        const store = useStore()

        const name = computed(() => {
            const { width, height, item } = props.data

          let name = `${width} x ${height} ${item.product_type.name}`
          if (props.data?.is_additional_accessory)
            name = item.product_type.name
          else if (isWidthIsTheSizeToEnterFirst() !== true )
            name = `${height} x ${width} ${item.product_type.name}`

        //   if (props.noActions)
        //     name = `${width} x ${height}`

            return  name
        })

        const canSeePrice = computed(() => props.area === 'my-orders'? store.getters['auth/canSeeCost']: store.getters['auth/canSeePrice'])


        const showDropdown = ref(false)
        const sel = ref(true)
        return {
          canSeePrice,
          actions,
          name,
          showDropdown,
          showToolip,
          formatter,
          bulkEditStore,
        }
    },
})
</script>


<style scoped lang="scss">

.form-group {
  display: flex;
  margin-bottom: 15px;
  //width: 200px;
  // margin-left: -6px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  text-align: left;
}

.form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--PrimaryButtonColour);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  width: 15px;
  min-width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 2px;
}

.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 7px;
  width: 3px;
  height: 9px;
  border: solid var(--PrimaryButtonColour);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.item {
    border: 1px solid rgba(11, 53, 83, 0.3);
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 2px;
    width: 255px;
    min-width: 255px;
    height: fit-content;
    // &.disabled {
    //     opacity: 0.7;
    // }
    &__top {
        background: rgba(11, 53, 83, 0.1);
        height: 31px;
        padding-left: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &.toolip {
            white-space: nowrap;
            width: auto;
        }
    }
    &__content {
        padding: 10px;
        width: 100%;
    }
}
.top {
    &__title {
        font-size: 13px;
        width: calc(100% - 40px);
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
        white-space: nowrap;
        text-align: left;
        cursor: pointer;
        font-family: Sansation;
        &.toolip {
            position: absolute;
            top: -20px;
            overflow: visible;
            text-overflow: none;
        }
    }
    &__actions {
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15)), #FFFFFF;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.content {
    &__main {
        display: flex;
        min-height: 100px;
    }
    &__img {
        // width: 65px;
        // height: 80px;
        margin-right: 10px;
        font-size: 80px;
        ::v-deep .icons__wrapper {
            display: flex;
        }
    }
    &__text {
        font-size: 13px;
        line-height: 140%;
        color: rgba(28, 40, 51, 0.8);
        text-align: left;
    }
    &__desc {
        margin-top: 7px;
        margin-bottom: 10px;
      .options__price{
        text-align: right;
      }
    }
    &__btn {
        width: 100%;
    }
}
.actions {
    &__dropdown {
        position: absolute;
        right: 0;
        bottom: -100%;
        transform: translateY(55%);
        min-width: 142px;
        padding: 10px 0;
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.05), rgba(11, 53, 83, 0.05)), #FFFFFF;
        border: 1px solid rgba(11, 53, 83, 0.2);
        box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.1);
        z-index: 10;
    }
    &__item {
        margin-bottom: 10px;
        text-align: right;
        text-transform: uppercase;
        padding: 0 10px;
        font-weight: 600;
        cursor: pointer;
        &:last-of-type {
            margin-bottom: 0;
        }
        span {
            display: inline-block;
            margin-left: 5px;
        }
    }

}

::v-deep .svg-inline {
        width: 50px;
    &.width_2 {
        width: 100px;
    }
    &.width_0_5 {
      &.custom {
        height: 40px;
      }
        height: 100px;
    }
}
::v-deep .content__img .icons__wrapper .icons{
  flex-direction: inherit;
}

.orderItem__img{
  //height: 90px;
  width: 50px;
}

</style>
