<template>
  <div class="d-flex align-items-center time_section" :class="readonly && 'time_section--readonly'">
    <ErrorField
      :is-error="isErrorTime"
      text-error="Please select correct time"
    >
      <Input small :value="timeValue" placeholder="hh:mm" @input="changeTimeValue" type="time"/>
    </ErrorField>
<!--    <div class="calendar__time">-->
<!--      <LinkReturn  @click="$emit('am')" :active="activeIsAm">-->
<!--        <svg-icon name="solid/sun"/>-->
<!--        <p>Am</p>-->
<!--      </LinkReturn>-->
<!--      <div style="padding: 0 4px">/</div>-->
<!--      <LinkReturn @click="$emit('pm')" :active="!activeIsAm">-->
<!--        <svg-icon name="solid/moon"/>-->
<!--        <p>Pm</p>-->
<!--      </LinkReturn>-->
<!--    </div>-->
  </div>
</template>
<script>
import Input from "@/components/inputs/Input";
import LinkReturn from "@/components/links/LinkReturn";
// import 'vue-cal/dist/vuecal.css'

export default ({
  emits: ['changeTimeValue', 'am', 'pm'],
  props: ['isErrorTime','timeValue', 'activeIsAm', 'readonly'],
  components: {
    Input,
    LinkReturn
  },
  setup(props,{emit}) {
    let changeTimeValue = (value) => {
      emit('changeTimeValue', value)
    }
    return {
      changeTimeValue
    }
  }
})
</script>

<style scoped lang="scss">
.time_section {
  input{
    width: 130px;
  }
  position: relative;
  &:before {
    content: '';
   // position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
}
.calendar__time{
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 15px;
> a {
  font-size: 15px;
}
span {
  margin-right: 3px;
}
}
</style>
