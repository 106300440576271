<script setup>

const props = defineProps({
  message: {
    required: true,
    type: String
  },
  small: {
    type: Boolean,
    default: true
  }
})
</script>
<template>
  <div class="row">
    <div class="col-12 text_green text_large d-flex">
      <div>
        <svg-icon
            :class="{small: small}"
            class="tw-h-6 tw-w-6 tw-animate-spin text_green"
            name="solid/spinner"
        ></svg-icon>
      </div>
      <div>
       &nbsp; {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InlinePreloader"
}
</script>


<style lang="scss" scoped>

.text_large {
  font-size: 1.3em;
  text-align: center;
  justify-content: center;
  padding: 20px;
  align-items: center;


  .preloader__img {
    color: #55b494;
    width: auto !important;
    height: auto !important;
    margin-right: 10px;

    svg {
      color: #55b494;
      font-size: 1.1em;

      &.small {
        font-size: 0.5em;
      }
    }
  }
}
</style>