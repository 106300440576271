<template>
  <div :key="ekey">
      <Estimate v-if="job"
              class="orders-item"
                mode="customer"
                type="customer"
                :headerData="headerData"
                :key="job.container_id"
                area="my-jobs"
                @refreshWrapper="refreshAll"
                @jobModal="showModal"
                @cancelBtnClick="showCancel = jobID"
      />
    </div>

    <JobsScheduleCheckMeasure v-if="showSchedule" :id="showSchedule" @close="showSchedule = false"/>
    <JobsCancel v-if="showCancel" :id="showCancel" @close="showCancel = false" @cancelled="onCancelled" :job="job"/>
    <JobsCheckMeasureCompleted v-if="checkIsCompleted" :id="checkIsCompleted" @close="checkIsCompleted = false"/>
    <JobsDelivery v-if="deliveryIsShown" :id="deliveryIsShown"  @close="deliveryIsShown = false"/>
    <JobsDetails v-if="placeOrderIsShown" :id="placeOrderIsShown" :place-order="true"  @close="placeOrderIsShown = false"/>

    <Teleport to="body">
      <Modal v-if="modalContent" v-model="modalContent" :handleClose="() =>emits('update:show',false)">
            <JobViewJobCustomerDetails
              v-if="modalContent == 'jobDetails'"
              
              :jobId="jobID"
              :job="job"
              @save="updateJobDetails"
              @customerChanged="customerChanged"
              />
          <JobViewJobPricing
            v-if="modalContent == 'pricing'"
            :job="job"
            @updatePricing="refreshAll"
          />
      </Modal>
    </Teleport>

</template>

<script>
import { reactive, ref, computed, onBeforeUnmount,onBeforeMount, getCurrentInstance  } from 'vue'
import { useStore } from 'vuex'
import ButtonMain from "@/components/buttons/ButtonMain";
import LinkReturn from "@/components/links/LinkReturn";
import SimpleDropdown from "@/components/dropdown/SimpleDropdown";
import JobStatusComponent from "@/components/jobs/JobStatusComponent";
import JobViewJobCustomerDetails from "@/components/jobs/JobViewJobCustomerDetails";
import JobViewJobPricing from "@/components/jobs/JobViewJobPricing";
import DropdownMenu from "@/components/dropdown/DropdownMenu";
import PreloaderSmall from "@/components/PreloaderSmall";
import JobsCardButtons from "@/components/jobs/JobsCardButtons";
import JobsCardDropdown from "@/components/jobs/JobsCardDropdown";
import {useRoute, useRouter} from "vue-router";
import JobsCheckMeasureCompleted from "@/components/jobs/JobsCheckMeasureCompleted";
import JobsCancel from "@/components/jobs/JobsCancel";
import JobsDelivery from "@/components/jobs/JobsDelivery";
import JobsScheduleCheckMeasure from "@/components/jobs/JobsScheduleCheckMeasure";
import TotalPrice from "@/components/TotalPrice";
import { useScreen, useGrid } from 'vue-screen'
import router from "@/router";
import Receipt from '@/components/item-wizard/components/estimate/item/summary/Receipt'
import lookupsService from "@/services/lookups.service";
import SelectDropdown from "@/components/dropdown/SelectDropdown";
import jobApi from "@/services/jobs/job"
import Input from "@/components/inputs/Input";
import Estimate from '@/components/item-wizard/estimate'

import Modal from '@/components/item-wizard/components/elements/Modal'
import { useEventBus } from '@vueuse/core'
import { now } from 'lodash';

export default ({
  components: {
    ButtonMain,
    LinkReturn,
    SimpleDropdown,
    JobStatusComponent,
    JobViewJobCustomerDetails,
    JobViewJobPricing,
    DropdownMenu,
    PreloaderSmall,
    JobsCheckMeasureCompleted,
    JobsCancel,
    JobsDelivery,
    JobsScheduleCheckMeasure,
    JobsCardButtons,
    JobsCardDropdown,
    Receipt,
    SelectDropdown,
    Input,
    Estimate,
    Modal
  },
  setup() {

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const jobID = computed(() => route.params.id)
    const job = ref(null)
    const jobLoadingStatus = ref(null)
    const jobPrice = ref(null)

    const modalContent = ref(null)
    const ekey = ref(null)

    const updateCustomerEvent = useEventBus('update-customer')
    updateCustomerEvent.on(() => {
      initialise()
    })

    const orderSubmittedEvent = useEventBus("order-submitted");
    orderSubmittedEvent.on(() => {
      initialise()
    })

    function customerChanged() {
      ekey.value = Date.now()
    }

    const headerData = computed(() => {
      return {
        backLink: {
          url: '/my-jobs',
          title: 'Back to Jobs'
        },
        title: 'Job',
        totalBtn: {
          title: 'Job TOTAL:'
        },
        jobSettings: {
          check_measure_date: job.value.check_measure_date,
          installation_date: job.value.installation_date,
          modals: [
            {
              name: "Job and Customer details",
              icon: 'user',
              modal: 'jobDetails'
            },
            {
              name: "Pricing",
              icon: 'dollar',
              modal: 'pricing',
              warning: job.value.is_pricelist_expired
            }
          ]
        },
        container: job.value.container
      }
    })
    const customer = ref(null)
    const openSettings = ref(false)

    const loading = ref(false)
    const screen = useScreen()
    const grid = useGrid('tailwind')
    // const job = computed(() => store.getters['jobs/job'])
    // const jobPrice = computed(() => store.getters['jobs/jobPrice'])
    // const jopPriceTotal = computed(() => store.getters['jobs/jobPriceTotal'])
    let jobStatus = computed(() => store.getters['jobs/exactJobStatus'](job.value.status))
    // const jobID = computed(() => store.getters['jobs/jobID'] || route.params.id)
    const jobContainers = computed(() => store.getters['jobs/jobContainers'])
    const customerID = computed(() => store.getters['jobs/customerID'] || store.getters['customers/customerID'])
    const menuIsOpened = ref(false)
    const saveValue = ref(0)


    const discountTypes = ref(null)

    let toggleMenu = () => {
      menuIsOpened.value =! menuIsOpened.value
    }
    let closeMenu = () => {
      menuIsOpened.value = false
    }
    const showSchedule = ref(false)
    const showCancel = ref(false)
    const checkIsCompleted = ref(false)
    const deliveryIsShown = ref(false)
    const placeOrderIsShown = ref(false)
    let cloneJob = async () => {
      await store.dispatch('jobs/changeJobsStatus', {id: job.id, status: 'clone'} )
    }
    const itemActive = ref(-1)
    const activeMenuItem = ref(screen.width > 768 ? 0 : -1)
    // const menuItems = computed(() =>{return [
    //   {
    //     name: 'Job & Customer Details',
    //     icon: 'solid/user',
    //
    //   },
    //   {
    //     name: 'Pricing',
    //     icon: 'solid/usd-circle',
    //     visible: jobID.value !== 'new'
    //   },
    //   {
    //     name: 'Products',
    //     icon: 'solid/layer-group',
    //     create: 'true',
    //     visible: jobID.value !== 'new'
    //   }
    // ].filter((el) => el.visible !== false)})

    // const chosenContainer = ref([])

    // let chooseContainer = async (value) => {
    //   if (itemActive.value != value.index) {
    //     itemActive.value = value.index
    //     await router.push('/jobs/' + jobID.value+'/'+ value.id)
    //     activeMenuItem.value = 2
    //   }
    // }
    //
    // let toggleChosenContainer = async (container) => {
    //   let index = chosenContainer.value.indexOf(container)
    //   if (index > -1) {
    //     chosenContainer.value.splice(index, 1);
    //   } else {
    //     chosenContainer.value.push(container)
    //   }
    //   await store.dispatch('jobs/calculateJobPrice',{id: jobID.value})
    // }
    let saveChanges = async (value) => {
      saveValue.value++
    }

    function changeView(view){
      activeMenuItem.value = view
    }

    function showModal(modal){
      modalContent.value = modal
    }

    function updateJobDetails(data){
      job.value = data
      modalContent.value = null
    }

    async function initialise(){

      jobLoadingStatus.value = 'Loading'
      //load job details
      refreshSummary()
      let result = await jobApi.get(jobID.value)
      if (result.data){
        job.value = result.data
        customer.value = result.data.customer
      }
      jobLoadingStatus.value = 'Complete'

      discountTypes.value = await lookupsService.getDiscountTypes()
      job.value['updated'] = now()
    }

    async function refreshSummary(){
        let result = await jobApi.getSummary(jobID.value)
        if (!result.error) {
          jobPrice.value = result
        }
    }

    function setSummary(data){
      console.log('setSummary')
      jobPrice.value = data
    }

    initialise()

    async function refreshAll(){
      job.value = null
      await initialise()
      modalContent.value = null
    }

    function onCancelled() {
      router.push('/my-jobs')
    }

    return {
      customerChanged,
      ekey,
      loading,
      job,
      jobLoadingStatus,
      jobID,
      jobPrice,
      customer,
      headerData,

      // jopPriceTotal,
      jobStatus,
      jobContainers,
      customerID,
      menuIsOpened,
      changeView,
      toggleMenu,
      closeMenu,
      showSchedule,
      showCancel,
      checkIsCompleted,
      deliveryIsShown,
      placeOrderIsShown,
      cloneJob,
      // chosenContainer,
      itemActive,
      // chooseContainer,
      // toggleChosenContainer,
      activeMenuItem,
      // menuItems,
      saveChanges,
      saveValue,
      screen,
      grid,
      discountTypes,
      refreshSummary,
      setSummary,
      openSettings,
      modalContent,
      showModal,
      updateJobDetails,
      refreshAll,
      onCancelled,
    }
  },
})
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";


::v-deep(.modal) {
  max-height: 83vh;
  overflow: auto;
  align-items: unset;
}

.job_view {
  display: flex;
  height: calc(100vh - 110px);
  .header {
    &__status {
      display: flex;
      align-items: center;
      max-width: 150px;
      margin-right: 10px;
      flex-shrink: 0;
      font-weight: bold;
      p {
        margin-left: 5px;
      }
    }
  }
  &__nav {
    height: 100%;
    width: 360px;
    min-width: 350px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    &>div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: calc(100% - 128px);
      background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;
      &:last-child {
        height: auto;
        padding: 20px 0;
        flex-shrink: 0;
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15)), #FFFFFF;
        box-shadow: 0px -3px 3px rgba(28, 40, 51, 0.2);
      }
      &>div {
        width: 350px;
      }
    }
    .nav {
      &__header {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 26px;
        flex-wrap: wrap;
        & > div > a {
          font-size: 15px;
          margin-bottom: 10px;
          span {
            font-size: 13px;
          }
        }
      }
      &__menu {
        &--mobile {
          display: none;
        }
      }
      &__mini_cards {
        padding-right: 33px;
        overflow-y: auto;
        height: 100%;
      }
      &__menu_item {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-weight: bold;
        background: rgba(11, 53, 83, 0.1);
        box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
        transition-duration: 0.2s;
        cursor: pointer;
        span {
          font-size: 13px;
          margin-right: 5px;
          color: rgba(28, 40, 51, 0.8);
        }
        position: relative;
        &:hover {
          background: rgba(11, 53, 83, 0.05)
        }
        &:before {
          position: absolute;
          content: '';
          right: -10px;
          top: 0;
          border-top: 51px solid transparent;
          border-right: 10px solid transparent;
          height: 0;
          width: 10px;
          transition-duration: 0.2s;
        }
        &--products  {
          cursor: initial;
          &:hover {
            background: rgba(11, 53, 83, 0.1);
          }
        }
        button {
          margin-left: auto;
          width: 100px;
          height: 25px;
          border: 1px solid $text-gray;
          background: transparent;
          text-transform: none;
          font-size: 13px;
          line-height: 100%;
          color: $text-gray;
          span {
            color: $text-gray
          }
          &:hover {
            color: $black;
            border: 1px solid $black;
            span {
              color: $black;
            }
          }
        }
        &--active {
          background: #59B692;
          color: white;
          span {
            color: white;
          }
          &:hover {
            background: #59B692;
          }
          &:before {
            border-top: 51px solid #59B692;
          }
          button {
            border: 1px solid rgba(255, 255, 255, 0.6);
            color: rgba(255, 255, 255, 0.9);
            span {
              color: rgba(255, 255, 255, 0.9);
            }
            &:hover {
              color: white;
              border: 1px solid white;
              span {
                color: white;
              }
            }
          }
        }
      }
      &__bottom {
        padding-right: 33px;
        position: relative;
        ::v-deep {
          button {
            height: 40px;
            margin: 20px 0 30px;
          }
          .total_price > div:first-child{
            padding-top: 20px;
            color: #1C2833;
            margin-left: -15px;
            width: 364px;
          }
          .total_price__price[data-v-675ab015]:before {
            border-color: rgba(11, 53, 83, 0.4);
          }
          .total_price__content {
            bottom: 90px;
          }
        }
      }
    }

    .pricing__discount{
      padding-right: 10px;
      padding-top: 5px;
    }
  }
  &__content {
    height: 100%;
    width: calc(100vw - 360px);
    box-shadow: -3px 0px 3px rgba(28, 40, 51, 0.2);
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    &--mobile {
      display: none;
    }
    .content {
      &__save {
        width: 100%;
        height: 80px;
        flex-shrink: 0;
        background: #FFFFFF;
        &--products {
          box-shadow: 0px -3px 3px rgba(28, 40, 51, 0.2);
        }

        button {
          width: 255px;
          height: 40px;
        }
        a {
          text-transform: uppercase;
        }
        >div {
          width: 100%;
          max-width: 780px;
          padding: 20px 20px 20px 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      ::v-deep &__heading {
        font-weight: 300;
        font-size: 27px;
        display: flex;
        align-items: center;
        color: rgba(28, 40, 51, 0.5);
        padding-bottom: 20px;
        &__edit {
          font-size: 13px;
          width: 30px;
          height: 30px;
          background: #59B692;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          cursor: pointer;
          margin-left: 10px;
          transition-duration: 0.2s;
          &:hover {
            background: #3CD298;
          }
        }
      }
      ::v-deep &__line {
        width: 100%;
        border-top: 1px solid rgba(11, 53, 83, 0.2);
        margin-top: 15px;
        padding: 0;
        margin-bottom: 10px;
      }
    }
    ::v-deep input {
      // height: 40px;
      // padding: 10px 16px;
    }
  }
}
@media (max-width: 768px) {
  .job_view {
    //height: calc(100vh - 120px);
    height: auto;
    .header {
      &__status {
        max-width: 140px;
        margin-right: 30px;
        margin-left: auto;
        font-size: 13px;
        order: 0;
      }
    }
    &__nav {
      height: 100%;
      width: 100%;
      min-height: calc(100vh - 120px);
      min-width: auto;
      ::v-deep .dropdown_menu {
        order: 1;
        margin-right: 0;
      }
      &>div {
        height: calc(100% - 64px);
        background: white;
        &:last-child {
          margin-top: auto;
          background: white;
          box-shadow: none;
          ::v-deep .total_price__content {
            bottom: 100px
          }
        }
        &>div {
          width: 100%;
        }
      }
      .nav {
        &__header {
          display: flex;
          align-items: center;
          z-index: 1;
          padding-top: 0px;
          padding-bottom: 0px;
          box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
          & > div:first-child {
            padding: 0 20px;
            height: 30px;
            background: $navy;
            > a {
              margin-bottom: 0;
              font-size: 13px;
              p:before {
                content: none;
              }
            }
          }
          & > div:last-child {
            padding: 0 20px;
            height: 50px;
            h3 {
              font-size: 17px;
            }
          }
        }
        &__menu {
          display: none;
          &--mobile {
            display: block;
          }
        }
        &__mini_cards {
          padding-right: 0px;
        }
        &__menu_item {
          height: 40px;
          padding: 5px 20px;
          &:before {
            content: none;
          }
        }
        &__bottom {
          padding: 0 20px;
          ::v-deep button {
            height: 40px;
            margin: 10px 0;
          }
        }
      }
    }
    &__content {
      display: none;
      &--mobile {
        width: 100%;
        display: flex;
      }
      height: auto;
      box-shadow: none;
      .content {
        ::v-deep &__heading {
          font-size: 23px;
          padding-bottom: 12px;
          &__edit {
            margin-left: auto;
          }
        }
        ::v-deep &__line {
          padding: 0;
          box-shadow: 0px 2px 2px rgba(28, 40, 51, 0.1);
        }
      }
    }

  }
}
</style>
