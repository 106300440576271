<template>
  <div class="location">
    <div class="location__top">
      <div class="tw-flex tw-items-center title-wrap" v-if="data.name != 'additional accessories' && data.sort_order">
        <div class="location__drag icon__drag">
          <svg-icon-qouting class="ellipsis" data="fas fa-ellipsis-v" />
          <svg-icon-qouting class="ellipsis" data="fas fa-ellipsis-v" />
          <svg-icon-qouting class="ellipsis" data="fas fa-ellipsis-v" />
        </div>

        <div class="form-group tw-ml-2" style="margin-bottom: 0" v-if="isEditable">
          <input type="checkbox" :disabled="false" :id="locationId" :checked="isAllItemsSelected"
            @click="selectAllLocationItemsForBulkEdit($event)" />
          <label :for="locationId"> </label>
        </div>
        <div class="location__title" :title="data.name">{{ data.name }}</div>
      </div>
      <div class="location__title" v-else :title="data.name">{{ data.name }}</div>
      <div class="tw-flex tw-gap-2">
        <div class="location__actions tw-flex-col-reverse tw-gap-1">
          <div class="location__btn" @click="showWizard()" v-if="isEditable && data.name != 'additional accessories'">
            <svg-icon-qouting data="fas fa-plus" style="font-size: 11px" />
            Add item
          </div>
          <div class="location__btn" @click="$emit('clickAddANewAccessoryBtn')" v-else-if="isEditable">
            <svg-icon-qouting data="fas fa-plus" style="font-size: 11px" />
            Add Accessory
          </div>
        </div>
        <div>
          <button @click="collapsed = !collapsed"
            class="tw-border-none tw-bg-transparent tw-cursor-pointer hover:tw-opacity-70">
            <span v-if="collapsed">
              <svg-icon-qouting data="fas fa-chevron-up" style="font-size: 11px" />
            </span>
            <span v-else>
              <svg-icon-qouting data="fas fa-chevron-down" style="font-size: 11px" />
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="tw-overflow-hidden tw-transition-all" :class="collapsed ? 'tw-max-h-0' : 'tw-max-h-screen'">
      <div class="location__content tw-flex tw-flex-col" :style="{ 'min-height': !isEditable && 'auto' }">
        <div class="location__items-wrapper--- tw-flex" v-if="!isAdditionalAccessory">
          <Loader v-if="!items || (items && !items.length)" />
          <div class="location__section" v-for="(item, index) in items" :key="index" v-else>
            <div class="section__info" v-if="item.section_items.length > 1">
              <div class="tw-flex tw-items-center bulk-edit-selection">
                <div class="form-group" style="margin-bottom: 0" v-if="isEditable">
                  <input type="checkbox" :disabled="false" :value="item" :id="item.section_info.id"
                    v-model="bulkEditStore.itemSelection" v-if="!isAdditionalAccessory" />
                  <label :for="item.section_info.id"> </label>
                </div>
                <span v-if="item.section_info.overall_width === 0 && item.section_info.overall_drop === 0">Make size -
                  {{ item.section_info.layout.name }}</span>
                <span v-else>{{ renderSize(item.section_info) }}
                  {{ item.section_info.layout.name }}</span>
              </div>

              <DotActions class="section__actions" ref="dotActions" v-if="isEditable"
                @clone="() => cloneMultipleTimes('layout', item.section_info.id, 1)" @cloneMultipleTimes="(times) => cloneMultipleTimes('layout', item.section_info.id, times)
                  " @remove="deleteActionFunctionRef = () => removeItem('layout', item.section_info.id)" />
            </div>
            <div class="section__items">
              <Item class="section__item" :noActions="item.section_items.length > 1" :data="section"
                v-for="(section, index) in item.section_items" :key="section.id" :isEditable="isEditable" :area="area"
                @clone="cloneMultipleTimes('position', section.id, 1)" @cloneMultipleTimes="(times) => cloneMultipleTimes('position', section.id, times)
                  " @remove="deleteActionFunctionRef = () => removeItem('position', section.id)" @showWizard="showWizard(section, index)"
                :showBulkEditSelection="item.section_items.length === 1 && isEditable" :item="item" />
            </div>
          </div>
        </div>

        <div class="location__items-wrapper--- tw-flex" v-else>
          <Loader v-if="!items || (items && !items.length)" />
          <div class="location__section" v-for="key in Object.keys(groupedAccessories)" :key="key" v-else>
            <div class="section__items">
              <AccessoryItem :area="area" class="section__item" :name="key" :data="groupedAccessories[key]" :key="key"
                :isEditable="isEditable" @showWizard="(accessory) => showWizard(accessory)"
                @remove="(accessory) => removeAAccessoryPosition(accessory)" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tw-bg-gray-200" v-if="!(!items || (items && !items.length))">
      <div class="tw-flex tw-justify-end tw-flex tw-justify-end tw-px-2 tw-py-1.5">
        <div class="location__count">
          <svg-icon-qouting data="fas fa-layer-group" />
          <strong v-if="items">{{ items.length }}</strong> items
        </div>
        <div v-if="canSeePrice" class="location__price">
          <strong>{{ formatter.formatMoney(data.amount) }}</strong>
        </div>
      </div>
    </div>
    <DeleteConfirmationModal v-model="showDeleteConfirmation" @deleteEstimate="
      removeItem('position', accessoryPositionToBeRemoved.id),
      (showDeleteConfirmation = false),
      (accessoryPositionToBeRemoved = {})
      " />

    <DeleteConfirmationModal v-model="deleteActionFunctionRef" @deleteEstimate="handleDelete"/>

  </div>
</template>

<script>
import { useFormatter } from "../../../composables/formatter";
import Item from "./location/Item.vue";
import AccessoryItem from "./location/AccessoryItem";
import Loader from "../../elements/Loader.vue";
import DotActions from "./location/DotActions.vue";
import api from "../../../services/item.service";
import Checkbox from "../../form/Checkbox";
import DeleteConfirmationModal from "@/components/item-wizard/components/modals/DeleteConfirmationModal";
import { computed, ref, watch } from "vue";
import _ from "lodash";
import { bulkEditStore } from "../bulk-edit/store";
import { useStore } from "vuex";
import {isWidthIsTheSizeToEnterFirst} from "@/helpers/portalCommons";

export default {
  props: [
    "data",
    "isEditable",
    "selectedProductTypeForBulkEdit",
    "locationCollapsed",
    "area",
  ],
  components: {
    Item,
    Loader,
    DotActions,
    AccessoryItem,
    Checkbox,
    DeleteConfirmationModal,
},
  setup(props, { emit }) {
    const showDeleteConfirmation = ref(false);
    const accessoryPositionToBeRemoved = ref({});
    const formatter = useFormatter();
    const dotActions = ref(null);
    const collapsed = ref(false);
    const showLoader = ref(false);
    const deleteActionFunctionRef = ref(null)
    const store = useStore()

    const canSeePrice = computed(() => props.area === 'my-orders'? store.getters['auth/canSeeCost']: store.getters['auth/canSeePrice'])

    function handleDelete() {
      deleteActionFunctionRef.value()
      deleteActionFunctionRef.value = null
    }

    function showWizard(item, index) {
      const emitData = {
        location: props.data.name,
      };
      emitData.is_additional_accessory =
        emitData.location === "additional accessories" && item?.is_additional_accessory;

      if (item) emitData.position_id = item.id;
      if (index) emitData.index = index;
      emit("showWizard", emitData);
    }

    async function removeItem(type, id) {
      const result = await api.removeItem(type, id, props.area);
      if (result == "Deleted") emit("removedItem", props.data);
      // emit('removedItem', props.data)
      // dotActions.value.showDropdown = !dotActions.value.showDropdown
    }

    async function cloneItem(type, id, item) {
      emit("clone", { sectionType: type, sectionId: id });
    }

    async function cloneMultipleTimes(type, id, times) {
      emit("cloneMultipleTimes", {
        sectionType: type,
        sectionId: id,
        times,
        location: props.data.name,
      });
    }

    const isAdditionalAccessory = computed(() => {
      return props.data?.items[0]?.section_items[0]?.is_additional_accessory;
    });

    const groupedAccessories = computed(() => {
      let list = [];
      if (isAdditionalAccessory.value) {
        props.data?.items.forEach((item) => {
          list.push(item.section_items[0]);
        });
      }

      return _.groupBy(list, (i) => i.item?.product_style.name);
    });

    const removeAAccessoryPosition = (position) => {
      (accessoryPositionToBeRemoved.value = position),
        (showDeleteConfirmation.value = true);
    };

    const locationId = computed(() => _.snakeCase(props.data.name));

    const items = computed(() => {
      return props.data.items.map((x, i) => ({ ...x, id: `${locationId.value}-${i}` }));
    });

    const isAllItemsSelected = computed(() => {
      return items.value.every((x) =>
        bulkEditStore.itemSelection.find((i) => i.id === x.id)
      );
    });

    const selectAllLocationItemsForBulkEdit = (e) => {
      const allSelected = e.target.checked;

      if (allSelected) {
        items.value.forEach((item) => {
          if (!bulkEditStore.itemSelection.find((x) => x.id === item.id)) {
            bulkEditStore.itemSelection.push(item);
          }
        });
      } else {
        items.value.forEach((item) => {
          if (bulkEditStore.itemSelection.find((x) => x.id === item.id)) {
            bulkEditStore.itemSelection = bulkEditStore.itemSelection.filter(
              (i) => i.id !== item.id
            );
          }
        });
      }
    };

    const selectedProduct = computed(() => props.selectedProductTypeForBulkEdit);

    watch(selectedProduct, (val) => {
      // val.id: -1 = select all; -2 = deselect all
      if (val.id === -1) {
        if (isAdditionalAccessory.value) return;

        items.value.forEach((item) => {
          if (!bulkEditStore.itemSelection.find((x) => x.id === item.id)) {
            bulkEditStore.itemSelection.push(item);
          }
        });
        return;
      }

      // Deselect all
      if (val.id === -2) {
        bulkEditStore.itemSelection = [];
        return;
      }

      // Filter for product typeid
      let result = [];
      items.value.forEach((item) => {
        if (item.section_items[0].item.product_type.id === val.id) {
          result.push(item);
        }
      });

      const ids = items.value.map((i) => i.id);
      // Remove all selection first
      bulkEditStore.itemSelection = bulkEditStore.itemSelection.filter(
        (x) => !ids.includes(x.id)
      );
      bulkEditStore.itemSelection = [...bulkEditStore.itemSelection, ...result];
    });

    const isAllLocationsCollapsed = computed(() => props.locationCollapsed);
    watch(
      isAllLocationsCollapsed,
      (val) => {
        collapsed.value = val;
      },
      { immediate: true }
    );

    function renderSize(elem){
      if (isWidthIsTheSizeToEnterFirst()){
        return elem.overall_width + " x " + elem.overall_drop
      }
      return elem.overall_drop + " x " + elem.overall_width
    }

    return {
      canSeePrice,
      formatter,
      showWizard,
      dotActions,
      removeItem,
      cloneItem,
      isAdditionalAccessory,
      groupedAccessories,
      showDeleteConfirmation,
      accessoryPositionToBeRemoved,
      removeAAccessoryPosition,
      bulkEditStore,
      isAllItemsSelected,
      locationId,
      selectAllLocationItemsForBulkEdit,
      items,
      collapsed,
      cloneMultipleTimes,
      showLoader,
      deleteActionFunctionRef,
      handleDelete,
      renderSize
    };
  },
};
</script>

<style scoped lang="scss">
.form-group {
  display: flex;
  margin-bottom: 15px;
  //width: 200px;
  // margin-left: -6px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  text-align: left;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--PrimaryButtonColour);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  width: 15px;
  min-width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 2px;
}

.form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 7px;
  width: 3px;
  height: 9px;
  border: solid var(--PrimaryButtonColour);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.location {
  width: 100%;
  box-shadow: 1px 0px 5px rgba(28, 40, 51, 0.5);
  margin-bottom: 20px;

  &__top {
    padding: 8px 5px 8px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15));
    border-radius: 2px 2px 0px 0px;

    @media (max-width: 768px) {
      overflow-x: auto;
    }
  }

  &__actions {
    display: flex;
    align-items: flex-end;
  }

  &__drag {
    display: flex;
    align-items: center;
  }

  &__title {
    font-weight: bold;
    font-size: 21px;
    margin-left: 7px;
    text-transform: uppercase;
    font-family: Sansation;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__count {
    padding-right: 20px;
    border-right: 1px solid rgba(11, 53, 83, 0.2);
    white-space: nowrap;
    margin-left: 5px;

    strong {
      margin-left: 5px;
    }
  }

  &__content {
    padding: 20px;
    // min-height: 249px;
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    border: 1px solid rgba(11, 53, 83, 0.2);
    min-height: 257px;
  }

  &__items-wrapper {
    border-top: none;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    height: 100%;
  }

  &__price {
    padding-left: 20px;
  }

  &__btn {
    cursor: pointer;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.02em;
    white-space: nowrap;
    padding: 5px 10px;
    // background: #59b692;
    background: var(--PrimaryButtonColour);
    border-radius: 2px;
    // color: white;
    color: var(--PrimaryButtonTextColour);
    margin-left: 20px;
  }

  &__section {
    display: flex;
    flex-direction: column;
  }

  .title-wrap {
    max-width: 70%;
    overflow-x: auto;
  }
}

.section {
  &__info {
    width: calc(100% - 10px);
    height: 25px;
    flex-shrink: 0;
    background: var(--PrimaryColour);
    color: var(--PrimaryTextColour);
    padding-left: 10px;
    font-weight: bold;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px 2px 0px 0px;
    transform: translateY(2px);
    position: relative;
    z-index: 10;

    ::v-deep .bulk-edit-selection {
      .form-group label {
        &::before {
          border-color: var(--PrimaryTextColour);
        }

        &::after {
          border-color: var(--PrimaryTextColour);
        }
      }
    }
  }

  &__items {
    display: flex;
    //align-items: flex-end;
    height: 100%;
  }

  &__actions {
    position: absolute;
    right: 0;
    height: 100%;

    ::v-deep .actions__dropdown {
      transform: translateY(72%);
    }
  }

  &__item {
    margin-right: 10px;
  }
}

.icon__drag {
  cursor: pointer;
  width: 20px;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep .icons__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &>* {
    color: rgba(28, 40, 51, 0.6);
    margin-right: 1px;
  }
}

.btn__trash {
  width: 29px;
  height: 29px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(11, 53, 83, 0.4);
}

::v-deep .spinner {
  margin: auto;
  display: block;

  svg {
    font-size: 50px;
  }
}
</style>
