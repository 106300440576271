<template>
  <section>
    <JobView></JobView>
  </section>
</template>

<script>

import { ref } from 'vue'
import JobView from '@/components/jobs/JobView'

export default {
  components: {
    JobView
  },
  setup() {
  }
};
</script>

<style scoped>

</style>
