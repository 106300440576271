<template>
    <div class="states-form tw-text-left" @mouseleave = "showDropdown = false">
        <p class="tw-mb-1">State</p>
        <SelectDropdown
            placeholder="Select State"
            :getSelectedText="() => selectedText"
            :listIsObject="true"
            :list="searchResult.slice(0, 7)"
            :selected="selectedText"
            :getOptionLabel="item => item.name"
            @select="item => updValue(item.name)"  
        />
        
        <!-- <InputGroup
            :modelValue="modelValue" :data="{label: 'State', placeholder: 'Start typing'}"
            @input="updValue($event.target.value)"
            @focus="openDropdown(true)"
            @click="openDropdown(true)"
        >
        </InputGroup>
        <div class="states-form__dropdown" v-if="showDropdown">
            <div
                class="dropdown__item"
                v-for="item in searchResult.slice(0, 5)" :key="item.id"
                :class="{'no-active': item.noActive}"
                @click="!item.noActive && updValue(item.name), showDropdown = false"
                >
                <i class="fa-solid fa-location-dot" v-if="!item.noActive"></i> {{ item.name }}
            </div>
        </div> -->
    </div>
</template>

<script>
import InputGroup from './InputGroup.vue'
import { ref, computed } from 'vue'
import axios from 'axios'
import SelectDropdown from '../elements/SelectDropdown.vue'

export default ({
    props: ['modelValue', 'id'],
    components: {
    InputGroup,
    SelectDropdown
},
    setup(props, { emit }) {
        const states = ref([])
        const showDropdown = ref(false)
        getStates()
        async function getStates() {
          let url = process.env.VUE_APP_API_STATES_URL

          if( typeof url === 'undefined'){
              const api = new URL(process.env.VUE_APP_API_URL).origin
              url = `${api}/api/site/states`
          }
            const result = await axios.get(url)
            states.value = result.data
            emit('updStates', result.data)
        }
        const searchResult = computed(() => {
            const searchQuery = props.modelValue
            if(!searchQuery || searchQuery && !searchQuery.length) return states.value
            const result = states.value //.filter(el => el.name && el.name.split(' ').join('').toLowerCase().includes(searchQuery.split(' ').join('').toLowerCase()))
            return result.length && result || [{ id: 100, name: 'Nothing Found', noActive: true }]
        })

        function updValue(value) {
            emit('update:modelValue', value)
        }

        function openDropdown(value) {
            if(value) showDropdown.value = true
            else {
                setTimeout(() => {
                    if(showDropdown.value) showDropdown.value = false
                }, 1000)
            }
        }

        const selectedText = computed(() => {
            return props.id ? states.value?.find(x => x.state_id === props.id)?.name : props.modelValue
        })  

        return {
            selectedText,
            searchResult,
            updValue,
            showDropdown,
            openDropdown
        }
    },
})
</script>

<style scoped lang="scss">
.states-form {
    
    ::v-deep .input_component.select_dropdown__input {
        font-weight: bold;
        font-size: 17px;
        &::placeholder {
            font-size: 17px;
            font-weight: normal;
            color: black;
            font-style: italic;
        }
    }
    position: relative;
    &__dropdown {
        background: #fff;
        border: 1px solid rgba(11, 53, 83, 0.2);
        border-top: none;
        width: 100%;
        position: absolute;
        z-index: 1000;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
    }
}
.dropdown__item {
    text-align: left;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(11, 53, 83, 0.2);
    font-size: 14px;
    &:last-of-type {
        border-bottom: none;
    }
    svg {
        margin-right: 3px;
        display: inline-block;
    }
    &:hover {
        background: #eaeaea;
        cursor: pointer;
    }
    &.no-active {
        &:hover {
            background: transparent;
            cursor: auto;
        }
    }
}
</style>

