import {reactive, computed} from 'vue'
import api from './../../../services/wizard.service'
import _ from 'lodash'
import {useOptionLogic} from './../../../composables/estimate/create'


export const pcChargeStore = reactive({
    orderPcFees: [],
    supressAlert: false,
    showAlert: false,
    data: null,

    updateOrderFees(payload) {
      this.orderPcFees = payload
    },

    dispose() {
      this.orderPcFees = []
      this.supressAlert = false
      this.data = null
    },

    checkPcCharges(payload){
      this.data = payload
      payload = payload.pc_charges
      let colours = []
      let newColours = []
      _.forEach(this.orderPcFees, function(element){

        if(colours[element.colour]==undefined){
          colours[element.colour] = 0.00;
        }
        colours[element.colour] += +parseFloat(element.pc_fee).toFixed(2);

        if (payload.some((i) => i.order_position_id != element.order_position_id)){
          if(newColours[element.colour]==undefined){
            newColours[element.colour] = 0.00;
          }
          newColours[element.colour] += +parseFloat(element.pc_fee).toFixed(2);
        }

      })
      const chargeThreshold = 70
      const chargeAmount = 49
      const oldCharge = colours.filter((i) => i < chargeThreshold).length * chargeAmount

      _.forEach(payload, function(element) {

        if (newColours[element.colour] == undefined) {
          newColours[element.colour] = 0.00;
        }
        newColours[element.colour] += +parseFloat(element.pc_fee).toFixed(2);
      })
      const newCharge = newColours.filter((i) => i < chargeThreshold).length * chargeAmount
      if (!this.supressAlert)
        this.showAlert = newCharge > oldCharge
      else
        this.showAlert = false

      return this.showAlert
    },
    closeAlert(){
      this.showAlert = false
      this.data = null
    }
  }
)
