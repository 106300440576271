<template>
  <div class="d-flex align-items-center date_section" :class="readonly && 'time_section--readonly'">
    <ErrorField
      :is-error="isErrorDate"
      text-error="Please select correct date"
    >
      <Input small :value="dateValue" placeholder="dd/mm/yyyy" @input="changeDateValue" type="date" />
    </ErrorField>
    <div class="calendar__date" @click="$emit('calendarIsOpened', !calendarIsOpened)" v-if="!hideCalendar">
      <svg-icon class='link-green' name="solid/calendar-alt"/>
      <div>
        <svg-icon v-show="calendarIsOpened === true" name="solid/angle-up"/>
        <svg-icon v-show="calendarIsOpened === false" name="solid/angle-down"/>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "@/components/inputs/Input";
import LinkReturn from "@/components/links/LinkReturn";
// import 'vue-cal/dist/vuecal.css'

export default ({
  emits: ['changeDateValue', 'calendarIsOpened'],
  props: ['isErrorDate','dateValue', 'calendarIsOpened', 'readonly', 'hideCalendar'],
  components: {
    Input,
    LinkReturn
  },
  setup(props,{emit}) {
    let changeDateValue = (value) => {
      emit('changeDateValue', value)
    }
    return {
      changeDateValue
    }
  }
})
</script>

<style scoped lang="scss">
.date_section {
   input{
    width: 180px;
  }
  position: relative;
  &:before {
    content: '';
   // position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
}
.calendar__date {
  display: flex;
  align-items: center;
  color: #59B692;
  margin-left: 10px;
  font-size: 24px;
  cursor: pointer;
  div {
    margin-left: 4px;
    font-size: 12px;
    color: #1C2833;
  }
}
</style>
