
import { onMounted, ref, onBeforeUnmount } from "vue"

export default function usePrint() {
    const viewPortContent = ref(null)
    onMounted(() => {
        var viewportMeta = document.querySelector('meta[name="viewport"]');
        viewPortContent.value = viewportMeta?.content

        const before = window.onbeforeprint ?? (() => {})
        const after = window.onafterprint ?? (() => {})

        window.onbeforeprint = function(e) {
            var viewportMeta = document.querySelector('meta[name="viewport"]');
            if (viewportMeta) {
                viewportMeta.content = ""
            }
            before(e)
        };

        window.onafterprint = function(e) {
            var viewportMeta = document.querySelector('meta[name="viewport"]');
            if (viewportMeta) {
                viewportMeta.content = viewPortContent.value
            }
            after(e)
        };
    })

    onBeforeUnmount(() => {
        var viewportMeta = document.querySelector('meta[name="viewport"]');
        if (viewportMeta) {
            viewportMeta.content = viewPortContent.value
        }
    })

    return {

    }
}