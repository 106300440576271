<template>
  <div class="content__job-customer_details" v-if="!customerData">
    <div>
      <CustomerSelector
        @selectCustomer="selectCustomer"
      >
      <template #searchHeader>
        <div class="content__heading">Use the customer search to display or create a new customer to create a job.</div>
      </template>
      </CustomerSelector>
    </div>
  </div>
  <div class="content__job-customer_details" v-else>
    <div class="content__heading">Customer details</div>
    <div>
      <p>
        {{ customerData.type }}: <strong>{{ customerData.display_name }}</strong>
      </p>
      <p>
        <a href="mailto:{{customerData.email}}">{{ customerData.email }}</a>
      </p>
      <p v-if="customerData.phone || customerData.mobile">
        <a
          href="tel:{{customerData.phone || customerData.mobile}}">{{ customerData.phone || customerData.mobile }}</a>
      </p>
      <p>
        {{ customerData.address }}, {{ customerData.suburb }}
        {{ customerData.state ? customerData.state.code : '' }} {{ customerData.postcode }}
      </p>
      <button class="btn btn_outline" @click="isChangeCustomer = true" v-if="['Open', 'Draft', 'In Production'].includes(jobData.status)">
        Change or Edit customer
      </button>
    </div>

    <div class="content__line"></div>
    <div class="content__heading">Job Details</div>
    <div class="content__job_details">
      <div>
        <div>Reference:</div>
        <Input
          :icon="true"
          class="font-weight-bold"
          :value="jobData.customer_ref"
          :readonly="!['Open', 'Draft'].includes(jobData.status)"
          @input="value => jobData.customer_ref = value"
          :disabled="!['Open', 'Draft', 'new'].includes(jobData.status ?? 'new')"
        />
      </div>

      <div>
        <div class="content__job_details__address_header">
          <span>Job Address</span>
          <Checkbox text="Same as customer address"
                    id="same_job"
                    :value="jobData.job_address.same_as_customer_address"
                    @input="jobData.job_address.same_as_customer_address =!jobData.job_address.same_as_customer_address"/>
        </div>
        <div
          class="d-flex align-items-center justify-content-between content__job_details__address">
          <SingleAddressComponent
            ref="individualAddressREFER"
            class="details_input"
            :suburb-and-post-code-in-same-raw="true"
            :address="jobData.job_address"
            v-if="!jobData.job_address.same_as_customer_address"
            @update:address="jobData.job_address = $event"
          />
        </div>
      </div>

      <div>
        <div class="content__job_details__address_header">
          <span>Billing Address</span>
          <Checkbox text="Same as customer address"
                    id="same_billing"
                    :value="jobData.billing_address.same_as_customer_address"
                    @input="jobData.billing_address.same_as_customer_address =!jobData.billing_address.same_as_customer_address"/>
        </div>
        <div
          class="d-flex align-items-center justify-content-between content__job_details__address">
          <SingleAddressComponent
            ref="individualAddressREFER"
            class="details_input"
            :suburb-and-post-code-in-same-raw="true"
            :address="jobData.billing_address"
            v-if="!jobData.billing_address.same_as_customer_address"
            @update:address="jobData.billing_address = $event"
          />
        </div>
      </div>

      <div v-if="!jobData.status || jobData.status == 'Open'">
        <div class="content__schedule_row">
          <div>Check measure:</div>
          <div>
            <span v-if="jobData.check_measure_date">
          {{ dateFormat(jobData.check_measure_date) }}
        </span>
            <span v-else>
          Not scheduled
        </span>
          </div>
          <div class="content__schedule_row__button">
            <button class="btn small"
                    :class="{'btn_default' : !jobData.check_measure_date, 'btn_outline' : jobData.check_measure_date}"
                    @click="isScheduleCheckMeasure = !isScheduleCheckMeasure">
              <svg-icon name="solid/calendar-alt"/>
              {{ jobData.check_measure_date ? 'Re-schedule' : 'Schedule' }}
            </button>
          </div>
        </div>

        <Modal v-if="isScheduleCheckMeasure" v-model="isScheduleCheckMeasure"
               :handleClose="() =>emits('update:shows',false)">
          <ScheduleEvent
            title="Check measure"
            :dateTimeValue="jobData.check_measure_date"
            v-model="jobData.check_measure_date"
            @update:modelValue="jobData.check_measure_date = $event, isScheduleCheckMeasure = false"
          />
        </Modal>
      </div>

      <div>
        <div class="content__schedule_row">
          <div>Installation:</div>
          <div>
            <span v-if="jobData.installation_date">
              {{ dateFormat(jobData.installation_date) }}
            </span>
            <span v-else>
              Not scheduled
            </span>
          </div>
          <div class="content__schedule_row__button">
            <button class="btn small"
                    :class="{'btn_default' : !jobData.installation_date, 'btn_outline' : jobData.installation_date}"
                    @click="isScheduleInstall = !isScheduleInstall">
              <svg-icon name="solid/calendar-alt"/>
              {{ jobData.installation_date ? 'Re-schedule' : 'Schedule' }}
            </button>
          </div>
        </div>

        <Modal v-if="isScheduleInstall" v-model="isScheduleInstall"
               :handleClose="() =>emits('update:show',false)">
          <ScheduleEvent
            title="Installation"
            :dateTimeValue="jobData.installation_date"
            v-model="jobData.installation_date"
            @update:modelValue="jobData.installation_date = $event, isScheduleInstall = false"
          />
        </Modal>
      </div>
      <div>
        <div>Estimated installation time (hrs): </div>
        <Input small placeholder="Est. time (hrs)"
               :value="jobData.installation_time_estimate"
               type="number"
               @input="jobData.installation_time_estimate = $event"
        />
      </div>



      <div>
        <div>Notes:</div>
        <Input :value="jobData.notes"
               @input="value => jobData.notes = value"/>
      </div>
    </div>
    <div class="content__line"></div>

    <div class="content__save" :class="activeMenuItem == 2 && 'content__save--products'">
      <div>
        <ButtonMain @click="saveChanges">
          <svg-icon name="solid/check"/>

          {{ jobId === 'new' ? 'Create Job' : 'Update Job Details' }}
        </ButtonMain>
      </div>
    </div>

  </div>
  <Teleport to="body">
    <Modal class="change_customer_modal" v-if="isChangeCustomer" :modelValue="isChangeCustomer"
          @update:modelValue="isChangeCustomer = false">
      <div class="change_customer_modal">
        <h4>Please note that upon customer update current customer specific pricing may be replaced
          with
          new customer's or generic price settings</h4>
        <CustomerSelector
          @selectCustomer="changeCustomer"
          :selected="customerData"
          selectButtonTitle="Change Customer"
          :noEdit="false"
        />
      </div>
    </Modal>
  </Teleport>
</template>

<script>
import {reactive, ref, computed, onMounted, watch} from 'vue'
import {useStore} from 'vuex'
import LinkReturn from "@/components/links/LinkReturn";
import SimpleDropdown from "@/components/dropdown/SimpleDropdown";
import Input from "@/components/inputs/Input";
import Checkbox from "@/components/inputs/Checkbox";
import SelectDropdown from "@/components/dropdown/SelectDropdown";
import {useRoute, useRouter} from "vue-router";
import {dateCalcFormat, dateForRequest, timeForRequest} from "@/helpers/datetime";
import SingleAddressComponent from "@/components/commons/NewSingleAddressComponent";
import SelectDropdownInput from "@/components/dropdown/SelectDropdownInput";
import CustomerSelector from "@/components/customer/CustomerSelector";
import ButtonMain from "@/components/buttons/ButtonMain";
import jobApi from "@/services/jobs/job"
import Modal from '@/components/item-wizard/components/elements/Modal'
import ScheduleEvent from '@/components/commons/ScheduleEventComponent'
import Calendar from '@/components/commons/Calendar'

export default ({
  components: {
    LinkReturn,
    SimpleDropdown,
    SelectDropdown,
    Input,
    Checkbox,
    SingleAddressComponent,
    CustomerSelector,
    ButtonMain,
    Modal,
    ScheduleEvent,
    Calendar
  },
  props: ['job', 'jobId'],
  setup(props, {emit}) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const customerData = ref(null)
    const jobData = ref(null)

    const isChangeCustomer = ref(false)
    const isScheduleCheckMeasure = ref(false)
    const isScheduleInstall = ref(false)

    customerData.value = props.job?.customer
    jobData.value = JSON.parse(JSON.stringify(props.job || {
      job_address: {
        same_as_customer_address: true
      },
      billing_address: {
        same_as_customer_address: true
      }
    }))

    async function selectCustomer(c) {

      customerData.value = c

    }

    let saveChanges = async () => {
      store.commit('preloader/preloader', true);
      let parameters = {
        "customer_id": customerData.value.id,
        "customer_ref": jobData.value.customer_ref,
        "notes": jobData.value.notes,
        "check_measure_date": jobData.value.check_measure_date,
        "installation_date": jobData.value.installation_date,
        "installation_time_estimate": jobData.value.installation_time_estimate,
        "address": {
          "job_address": {
            ...jobData.value.job_address,
            "same_as_customer_address": jobData.value.job_address.same_as_customer_address || false,
          },
          "billing_address": {
            ...jobData.value.billing_address,
            "same_as_customer_address": jobData.value.billing_address.same_as_customer_address || false
          }
        }
      }

      if (props.jobId !== 'new') {
        let job = await jobApi.update(props.jobId, parameters)
        if (job.data) {
          emit('save', job.data)
        }
      } else {
        let job = await jobApi.create(parameters)

        if (job.data) {
          router.push({name: 'job', params: {id: job.data.id}})
        }
      }

      store.commit('preloader/preloader', false);

    }

    async function changeCustomer(new_customer) {

      if(props.jobId == 'new') {
        customerData.value = new_customer
        isChangeCustomer.value = false
        return
      }

      let result = await jobApi.changeCustomer(props.jobId, {customer_id: new_customer.id})
      customerData.value = new_customer
      if (result.success) {
        emit('customerChanged')
        //  router.push({name:'job', params: {id: props.jobId}, force: true, replace: true, hash: '?u=' + Date.now()})
         isChangeCustomer.value = false
      }

      if(!result?.errors) {
        isChangeCustomer.value = false
      }

    }

    let dateFormat = (date) => {
      return dateCalcFormat(date, true, false)
    }

    return {
      customerData,
      saveChanges,
      selectCustomer,
      isChangeCustomer,
      isScheduleCheckMeasure,
      isScheduleInstall,
      changeCustomer,
      jobData,
      dateFormat
    }
  },
})
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

::v-deep(.modal) {
  max-height: 83vh;
  overflow: auto;
  align-items: unset;
}
.content {
  &__heading {
    font-weight: 300;
    font-size: 27px;
    display: flex;
    align-items: center;
    color: rgba(28, 40, 51, 0.5);
    padding-bottom: 20px;
  }

  &__job-customer_details {
    max-height: calc(100vh - 80px);
    padding: 30px 20px 30px 20px;
    width: 750px;
    max-width: 100%;
    font-size: 13px;
    color: rgba(28, 40, 51, 0.7);
    overflow-y: scroll;
    position: relative;

    .dropdown_price {
      width: 255px
    }

    .details_input {
      width: 350px;

      ::v-deep input {
        margin-bottom: 15px;
      }
    }
  }

  &__job_details {

    > div {
      padding-bottom: 15px;
    }

    &__address_header{
      display: flex;
      gap: 10px;

      >span{
        width: 100px;
      }
    }

    &__address {
      div:last-child {
        flex-shrink: 0;
        margin-left: 30px;
      }
    }

  }

  &__schedule_row{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    .btn{
      margin: 0;
    }
  }

}

.job-customer__calendar {
  ::v-deep input {
    width: 150px;
  }
}

.job-customer__time {
  ::v-deep input {
    width: 90px;
  }
}

::v-deep .simple_modal__container {
  padding: 0;
  width: 500px;
}


.schedule_modal {
  &__calendar {
    display: flex;
    align-items: center;
    color: #59B692;
    margin-left: 10px;
    font-size: 24px;
    cursor: pointer;

    div {
      margin-left: 4px;
      font-size: 12px;
      color: #1C2833;
    }
  }

  &__time {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 15px;

    > a {
      font-size: 15px;
    }

    span {
      margin-right: 3px;
    }
  }

  &__body {
    width: 420px;

    ::v-deep .vuecal {
      &__cell {
        &--selected {
          background: rgba(11, 53, 83, 0.2);
        }

        &:hover {
          cursor: pointer;
          background: rgba(11, 53, 83, 0.1);
        }

        &--has-events {
          background: rgba(89, 182, 146, 0.2);

          &:hover {
            background: rgba(89, 182, 146, 0.4)
          }

          &.vuecal__cell--selected {
            background: rgba(89, 182, 146, 0.4)
          }
        }
      }
    }
  }
}

.change_customer_modal {
  display: block;
  max-width: 100%;
  width: 600px;
  padding: 20px;
}

@media (max-width: 768px) {
  .content {
    &__job-customer_details {
      height: calc(100% - 80px);
      padding: 0;

      .dropdown_price {
        width: 100%;
      }

      > div {
        padding: 10px 20px 12px;
        width: 100%;

        > div {
          width: 100%;
        }
      }

      .details_input {
        width: 100%;

        ::v-deep input {
          margin-bottom: 10px;
        }
      }
    }

    &__job_details {
      > div {
        padding-bottom: 20px;
      }

      > .d-flex {
        flex-direction: column;
      }

      &__address {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start !important;

        div:last-child {
          width: auto;
          flex-shrink: 0;
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
  ::v-deep .simple_modal__container {
    padding: 0;
    width: 500px;
  }
  .schedule_modal {
    &__body {
      width: 100%;
    }

    &__label {
      padding-top: 10px;
    }
  }

}
</style>
