<script setup>
import api from "../../../services/item.service";
import { useStore } from "vuex";
import SummaryTabs from "./SummaryTabs.vue";
import RenderOSQRTable from "./RenderOSQRTable.vue";
import { onMounted, watch } from "@vue/runtime-core";
import InlinePreloader from "./InlinePreloader.vue";
import _ from "lodash";

import { useRoute } from "vue-router";
import { computed, ref } from "@vue/reactivity";
import { onBeforeUnmount } from "vue";
import usePrint from "../../../composables/usePrint";
const route = useRoute();
const order_num = ref(0);
const mainItems = ref([]);
const previewImage = ref(null);
const currenFinance = ref(null);
const printWindow = ref(false);
const outOfSquarePrints = ref([]);
const isImage = ref(null);

const store = useStore()

const items = ref(null); //computed(() => store.state["orderDetails"].items);

const isLoading = ref(true);
usePrint()

async function isBase64UrlImage(base64String) {
  let image = new Image();
  image.src = base64String;
  return await new Promise((resolve) => {
    image.onload = function () {
      if (image.height === 0 || image.width === 0) {
        resolve(false);
        return;
      }
      resolve(true);
    };
    image.onerror = () => {
      resolve(false);
    };
  });
}

let openImagePreview = async (val) => {
  isImage.value = await isBase64UrlImage(val);
  previewImage.value = val;
};

const closeImagePreview = (val) => {
  previewImage.value = null;
};

const changeTab = (i) => {
  currenFinance.value = i;
};

const printReport = () => {
  printWindow.value = true;
  setTimeout(() => {
    window.print();
    printWindow.value = false;
  }, 100);
};

let printImage = () => {
  var win = window.open("about:blank", "_new");
  win.document.open();
  win.document.write(
    [
      "<html>",
      "   <head>",
      "   </head>",
      '   <body onload="window.print()" onafterprint="window.close()" maxHeight="100%" maxWidth="100%">',
      '       <img src="' + previewImage.value + '"/>',
      "   </body>",
      "</html>",
    ].join("")
  );
  win.document.close();
};

function getBase64FileExtension(encoded) {
  var result = null;

  if (typeof encoded !== "string") {
    return result;
  }

  var mime = encoded.match(/data:([a-zA-Z0-9]+\/([a-zA-Z0-9-.+]+)).*,.*/);

  if (mime && mime.length) {
    result = mime[2];
  }

  return result;
}

function download(Base64String) {
  var a = document.createElement("a"); //Create <a>
  a.href = Base64String;
  a.download = "Out-of-square." + getBase64FileExtension(Base64String); //File name Here
  a.click();
}

const closeOsqlModal = async () => {
  await loadData();
  regenerateOsqrImageForSettings.value = null;
};

const loadData = async () => {
  //   GlobalMixins.startLoader();
  //   await store.dispatch("orderDetails/productionReview", route.params.id);
  isLoading.value = true;
  const res = await api.getSpecificationNew(route.params.id, route.params.type);
  items.value = res.data;
  order_num.value = res.data.order_id; //items.order_id;
  mainItems.value = res.data.mainItems; //items.mainItems;
  printWindow.value = false;
  //   GlobalMixins.stopLoader();
  isLoading.value = false;
};

const canSeePrice = computed(() => store.getters['auth/canSeePrice'])
const canSeeCost = computed(() => store.getters['auth/canSeeCost'])

onMounted(async () => {
  await loadData();

    let elAction = document.querySelector(".top-action");
    let elHeader = document.querySelector(".header-info");
    let observer = new IntersectionObserver(
      (entries) => {
        if (!entries[0].isIntersecting) {
          elAction?.classList.add("action-fixed");
        } else {
          elAction?.classList.remove("action-fixed");
        }
      },
      {
        threshold: 0.2,
      }
    );
    observer.observe(elHeader);
});

watch(items, () => {
  if (Object.keys(items.value || {}).length > 0) {
    order_num.value = items.value.order_id;
    outOfSquarePrints.value = Object.values(items.value.mainItems).filter(
      (i) => i.required["Out of Square File"]
    );
  }
});

const mainItemsRemapped = computed(() => {
  if (!items.value.mainItems) return;
  return Object.values(items.value.mainItems).map((item) => {
    item.col1 = {};
    item.col2 = {};
    const all = { ...item.required, ...item.optional };
    const keys = Object.keys(all);
    keys.slice(0, keys.length / 2 + 1).forEach((key) => {
      item.col1[key] = all[key];
    });
    keys.slice(keys.length / 2 + 1).forEach((key) => {
      item.col2[key] = all[key];
    });
    return item;
  });
});
const isAdmin = () => {
  return true;
};
let filterNotOSQR = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([key, value]) => {
      // return key !== "Out of Square Table";
      return !["Out of Square Table", "Out of Square File"].includes(key);
    })
  );
};

const regenerateOsqrImageForSettings = ref(null);

const regenerateOutOfSquareImage = (item) => {
  regenerateOsqrImageForSettings.value = {
    ...item.settings,
    positionId: item.id,
  };
};

onBeforeUnmount(async () => {
  //   await store.commit("orderDetails/items", {});
});
</script>
<template>
  <div class="container wrapper" v-if="isLoading">Loading....</div>

  <template v-else>
    <div id="printOrderView" class="container wrapper tw-text-black">
      <section v-if="!items.dealer">
        <div class="tw-flex tw-h-80 tw-justify-center tw-items-center">
          <div class="tw-text-center">
            <InlinePreloader class="tw-h-12" message="Loading data, please wait..." />
          </div>
        </div>
      </section>
      <section v-else>
        <div class="row header-info">
          <div
            v-bind:class="{ 'col-4': printWindow }"
            class="col-md-4 tw-mb-4 md:tw-mb-0 print-col-4"
          >
            <h4 class="h4">
              Order
              {{ items.status == "PEND" ? "Review" : "Details" }}
            </h4>
            <p class="">Order ID Number: {{ items.order_id }}</p>
            <p>Date: {{ items.order_date }}</p>
            <p v-if="items.reference">Reference: {{ items.reference }}</p>
            <p v-if="items.job_address && !items.job_address?.same_as_customer_address">Job address: {{ items.job_address?.address }}, {{ items.job_address?.suburb }} {{ items.job_address?.state?.code }} {{ items.job_address?.postcode }}</p>
            <p v-else-if="items.customer?.address">Job address: {{ items.customer?.address }}, {{ items.customer?.suburb }} {{ items.customer.state?.code }} {{ items.customer?.postcode }}</p>

            <h4 class="h4 mt-2- tw-mt-4 sm:tw-mt-2">Dealer</h4>
            <p v-if="items.dealer">Dealer: {{ items.dealer.name }}</p>

            <p v-if="items.dealer">Email: {{ items.dealer.email }}</p>
            <p v-if="items.dealer">Phone: {{ items.dealer.phone }}</p>
          </div>
          <div v-bind:class="{ 'col-4': printWindow }" class="col-md-4 print-col-4">
            <h4 class="h4">Customer</h4>
            <div v-if="items && items.customer && Object.keys(items.customer).length > 0">
              <p>Name: {{ items.customer?.name }}</p>
              <p>Email: {{ items.customer?.email }}</p>
              <p>Phone: {{ items.customer?.phone }}</p>
              <p>Address: {{ items.customer?.address }}, {{ items.customer?.suburb }} {{ items.customer?.state?.code }} {{ items.customer?.postcode }}</p>
            </div>
            <div v-else>No customer</div>
          </div>

          <div
            class="col-md-4 tw-mt-4 print-col-4"
            v-if="items.finance"
            v-bind:class="{ 'col-4': printWindow }"
          >
            <SummaryTabs
              :finance="items.finance"
              @change="changeTab"
              :print-window="printWindow"
            />
          </div>
          <div class="col-12 no_print">
            <button
              v-if="items.status == 'PEND'"
              class="btn btn_default"
              type="button"
              @click.prevent="approveOrder(items.order_id)"
            >
              Approve
            </button>
            <button
              v-if="items.status == 'PEND'"
              class="btn btn_default"
              type="button"
              @click.prevent="openModalReject(items.order_id)"
            >
              Reject
            </button>
          </div>
        </div>
        <div
          class="top-action top-action tw-z-10 tw-w-full tw-bg-white tw-transition-all tw-sticky tw-top-12"
        >
          <div class="row" id="buttons" v-if="items.status == 'PEND' && isAdmin()">
            <div class="col">
              <button
                class="btn btn_default"
                type="button"
                @click.prevent="sendToProduction()"
              >
                Send to Production
              </button>
              <button
                type="button"
                class="btn btn_default"
                @click.prevent="openModalReject()"
              >
                REJECT
              </button>
            </div>
          </div>

          <div v-if="items.dealer" class="row no_print">
            <div class="col">
              <button
                class="btn btn_default"
                type="button"
                @click.prevent="$router.back()"
              >
                Back
              </button>
              <button
                class="btn btn_default"
                type="button"
                @click.prevent="printReport()"
              >
                Print
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <h4 class="h4">Product Details</h4>
          </div>
        </div>

        <div
          class="row small item-container"
          v-for="(item, i) in mainItemsRemapped"
          :key="i"
        >
          <hr class="divider mx-2" />

          <div class="col-12">
            <h4 class="h4">{{ item.name }}</h4>
          </div>
          <div v-bind:class="{ 'col-2': printWindow }" class="col-md-1 item-icon print-col-2">
            <svg-icon-qouting  :data="item.layout_image || item.icon" alt="" />
          </div>
          <div v-bind:class="{ 'col-4': printWindow }" class="col-md-5 print-col-4">
            <div v-for="(val, key) in item.col1" :key="key">
              <strong>{{ key }}: </strong>
              <span v-if="key == 'Out of Square File'"
                ><a @click="openImagePreview(val)" class="btn_link">
                  <svg-icon name="solid/file" /> View</a
                ></span
              >
              <span v-else v-html="val"></span>
            </div>
          </div>

          <div v-bind:class="{ 'col-6': printWindow }" class="col-md-6 print-col-6">
            <div v-for="(val, key) in filterNotOSQR(item.col2)" :key="key">
              <strong>{{ key }}: </strong>
              <span v-html="val"></span>
            </div>
            <div
              class="no_print"
              v-if="_.get(item.required, 'Out of Square File', false)"
            >
              <span>
                <a
                  @click="openImagePreview(item.col2['Out of Square File'])"
                  class="btn_link"
                >
                  <svg-icon name="solid/file" /> View
                </a>
                <span class="ml-2 print-d-none" :class="{ 'd-none': printWindow }">
                  <input
                    :id="`${item.id}-print-osqr`"
                    :checked="true"
                    type="checkbox"
                    :value="item"
                    v-model="outOfSquarePrints"
                  />
                  <label :for="`${item.id}-print-osqr`"
                    >Include a drawing on printout</label
                  >
                </span>
              </span>

              <div
                class="tw-hidden no_print"
                v-if="item.settings?.OSQR?.is_custom_image !== true"
              >
                <span
                  class="tw-underline tw-text-secondary tw-cursor-pointer hover:tw-no-underline"
                  @click="regenerateOutOfSquareImage(item)"
                  >Re-generate image</span
                >
              </div>
            </div>
          </div>
          <div
            v-bind:class="{ 'col-6': printWindow }"
            class="col-md-6 text_right print-col-6"
            v-if="currenFinance && item.finance && canSeeCost"
          >
          </div>
          <div
            v-bind:class="{ 'col-6': printWindow }"
            class="col-md-6 text_right print-col-6"
            v-if="currenFinance && item.finance"
          >
            <p class="text_print" v-if="currenFinance !== 'customer' && canSeeCost && item.finance[currenFinance]['Products Cost'].amount">
              Cost ${{
                Number(item.finance[currenFinance]["Products Cost"].amount)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              }}
            </p>

            <p class="text_print" v-if="canSeePrice && item.finance[currenFinance]['Product Price'].amount">
              Price ${{
                Number(item.finance[currenFinance]["Product Price"].amount ?? 0)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              }}
            </p>
          </div>
        </div>

        <div
          class="row"
          v-if="items.accessories && items.accessories.length > 0"
          style="page-break-inside: avoid"
        >
          <div class="col-12">
            <hr class="divider px-2" v-if="printWindow" />
            <h4 class="h4">Additional Accessories</h4>
          </div>
          <div class="col-12">
            <div class="row" v-for="(accessory, key) in items.accessories" :key="key">
              <div v-bind:class="{ 'col-8': printWindow }" class="col-md-8 print-col-8">
                {{ Number(accessory.qty).toFixed(0) }} x {{ accessory.name }} -
                {{ accessory.colour_name }}
              </div>
                  <div v-bind:class="{ 'col-4': printWindow }" class="col-md-4 text_right print-col-4">
                    <p
                      class="text_print"
                      v-if="currenFinance && currenFinance !== 'customer' && canSeeCost && accessory.finance[currenFinance].cost"
                    >Cost ${{
                        Number(accessory.finance[currenFinance].cost)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      }}</p>
                    <p class="text_print" v-if="currenFinance && canSeePrice && accessory.finance[currenFinance].price"
                      >Price ${{
                        Number(accessory.finance[currenFinance].price)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      }}</p
                    >
                  </div>
            </div>
          </div>
        </div>
        <div class="print-only print-d-none" :class="{ 'd-none': !printWindow }">
          <div v-for="(item, i) in outOfSquarePrints" :key="i">
            <div class="page-break"></div>
            <div
              class="pt-3 d-flex; flex-column; align-items-center; justify-content-center tw-overflow-auto"
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
            >
              <h4>
                <span class="tw-text-primary tw-font-bold">#{{ item.count }}</span>
                - <span>{{ item.name }}</span>
              </h4>
              <h5>Location: {{ item.required["Location"] }}</h5>
              <img
                :src="item.required['Out of Square File']"
                class="tw-w-auto tw-h-auto"
              />
              <div
                v-if="item.required['Out of Square Table']"
                class="tw-text-center tw-mt-2"
              >
                <RenderOSQRTable :table-data="item.required['Out of Square Table']" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="row" id="buttons" v-if="items.status == 'PEND' && isAdmin()">
        <div class="col">
          <button
            class="btn btn_default"
            type="button"
            @click.prevent="sendToProduction()"
          >
            Send to Production
          </button>
          <button
            type="button"
            class="btn btn_default"
            @click.prevent="openModalReject()"
          >
            REJECT
          </button>
        </div>
      </div>

      <div v-if="items.dealer" class="row no_print">
        <div class="col">
          <button class="btn btn_default" type="button" @click.prevent="$router.back()">
            Back
          </button>
          <button class="btn btn_default" type="button" @click.prevent="printReport()">
            Print
          </button>
        </div>
      </div>
    </div>

    <transition v-if="previewImage" name="fade" mode="out-in">
      <div class="modal modal_wrap">
        <div class="modal_popup modal_full modal_ligthBg">
          <div class="modal_content tw-text-center">
            <img v-if="isImage" :src="previewImage" ref="osrq_image" class="tw-w-auto tw-max-w-full" />
            <div v-else class="tw-full text-center">
                <h2>Preview is unavailable</h2>
                <h3>
                  <a @click="download(previewImage)" class="btn_link">
                    Click here to download
                  </a>
                </h3>
              </div>
          </div>
          <span class="modal__close" @click="closeImagePreview">
            <span class="text_green">
              <svg-icon name="solid/times" class="text_green"></svg-icon>
            </span>
          </span>
          <div class="justify-content-between">
            <button v-if="isImage" class="btn btn_transparent" @click="printImage">Print</button>
            <button
                  class="btn btn_transparent"
                  @click="download(previewImage)"
                  v-else
                >
                  Download
                </button>
          </div>
        </div>
      </div>
    </transition>
  </template>

  <!-- TODO:Kiruba - REGENERATE OSQR -->
  <!-- <transition name="fade">
    <div
      v-if="regenerateOsqrImageForSettings"
      class="modal modal_wrap generic-modal"
    >
      <div class="modal_popup modal_full">
        <div class="modal-content-wrapper">
          <div class="container modal_content">
            <OutOfSquare
              :orderId="items.order_id"
              :positionId="regenerateOsqrImageForSettings.positionId"
              :settings="regenerateOsqrImageForSettings.OSQR"
              :width="regenerateOsqrImageForSettings.width"
              :drop="regenerateOsqrImageForSettings.drop"
            ></OutOfSquare>
          </div>

          <span class="modal__close" @click="closeOsqlModal">
            <span class="text_green">
              <svg-icon name="solid/times" class="text_green"></svg-icon>
            </span>
          </span>
        </div>
        <slot name="footer" />
      </div>
    </div>
  </transition> -->
</template>

<!--<script>-->
<!--// export default {-->
<!--//   name: "ProductionReviewIndex",-->
<!--// };-->
<!--</script>-->

<style lang="scss">
.footer {
  @media print {
    display: none;
  }
}
</style>

<style scoped lang="scss">
@import "@/assets/scss/utils/vars";


.text_print {
  color: $PrimaryColour;
}

.print-on {
  background: green;
}

@media print {
  .print-col-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .print-col-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .print-col-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .print-col-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .print-d-none {
    display: none!important;;
  }

}

.modal_popup {
  padding: 2rem;
}


::v-deep h4{
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.wrapper {
  margin-top: 10px;

  @media print {
    margin-top: 0;
  }
}

.action-fixed {
  max-width: 1110px;
  transform: translateY(3.3rem);
  @apply tw-fixed tw-top-0 tw-border-b tw-border-solid tw-border-gray-300;
}
.btn {
  margin-right: 2rem;
}

.item-icon {
  font-size: 60px;
  color: #59b692 !important;
}

.modal_additional .btn {
  margin-top: 40px;
}

.thumbnailsTable {
  max-width: 200px;
  margin: 0 auto;
  padding: 1rem;
  cursor: pointer;
}

.orderItem__listItem {
  display: block;
}

.accessories_list__wrapper .toggleList__title {
  width: 100%;
}
hr.divider {
  margin: 0em;
}
@media screen and (max-width: $sm) {
  .thumbnailsTable {
    position: static;
    transform: none;
  }
}

.item-icon {
  max-width: 100px;
  width: auto;
  // max-height: 100px;

  ::v-deep svg {
    max-width: 100%;
    width: 100%!important;
    height: auto;
    vertical-align: top;
  }
}

.columns {
  column-count: 2;
  column-gap: 20px;
  width: 100%;
}

.item-container {
  padding: 5px 0;
}

.orderItem {
  &_open {
    .orderItem__listItems {
      max-height: none;
      overflow: hidden;
    }
  }
}

.orderItem__listItems {
  max-height: none;
  /*overflow: auto;*/
}

.accessories_count {
  margin-bottom: 20px;
}

.modal {
  &_p90 {
    padding-bottom: 90px;
  }
}

table#slip {
  width: 100%;
  font-size: 0.7em;

  td.text-large {
    font-size: 1.3em;
  }

  .border-top {
    border-top: 2px solid;
  }

  .border-left {
    border-left: 2px solid;
  }

  .border-right {
    border-right: 2px solid;
  }

  .border-bottom {
    border-bottom: 2px solid;
  }

  .high {
    height: 4em;
  }
}

table#sheet {
  width: 100%;
  font-size: 0.7em;
  border: 1px solid;

  tbody tr {
    background-color: #ffffcc;
  }

  td,
  th {
    border: 1px solid;
  }

  td {
    text-align: center;
  }
}

table#length {
  width: 100%;

  font-size: 0.8em;

  thead {
    border: 2px solid;

    th {
      border-left: 1px solid;
      vertical-align: middle;
    }

    th.gap {
      width: 50px !important;
    }
  }

  tbody {
    tr.spacer {
      border-bottom: 2px solid;
    }

    tr.total {
      padding-top: 5px;
      background-color: #ddd;
      border-top: 2px solid;
      border-bottom: 2px solid;
    }

    tr:not(.spacer) {
      td:first-child {
        border-left: 2px solid;
      }

      td:last-child {
        border-right: 2px solid;
      }
    }

    td {
      vertical-align: middle;
      text-align: center;
    }
  }

  tr:not(.total) {
    td {
      border: 1px solid;
    }
  }

  tr.spacer {
    td {
      border: none;
    }
  }

  .gray {
    background-color: #ddd;
  }
}

.strong {
  font-weight: bold;
}

.price {
  width: 100%;
}

@media print {
  // @page {
  //   size: 21cm 29.7cm;
  // }

  .container {
    font-size: 11px !important;
  }

  #buttons {
    display: none !important;
  }

  .no_print {
    display: none;
  }

  .page-break {
    clear: both;
    page-break-after: always;
  }
  .item-container {
    page-break-inside: avoid;
  }
  h4 {
    font-size: 1rem;
  }
  .text_print {
    font-size: 1rem;
    margin: 0;
    // color: $navy;
  }
  hr.divider {
    margin: 0em;
  }
}
</style>
