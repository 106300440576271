import { ref, unref, reactive } from 'vue'

export default function useConfirmAsync() {
    const promiseResponse = ref(null)

    const resolve = (val) => {
        promiseResponse.value(val)
    }

    const prompting = ref(false)

    const _continue = (val = true) => {
        promiseResponse.value(val)
    }

    const cancel = (val = false) => {
        promiseResponse.value(val)
    }

    return reactive ({
        confirm: () => {
            prompting.value = true

            return new Promise((resolve, reject) => {

                promiseResponse.value = (response) => {
                    prompting.value = false
                    resolve(response)
                }
            })
        },
        resolve,
        prompting: prompting,
        continue: _continue,
        cancel
    })
}

// const closeModalConfirmation = useConfirmAsync()
// await closeModalConfirmation.confirm()

// closeModalConfirmation.continue()