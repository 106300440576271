// store.js
import { reactive, computed } from 'vue'
import api from './../../../services/wizard.service'
import _ from 'lodash'
import { useOptionLogic } from './../../../composables/estimate/create'



export const bulkEditStore  = reactive({
    // State
    items: [],
    itemSelection: [],
    bulkEditOption: null,
    loadingData: false,
    targetSelection: null,
    activeScreen: 'bulkEditOption', //['bulkEditOption', 'initialBulkEditSelection', 'itemWizard']
    currentStepId: null,
    currentOptionCode: null,
    currentStepName: null,
    savingChanges: false,
    fillItems(items) {
        // eslint-disable-next-line no-undef
        this.items = _.cloneDeep(items)
        this.items.forEach(i => {
            i['id'] = crypto.randomUUID(),
            i['selected'] = false
        })

    },
    async saveChanges() {
        this.savingChanges = true
        
        for(let i = 0; i < this.items.length; i++) {
            const item = this.items[i]
            item['error'] = false
            item['saved'] = false

            try{
                await api.save('save', item.initial_data)
                item['saved'] = true
            }catch(err) {
                item['error'] = true
                console.log('Something went wrong, while saving the changes', item.id) 
            }
        }
        
        this.savingChanges = false
        console.log('All changes saved successfully!')
    },
    getPrice(item) {
        const optionLogic = useOptionLogic({})

        // if(item.currentPrice) return item.currentPrice
        const sizeStep = item.initial_data?.steps.find(el => el.name == 'Size')
        if(!sizeStep) return
        const sections = sizeStep?.data?.sections
        const sectionsPrice = optionLogic.getPrice(sections, false)

        if(!item.initial_data.dry_price) return 0
        let value = 0
        if(Array.isArray(sectionsPrice)) {
            sectionsPrice.forEach(section => {
            for(const key in section) {
                value += section[key]
            }
            })
        }
        return item.initial_data.dry_price + value
    },
    exit() {
        this.clean()
        this.items = []
        this.itemSelection = []
    },
    clean() {
        this.bulkEditOption = null
        this.items.forEach(x => x['selected'] = false)
        this.activeScreen = 'bulkEditOption'
        this.loadingData = false
    },
    selectBulkEditOption(option) {
        console.log('step....', option)
        this.bulkEditOption = option

        let item = this.items[0]
        const step = item.initial_data?.steps.find(x => x.name.toLowerCase().split(' ').join('_') === this.bulkEditOption.name)
        console.log(step)
        this.setCurrentStepInfo(step.id, step.optionCode, step.name)

        this.showBulkEditForFirstItem()
    },

    setCurrentStepInfo(stepId, optionCode='', name = '') {
        this.currentStepId = stepId
        this.currentOptionCode = optionCode
        this.currentStepName = name
    },

    async showBulkEditForFirstItem() {
        this.items.forEach(x => {
            x.selected = false
        })
        await this.jumpToBulkEditStepAndUpdateData()
        console.log('After jump')
        let firstItem = this.items[0]
        firstItem['selected'] = true
        this.activeScreen = 'initialBulkEditSelection'
    },

    async jumpToBulkEditStepAndUpdateData() {
        this.loadingData = true
        let promises = []
        this.items.forEach(item => {
            // const stepIdForBulkEdit = item.initial_data?.steps.find(x => x.name.toLowerCase().split(' ').join('_') === this.bulkEditOption.name).id
            let p = api.routeJumpTo(this.currentStepId, item.initial_data).then(res => {
                item['initial_data'] = res
                
                if(res.current_step.name === 'Type') {
                    item.initial_data.current_step.settings.data = item.initial_data.current_step.settings.data.filter(x => ["Window 9mm", "Window 11mm"].includes(x.name))
                }
            })

            promises.push(p)
        })

        await Promise.all(promises).then(res => {
            console.log('All completed')
        })
        this.loadingData = false
    },

    async setInitialData(id, params) {
        const response = await api.init(params)
        let item = this.items.find(i => i.id === id)
        item['initial_data'] = response
        item['original_price'] = this.getPrice(item)
        return true
    },

    selectItem(item) {
        console.log('select item.... targer', item.id)

        let itm = this.items.find(i => i.id === item.id)
        if(!itm) return console.log('Item not found...')

        this.items.forEach(x => {
            x.selected = false
        })

        itm.selected = true
        let i = this.items.find(i => i.id === item.id)
        i.selected = true
    },

    get selectedItem(){
        let item = this.items.find(x => x.selected)
        if(!item) return null
        let propItems = (item.initial_data.current_step.settings?.data && item.initial_data.current_step.name !== 'Option')? item.initial_data.current_step.settings : item.initial_data
        item['propItems'] = propItems
        return item
    },

    markSameSettings() {

    },

    // setTargetSelectionFromItemWizard(selection, step, optionCode) {

    // }

    async setTargetSelection(selection, step, optionCode='') {
        console.log('Set current slelction.....')
        this.targetSelection = selection
        this.targetStep = step
        this.targetOptionCode = optionCode
        this.selectedItem['new_selection'] = _.cloneDeep(selection)
        this.checkSelectionAvailability()

        const activeItems = this.items.filter(i => i.initial_data.current_step.id === this.currentStepId &&
          (i.initial_data.current_step.option_code || '') === this.currentOptionCode)

        const hasIssue = activeItems.some(x => !x.new_selection)
        console.log('HAS issue:::', hasIssue)
        if(hasIssue) return


        this.loadingData = true
        await this.resolveItems()
        this.activeScreen = 'itemWizard'
        this.loadingData = false


    },

    checkSelectionAvailability() {
        const activeItems = this.items.filter(i => i.initial_data.current_step.id === this.currentStepId && (i.initial_data.current_step.option_code || '') === this.currentOptionCode)
        console.log('Active items length', activeItems.length)
        activeItems.forEach(item => {
            //Option
            const tmpTarget = item.initial_data.current_step.option_code ? this.targetSelection?.sections[0].options[this.currentOptionCode] ?? this.targetSelection?.sections[1].options[this.currentOptionCode]: false
            if(item.initial_data.current_step.option_code && tmpTarget) {

                let targetOption = tmpTarget.option_settings.groups.default.choices.find(x => x.default)
                const sizeOption = item.initial_data.steps.find(x => x.name === 'Size')

                if(sizeOption?.data?.sections[0]?.options[this.targetOptionCode]?.option_settings?.groups?.default?.choices?.some(x => x.id === targetOption.id)) {
                // if(item.initial_data.current_step.settings.data?.groups.default.choices.some(x => x.id === targetOption.id)) {
                    item.new_selection = this.targetSelection
                }
                // console.log('Options....')

            }else {
                // console.log({item, target: this.targetSelection})
                const settingsData = item.initial_data.current_step?.settings?.data
                let isAvailable = _.isArray(settingsData) && settingsData.some(x => x.id === this.targetSelection.id)
                // console.log({isAvailable})
                if(this.bulkEditOption.name === 'frame_colour' || ['Frame Colour', 'Insert Colour'].includes(this.currentStepName) ) {
                    isAvailable = settingsData.some(x => {
                        return x.colours.some(c => c.id === this.targetSelection.id)
                    })
                }


                if(isAvailable && !item.new_selection) {
                    item['new_selection'] = this.targetSelection
                }


                if(item.new_selection) {
                    item['selectionAvailable'] = true
                }else {
                    item['selectionAvailable'] = false

                }
            }
        })


        // this.items.forEach(item => {

        //     let isAvailable = item.initial_data.current_step?.settings?.data?.some(x => x.id === this.targetSelection.id)
        //     if(this.bulkEditOption.name === 'frame_colour') {
        //         isAvailable = item.initial_data.current_step?.settings?.data?.some(x => {
        //             return x.colours.some(c => c.id === this.targetSelection.id)
        //         })
        //     }


        //     if(isAvailable && !item.new_selection) {
        //         item['new_selection'] = this.targetSelection
        //     }


        //     if(item.new_selection) {
        //         item['selectionAvailable'] = true
        //     }else {
        //         item['selectionAvailable'] = false

        //     }

        // })
    },

    selectNextUnresolvedItem() {
        let unresolvedItems = this.items.filter(item => !item.resolved)
        unresolvedItems = _.sortBy(unresolvedItems, [function(o) {
            return o.initial_data.current_step.id ;
        }, function(o) {
            return o.initial_data.current_step.option_code;
        }]);
        console.log('Unresolded...........', unresolvedItems)

        if(unresolvedItems.length) {
            this.setCurrentStepInfo(unresolvedItems[0].initial_data.current_step.id, unresolvedItems[0].initial_data.current_step.option_code, unresolvedItems[0].initial_data.current_step.name)

            this.items.forEach(i => i.selected = false)
            let itm = this.items.find(x => x.id === unresolvedItems[0].id)
            itm.selected = true
        }
    },

    async resolveItems() {
        this.loadingData = true
        let targetItems = this.items.filter(item => item.new_selection)
        let selection = this.targetSelection
        let apiCalls = []

        targetItems.forEach(item => {
            let p = api.nextStep({...item.initial_data, selection: item.new_selection, is_bulk_edit: true}).then(res => {
                // console.log('RES.....', res)
                item['initial_data'] = res
                item['resolved'] = res.current_step.name === 'All Options'
            })
            apiCalls.push(p)
        })

        await Promise.all(apiCalls).then(res => {
            console.log('All completed')
        })

        this.items.forEach(i => {
            i.new_selection = null
        })

        this.selectNextUnresolvedItem()
        // const data = await api.nextStep({...this.selectedItem.initial_data, selection})
        // let itm = this.items.find(x => x.id === this.selectedItem.id)
        // itm.initial_data = data


        this.loadingData = false
    }

})
