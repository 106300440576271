<template>
    <div class="estimate">
      <div v-if="isUnavailable" class="locked-wrapper">
        <div class="locked-content">
          This estimate request has been rejected by the dealer. Please bear with us while we are looking for another dealer.
          We will notify you as soon as we find another dealer that can help you.
        </div>
      </div>
        <Header
            v-if="header && !isHideHeader && buttons && buttons.length"
            :headerData="headerData"
            :data="header"
            :isEditable="isEditable"
            :quantityItems="quantityItems"
            :summary="summary"
            :buttons="buttons"
            :isQuoting="isQuoting"
            @jobModal="$emit('jobModal', $event)"
            @convertToJob="isSelectCustomer = true"
            @sendestimateBtnClick="quoteOrEstimateType = 'estimate'"
            @sendquoteBtnClick="quoteOrEstimateType = 'quote'"
            @addnewitemBtnClick="showWizard()"
            @acceptBtnClick="acceptEstimate(itemId)"
            @rejectBtnClick="rejectEstimate(itemId)"
            @cloneBtnClick="cloneEstimate(itemId)"
            @deleteBtnClick="showDeleteConfirmation = true"
            @specificationBtnClick="$router.push({ name: 'specification', params: { id: $route.params.id, type: area } })"
            @submitorderBtnClick="showSubmitOrderConfirmation = true"
        />

        <div class="estimate__content container" v-if="locations">
            <div class="tw-flex py-2 md:py-0 md:tw-gap-4 tw-flex-col md:tw-flex-row">
              <ContentPanel class="tw-flex-1"  v-if="itemId !== 'new'" :isEditable="isEditable" :quantity="quantityItems" :buttons="buttons" @addNewItem="showWizard()" @toggleLocationCollapse="locationsCollapsed = !locationsCollapsed"/>

              <div v-if="showBulkEditProductSelection && !loadingData" class="bulk-edit-product-type-selection tw-flex tw-gap-2 tw-items-center tw-justify-end tw-pr-2 sm:tw-pr-0">
                <span class="tw-font-bold">Bulk Edit:</span>
                <BulkEditProductTypeSelection :disabled="loadingData" :selected="selectedProductType" :items="availableProductTypes" @onSelected="handleProductTypeSelection" />
              </div>
            </div>
            <div v-if="itemId === 'new'" class="tw-flex tw-h-96 tw-items-center tw-justify-center tw-text-2xl">
              You have not added any items yet.
            </div>
            <draggable class="estimate__items" handle=".location__drag" v-model="locations" @sort="setSortLocations">
                <template #item="{element}">
                    <Location
                        :data="element"
                        @showWizard="showWizard"
                        @clone="showWizard"
                        @cloneMultipleTimes="cloneMultipleTimes"
                        :isEditable="isEditable"
                        @removedItem="getLocationItems(locations, $event.name, 'remove')"
                        @clickAddANewAccessoryBtn="showAddNewAccessoriesStep()"
                        :selectedProductTypeForBulkEdit="selectedProductType"
                        :locationCollapsed="locationsCollapsed"
                        :area="area"
                       />
                </template>
            </draggable>

          <div v-if="cloningProgress.items" style="z-index: 999;" class="cloning-loader tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black tw-z-20 tw-bg-opacity-70 tw-flex tw-flex-col tw-items-center tw-justify-center">
            <Loader class="estimate__loader main tw-text-white" customClass="big tw-text-white">
              <p class="loading-text tw-max-w-3xl tw-font-sans tw-text-xl md:tw-text-2xl tw-text-gray-200">Cloning in progress {{ cloningProgress.completed }}/{{ cloningProgress.items }}</p>
            </Loader>
            <!-- <p class="loading-text tw-max-w-3xl tw-font-sans tw-text-xl md:tw-text-2xl tw-text-gray-200">Cloning completed. Redirecting to a new estimate.</p> -->
          </div>

          <Location
            v-if="additionalAccessories"
            :data="additionalAccessories"
            :area="area"
            @showWizard="showWizard"
            @clone="showWizard"
            @cloneMultipleTimes="cloneMultipleTimes"
            :isEditable="isEditable"
            @removedItem="getLocationItems(locations, 'additional accessories', 'remove')"
            @clickAddANewAccessoryBtn="showAddNewAccessoriesStep()"
            :locationCollapsed="locationsCollapsed"
          />
        </div>
        <Loader class="estimate__loader" v-else-if="!$store.state.loader" />
        <div v-if="isCloning" class="cloning-loader tw-fixed tw-top-0 tw-w-full tw-h-full tw-bg-black tw-z-20 tw-bg-opacity-70 tw-flex tw-flex-col tw-items-center tw-justify-center">
          <Loader v-if="!afterCloneRedirecting" class="estimate__loader main tw-text-white" customClass="big tw-text-white">
            <p class="loading-text tw-max-w-3xl tw-font-sans tw-text-xl md:tw-text-2xl tw-text-gray-200">Please wait while we are cloning your estimate. We will redirect you to a new estimate when completed.</p>
          </Loader>
          <p v-if="afterCloneRedirecting" class="loading-text tw-max-w-3xl tw-font-sans tw-text-xl md:tw-text-2xl tw-text-gray-200">Cloning completed. Redirecting to a new estimate.</p>
        </div>

        <div class="estimate__summary" v-if="summary && !isHideFooter && !loadingData" :class="canSeePrice? '': 'tw-hidden'">
            <Summary
                :canSeePrice="canSeePrice"
                :key="key"
                class="container"
                :isEditable="isEditable"
                :quantityItems="quantityItems"
                :data="header"
                :summary="summary"
                :buttons="buttons"
                @sendestimateBtnClick="sendEstimateClick"
                @sendquoteBtnClick="quoteOrEstimateType = 'quote'"
                @additemBtnClick="showWizard()"
                @acceptBtnClick="acceptEstimate(itemId)"
                @rejectBtnClick="rejectEstimate(itemId)"
                @cloneBtnClick="cloneEstimate(itemId)"
                @deleteBtnClick="showDeleteConfirmation = true"
                @clickAddAccessoryBtn="showAddNewAccessoriesStep()"
                @specificationBtnClick="$router.push({ name: 'specification', params: { id: $route.params.id, type: area } })"
                @bulkeditBtnClick="onBulkeditBtnClick"
                @submitorderBtnClick="showSubmitOrderConfirmation = true"
                @bulkDeleteBtnClick="bulkDeleteBtnClick"
                @convertToJobBtnClick="isSelectCustomer = true"
                @placeOrderBtnClick="placeOrder"
                @cancelBtnClick="$emit('cancelBtnClick')"
            />
        </div>

        <!-- <Modal v-model="showSpecificationModal">
            <Specification :id="itemId" v-if="showSpecificationModal"/>
        </Modal> -->

        <Modal :modelValue="showWizardModal" @update:modelValue="closeWizard" class="wizard-modal">
            <Wizard
                ref="refWizard"
                :isModal="true"
                :isQuoting="isQuoting"
                :showAddNewAccessories="showAddNewAccessories"
                @showRegisterModal="showModal('register')"
                @closeModal="closeWizard"
                v-if="showWizardModal"
                :mode="mode"
                :area="area"
            />
        </Modal>

        <Modal class="bulk-edit-modal" :modelValue="showBulkEditModal" @update:modelValue="closeBulkEditModal">
            <BulkEdit v-if="showBulkEditModal" :area="area" :isQuoting="isQuoting" :mode="mode" :type="type" @closeWizard="showBulkEditModal = false" @bulkEditSuccess="handleBulkEditSuccess"></BulkEdit>
        </Modal>



        <Request
            :id="itemId"
            v-if="showRequestModal"
            @close="showRequestModal = false"
            @loadHeaderData="getHeaderData(true)"
        />

      <!-- <ConfirmationModal
        message="Are you sure you want to submit this order to production?"
        @confirmClicked="submitOrderToStarline"
        v-model:show="showSubmitOrderConfirmation"
      /> -->

      <SubmitOrderOrJobModal
        v-model:show="showSubmitOrderConfirmation"
        @orderSubmitted="submitOrderToStarline"
        :id="itemId"
        :area="area"
      />

      <ConfirmationModal
        message="If you leave now, your progress will be lost. Are you sure you want to leave?"
        :cancel_btn_data="{ type: 'primary', title: 'STAY ON THE PAGE', icon: 'far fa-arrow-turn-down-left' }"
        :confirm_btn_data="{ type: 'primary', title: 'DISCARD CHANGES', icon: 'far fa-ban' }"
        @confirmClicked="confirmWizardClose.continue"
        v-model:show="confirmWizardClose.prompting"
      />

      <ConfirmationModal
        message="Unsaved changes will be lost. Would you like to save?"
        :cancel_btn_data="{ type: 'primary', title: 'SAVE AND CLOSE', icon: 'far fa-save'  }"
        :confirm_btn_data="{ type: 'primary', title: 'DISCARD CHANGES', icon: 'far fa-ban' }"
        @cancelClicked="saveAndCloseTheWizard"
        v-model:show="confirmWizardSave.prompting"
        @confirmClicked="confirmWizardSave.continue()"
      />

      <ConfirmationModal
        message="Unsaved changes will be lost. Do you want to close the wizard?"
        :cancel_btn_data="{ type: 'primary', title: 'No' }"
        :confirm_btn_data="{ type: 'primary', title: 'Yes' }"
        @confirmClicked="confirmWizardCloseBulkEdit.continue"
        v-model:show="confirmWizardCloseBulkEdit.prompting"
      />

      <ConfirmationModal
        message="Are you sure you want to delete selected items?"
        :cancel_btn_data="{ type: 'primary', title: 'No' }"
        :confirm_btn_data="{ type: 'primary', title: 'Yes' }"
        @confirmClicked="confirmBulkDelete.continue"
        v-model:show="confirmBulkDelete.prompting"
      />

      <CloseConfirmationModal
          message="All unsaved changes will be lost. Do you want to close the wizard?"
          @confirmClicked="closeWizard"
          v-model:show="showSubmitWizardClosureConfirmation"
      />

      <template v-if="quoteOrEstimateType">
        <QuoteAndEstimateSendConfirmationModal
          :container_id="$route.params.id"
          :type="quoteOrEstimateType"
          v-model:show="quoteOrEstimateType"
          @onSend="$emit('refreshWrapper')"
        />
      </template>

      <Teleport to="body">
        <DeleteConfirmationModal v-model="showDeleteConfirmation" @deleteEstimate="deleteEstimate" message="This action will delete entire estimate.
  Click Continue if you want to proceed"/>
      </Teleport>

      <ConvertToJobsModal v-model:show="isSelectCustomer" :order-id="$route.params.id"/>

      <div v-if="convertingToJob" style="z-index: 99999;" class="cloning-loader tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black tw-z-20 tw-bg-opacity-70 tw-flex tw-flex-col tw-items-center tw-justify-center">
        <Loader class="estimate__loader main tw-text-white" customClass="big tw-text-white">
          <p class="loading-text tw-max-w-3xl tw-font-sans tw-text-xl md:tw-text-2xl tw-text-gray-200">Your Draft is being converted to a Job</p>
        </Loader>
      </div>

    </div>
</template>

<script>
import api from './services/item.service.js'
import apiWizard from './services/wizard.service'
import dealerOrderApi from './services/dealerOrder.service.js'
import Summary from './components/estimate/item/Summary'
import Location from './components/estimate/item/Location'
import Header from './components/estimate/item/Header'
import Button from './components/form/Button'
import draggable from 'vuedraggable'
import Loader from './components/elements/Loader.vue'
import Wizard from './wizard'
import Request from './components/estimate/request'
import Modal from './components/elements/Modal'
import  { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import  { ref, computed, onUnmounted, reactive, watch } from 'vue'
import { sortItems } from './helpers/objects'
import Buttons from './components/estimate/elements/Buttons.vue'
import ContentPanel from './components/estimate/item/ContentPanel.vue'
import Specification from './components/estimate/specification/index.vue'
import ConfirmationModal from "@/components/item-wizard/components/modals/ConfirmationModal.vue";
import CloseConfirmationModal from "@/components/item-wizard/components/modals/ConfirmationModal.vue";
import DeleteConfirmationModal from '@/components/item-wizard/components/modals/DeleteConfirmationModal'
import _, { now } from "lodash";
import BulkEdit from './components/estimate/bulk-edit/BulkEdit.vue'
import { bulkEditStore } from './components/estimate/bulk-edit/store'
import { pcChargeStore } from "./components/estimate/pc-charge/store";
import SelectDropdown from '@/components/item-wizard/components/elements/SelectDropdown.vue'
import BulkEditProductTypeSelection from './components/estimate/bulk-edit/BulkEditProductTypeSelection.vue'
import useConfirmAsync from './composables/useConfirmAsync'
import CustomerSelector from "@/components/customer/CustomerSelector";
import jobsApi from "@/services/jobs/job";
import QuoteAndEstimateSendConfirmationModal from "./components/modals/QuoteAndEstimateSendConfirmationModal.vue";
import SubmitOrderOrJobModal from './components/modals/SubmitOrderOrJobModal.vue'
import ConvertToJobsModal from './components/modals/ConvertToJobsModal.vue'
// import toast from "bootstrap/js/src/toast";

export default ({
    props: ['isQuoting', 'type', 'headerData', 'mode', 'area', 'isHideHeader', 'isHideFooter', 'parentSummary'],
    emits: ['jobModal'],
    components: {
    Summary,
    Location,
    Header,
    Button,
    Loader,
    draggable,
    Wizard,
    Modal,
    Request,
    Buttons,
    ContentPanel,
    Specification,
    ConfirmationModal,
    DeleteConfirmationModal,
    BulkEdit,
    SelectDropdown,
    BulkEditProductTypeSelection,
    CloseConfirmationModal,
    CustomerSelector,
    QuoteAndEstimateSendConfirmationModal,
    SubmitOrderOrJobModal,
    ConvertToJobsModal
},
    data() {
        return {
            drag: false,
        }
    },
    setup(props, {emit}) {
      const refWizard = ref(null)
      const saveAndCloseTheWizard = async () => {
        await refWizard.value.save('save')
        confirmWizardSave.continue()
      }
      const key = ref(0)
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const showSubmitOrderConfirmation = ref(false)
        const showDeleteConfirmation = ref(false)
        const showSubmitWizardClosureConfirmation = ref(false)
        const showBulkEditModal = ref(false)
        const header = ref(null)
        const cloningProgress = ref({items: 0, completed: 0})
        const convertingToJob = ref(false)

        const locations = ref(null)
        const additionalAccessories = ref (null)
        const summary = ref(null)
        const buttons = ref(null)
        const showWizardModal = ref(false)
        // const showSpecificationModal = ref(false)
        const showRequestModal = ref(false)
        const showAddNewAccessories = ref(false)
        const loadingData = ref(false)

        const quantityItems = computed(() => locations.value && locations.value.length)

        // const isEditable = computed(() => header.value?.state == 'Estimate' || header.value?.state == 'Order')
        const isEditable = computed(() => {
          if (props.isQuoting)
            return header.value?.state === 'Lead' && header.value?.is_editable

          return (header.value?.status === "Open" || header.value?.state === "Draft")
        })

      const isUnavailable = ref(false)

        const isCloning = ref(false)
        const afterCloneRedirecting = ref(false)
        let itemId = ref(route.params.id) //computed(() => route.params.id)
        // const props.area = props.area //route.params.type || localStorage.getItem('estimateType')
        // if(props.area) localStorage.setItem('estimateType', props.area)

        if (props.isQuoting)
          localStorage.setItem('prefer_entries', 'width')

        const confirmWizardClose = useConfirmAsync()
        const confirmWizardSave = useConfirmAsync()
        const confirmWizardCloseBulkEdit = useConfirmAsync()
        const showCloseWizardConfirmation = ref(false)

        const confirmBulkDelete = useConfirmAsync()

        const locationsCollapsed = ref(false)

        const isSelectCustomer = ref(false)

        const canSeePrice = computed(() => props.area === 'my-orders'? store.getters['auth/canSeeCost']: store.getters['auth/canSeePrice'])

        const handleProductTypeSelection = (itm) => {
          selectedProductType.value = {...itm, updated: now()}
        }

      const quoteOrEstimateType = ref(null)
      const showQuoteOrEstimateModal = computed(() => quoteOrEstimateType.value !== null)

        getData()

        onUnmounted(() => {
          bulkEditStore.exit()
          pcChargeStore.dispose()
        })

        const bulkDeleteBtnClick = async () => {
          const confirm = await confirmBulkDelete.confirm()
          if(!confirm) return

          const promises = []
          bulkEditStore.itemSelection.forEach(item => {
            let type = item.section_items.length > 1? 'layout': 'position'
            let id = type === 'layout'? item.section_info.id: item.section_items[0].id
            promises.push(api.removeItem(type, id, 'orders'))
          })

          await Promise.all(promises)
          getData()

        }

        const onBulkeditBtnClick = () => {
          bulkEditStore.fillItems(bulkEditStore.itemSelection)
          showBulkEditModal.value = true
        }

      const acceptEstimate = async (id) => {
        await api.acceptEstimate(id)
        getHeaderData(true)
      }

      const rejectEstimate = async (id) => {
        await api.rejectEstimate(id)
        getHeaderData(true)
      }

      const cloneEstimate = async (id) => {
          isCloning.value = true
          const response =  (await api.cloneEstimate(props.area, id))
          if(response.id){
            afterCloneRedirecting.value = true
            setTimeout(async () => {
              afterCloneRedirecting.value = false
              isCloning.value = false

              let area = props.area == 'my-jobs' ? 'job' : 'order-item'
              if (props.isQuoting)
                area = 'estimate-item'

              await router.push({name: area, params: {id: response.id}})
              itemId.value = response.id
              emit('refreshWrapper')
              await getData()
              await getHeaderData()
            }, 2000);
          }else {
            isCloning.value = true
          }

        }
        const deleteEstimate = async () => {
          store.commit('preloader/preloader', true);
          const area = props.area.includes('my-jobs')? 'my-jobs': props.area

          await api.deleteEstimate(props.area, itemId.value)

          store.commit('preloader/preloader', false);
          router.push({ path: '/' + area})
        }

        async function placeOrder(){
          await jobsApi.placeOrder(route.params.id)
          getHeaderData(true)

        }


        async function getHeaderData(loadActions = false) {

            if(loadActions) await loadRelatedActions()
            else header.value = await api.getData(itemId.value, props.area, 'header')
            if(showRequestModal.value) showRequestModal.value = false
          isUnavailable.value = header.value.is_unavailable ?? false
        }

        async function getData() {
            loadingData.value = true
            bulkEditStore.exit()
            if(props.isQuoting) {
                 store.commit('setField', {
                    field: 'loading', value: true,
                })
            }

            //req header data
            if(props.headerData) getHeaderData()

            await getActions()

            //req locations data

            const resultLocations = Object.fromEntries(sortItems(await api.getData(itemId.value, props.area, 'locations'), 'sort_order'))

            locations.value = Object.values(resultLocations)

            getSummary()

            await getLocationItems(resultLocations)
            loadingData.value = false
        }

      async function getActions (){
        //req buttons data
        buttons.value = await api.getData(itemId.value, props.area, 'actions')
        buttons.value =  buttons.value.filter(button => button.available_on =='page' || button.available_on =='both')
        if(props.isQuoting) {
          store.commit('setField', {
            field: 'loading', value: false,
          })
          key.value ++
        }
      }

        async function getSummary() {
            //req summary data
            summary.value = await api.getData(itemId.value, props.area, 'summary')
          pcChargeStore.updateOrderFees(summary.value.pc_charges?.fees)

          console.log('summary', summary.value)
          emit('setSummary', summary.value)
        }

        async function updLocationPrice(item, locationName,updatedLocationList = null) {
            let updLocations
            if(updatedLocationList)
              updLocations  = updatedLocationList
            else
              updLocations = await api.getData(itemId.value, props.area, 'locations')

            locations.value.forEach(l => {
              const updLocation = _.filter(updLocations, i => i.name == l.name)[0]
              if (updLocation)
                l.amount = updLocation.amount
            })

            const currentLocation = locations[locationName]
            if(currentLocation) item.amount = currentLocation.amount
    }

        async function getLocationItems(resultLocations, locationName, typeAction) {
            if(locationName && locationName) {
                bulkEditStore.exit()
                locations.value = resultLocations
                const item = resultLocations.find(el => el.name == locationName)

              if (item.items.length !== 0) {
                item.items = []
                if (typeAction == 'remove') {
                  updLocationPrice(item, locationName)
                }
                const items = await api.getLocationItems(itemId.value, props.area, locationName)
                item.items = filterOverallSectionItems(items)

                if (!item.items.length) removeLocation(locationName)
              } else {
                removeLocation(locationName)
              }
            }
            else {
                for(const key in resultLocations) {
                    const item = locations.value.find(el => el.name == resultLocations[key].name)
                  if (item) {
                    const items = await api.getLocationItems(itemId.value, props.area, item.name)
                    item.items = filterOverallSectionItems(items)
                  }
                }
            }
            // getSummary()
          loadRelatedActions()

        }

        function removeLocation(locationName) {
            const index = locations.value.findIndex(el => el.name == locationName)
            locations.value.splice(index, 1)
        }

        function filterOverallSectionItems(items) {

            if(!items) return
            const sections = []
            items.forEach((el, index) => {
                const positionLayoutId = el.position_layout && el.position_layout.id || index
                const includesSection = sections.length && sections.find(el => el.section_info && el.section_info.id == positionLayoutId)
                if(!includesSection) {
                    const arrayOverallPositionLayout = items.filter(el =>  el.position_layout && el.position_layout.id == positionLayoutId)
                    const section_items = arrayOverallPositionLayout.length && arrayOverallPositionLayout || [el]
                    sections.push({
                        section_info: el.position_layout,
                        section_items,
                    })
                }
            })
            return sections
        }

        function preCloseWizard(){
          showSubmitWizardClosureConfirmation.value = true
        }


        async function closeWizard(data) {
          if(!data) {
            const confirm = await (refWizard.value.currentStep.title === 'All Options'? confirmWizardSave.confirm(): confirmWizardClose.confirm())
            if(!confirm) return
          }

            showAddNewAccessories.value = false
            let clonedLocations = null
            let updatedLocationList = null
            showWizardModal.value = false
            const estimateNeedLoadData = sessionStorage.getItem('estimateNeedLoadData')

            const doesItHasClonedLocations =  sessionStorage.getItem('clonedLocations')
            if(doesItHasClonedLocations) clonedLocations = sessionStorage.getItem('clonedLocations').split(',')

            const wizardParams = sessionStorage.getItem('wizardParams') && JSON.parse(sessionStorage.getItem('wizardParams'))

            if(clonedLocations) {
              const resultLocations = Object.fromEntries(sortItems(await api.getData(itemId.value, props.area, 'locations'), 'sort_order'))

              Object.values(resultLocations).forEach((location) => {
                  //Push new locations
                  if(!_.find(locations.value,{name:location.name})) locations.value.push(location)
              })

              updatedLocationList = locations.value
              for (const clonedLocationName of clonedLocations) {
                //Check is cloned location available at locations
                const clonedLocationFromExistingData = locations.value.find(el => el.name == clonedLocationName)

                if(clonedLocationFromExistingData) {
                  clonedLocationFromExistingData.items = []
                  const previousLocationItems = await api.getLocationItems(itemId.value, props.area, clonedLocationFromExistingData.name)
                  if(!previousLocationItems.length) removeLocation(clonedLocationFromExistingData.name)
                  else {
                    clonedLocationFromExistingData.items = filterOverallSectionItems(previousLocationItems)
                    await updLocationPrice(clonedLocationFromExistingData, clonedLocationFromExistingData.name,locations.value)
                  }
                }

                getLocationItems(updatedLocationList, clonedLocationName, 'remove')

              }
            }

            if(estimateNeedLoadData && !clonedLocations) {
                sessionStorage.removeItem('estimateNeedLoadData')
                getData()
                return
            }

            if(data) {
              const location = data.steps.find(el => el.name == 'Location')?.data.toLowerCase().trim() ?? "additional accessories"
              if(location !== wizardParams.location ) {
                  const previousLocation = locations.value.find(el => el.name == wizardParams.location)
                  if(previousLocation) {
                    if(!clonedLocations || clonedLocations && !clonedLocations.includes(previousLocation.name)) {
                      previousLocation.items = []
                      const previousLocationItems = await api.getLocationItems(itemId.value, props.area, wizardParams.location)

                      if (!previousLocationItems.length) removeLocation(wizardParams.location)
                      else {
                        previousLocation.items = filterOverallSectionItems(previousLocationItems)
                        await updLocationPrice(previousLocation, wizardParams.location, updatedLocationList)

                      }
                    }
                  }
              }

              let matchLocation = locations.value.find(el => el.name == location)

                if(matchLocation) {
                  if(!clonedLocations || clonedLocations && !clonedLocations.includes(matchLocation.name)) {
                    matchLocation.items = []
                    await updLocationPrice(matchLocation, location,updatedLocationList)
                    const locationItems = await api.getLocationItems(itemId.value, props.area, location)
                    matchLocation.items = filterOverallSectionItems(locationItems)
                  }

                } else {

                  //get all locations
                  const allLocations = Object.fromEntries(sortItems(updatedLocationList ?? await api.getData(itemId.value, props.area, 'locations'), 'sort_order'))
                  const locationsArray = _.sortBy(Object.values(allLocations), 'sort_order')

                  const newlyAddedLocation = locationsArray.find(x => x.name === location)

                  const locationItems = await api.getLocationItems(itemId.value, props.area, location)

                  newlyAddedLocation.items = filterOverallSectionItems(locationItems)

                  const additionalAcc = locations.value.find(x => x.name === "additional accessories")
                  const otherLocations = locations.value.filter(x => x.name !== "additional accessories")
                  locations.value = [...otherLocations, ...(newlyAddedLocation? [newlyAddedLocation]: []), ...(additionalAcc? [additionalAcc]: [])]

                }

            }

            // getSummary()
            loadRelatedActions()

            sessionStorage.removeItem('wizardParams')
            sessionStorage.removeItem('clonedLocations')
            sessionStorage.removeItem('estimateNeedLoadData')
        }

        async function cloneMultipleTimes({sectionType, sectionId, times = 1, location}) {
          const repeat = times > 100 ? 99: times < 0? 1: times

          cloningProgress.value.items = times
          cloningProgress.value.completed = 0

          const apiCalls = []
          for(let i = 0; i < repeat; i++) {
            apiCalls.push(apiWizard.cloneItem(props.area, sectionType, sectionId).then(() => cloningProgress.value.completed = cloningProgress.value.completed +1))
          }

          const res = await Promise.all(apiCalls)
          getLocationItems(locations.value, location, 'remove')
          cloningProgress.value.items = 0
          cloningProgress.value.completed = 0
          getSummary()
        }

        async function showWizard(event) {
            if(route.params.id === 'new') {
              return showWizardModal.value = true
            }

            const params = {
                ...event,
            }

            //if user is trying to edit an order accessory then initialize wizard with that option
            if(params.is_additional_accessory){
              await showAddNewAccessoriesStep(params.position_id)
              return
            }
            if(props.type == 'dealer') params.dealer_order_id = route.params.id
            else params.customer_order_id = route.params.id

            sessionStorage.setItem('wizardParams', JSON.stringify(params))
            showWizardModal.value = !showWizardModal.value
        }

        async function showAddNewAccessoriesStep(position_id = null){
          let data = {
            location : 'additional accessories',
          }
          if(position_id){
            data.position_id = position_id
          }

          sessionStorage.setItem('wizardParams', JSON.stringify(data))
          showAddNewAccessories.value = true
          showWizardModal.value = true
        }


        function setSortLocations() {
            const newSort = Array.from(locations.value, el => el.name)
            api.setSortLocations(itemId.value, props.area, newSort)
        }

        async function submitOrderToStarline() {
          // await dealerOrderApi.submitDealerOrderToStarline(props.area, route.params.id)
          await loadRelatedActions()
          showSubmitOrderConfirmation.value = false
        }

        async function convertToJob(customer){
          convertingToJob.value = true
          // if (route.params.id === 'new'){
          //   router.push('/my-jobs/new')
          // }
          let result = await dealerOrderApi.convertToJob(route.params.id, {customer_id: customer.id})
          convertingToJob.value = false
          console.log(result)
          if (result.success){
            router.push({name: 'job', params: {id: result.job_id}})
          }
        }

        async function loadRelatedActions(){
        await getHeaderData()
        await getSummary()
        await getActions()
      }

      const buttonsWithBulkEdit = computed(() => {
        const btn = [{
          available_on: "both",
          icon: "fas fa-chevron-down",
          name: "Bulk Edit",
          title: "Bulk Actions",
          type: "secondary",
          buttons: [
            {
              available_on: "both",
              name: "Bulk Edit",
              type: "additional",
              event: 'bulkeditBtnClick'
            },
            {
              available_on: "both",
              name: "Bulk Delete",
              type: "additional",
              event: 'bulkDeleteBtnClick'
            }
          ]
        }]

        let buttonsArray = buttons.value
        if(bulkEditStore.itemSelection.length > 1) return  buttonsArray.concat(btn)
        else return buttonsArray
      })

      const handleBulkEditSuccess = () => {
        showBulkEditModal.value = false
        bulkEditStore.itemSelection = []
        getData()
      }

      const selectedProductType = ref(null)

      const availableProductTypes = computed(() => {
        let result = []
        result.push({
          id: -1,
          name: 'Select all'
        })

        result.push({
          id: -2,
          name: 'Deselect all'
        })

        locations.value.filter(loc => !['additional accessories'].includes(loc.name)).forEach(location => {
          location.items.forEach(locationItem => {
            result.push(locationItem.section_items[0].item.product_type)
          })
        })
        return _.uniqBy(result, 'id')
      })

      const showBulkEditProductSelection = computed(() => {
        if (!isEditable.value || props.isQuoting)
          return false

        const locationsForBE = locations.value.filter(loc => loc.name !== 'additional accessories')

        if(locationsForBE.length > 1) return true

        const items = _.get(locationsForBE, '[0].items.length', 0)
        return items > 1

      })

      const sendEstimateClick = () => {
          if (props.isQuoting)
            showRequestModal.value = true
          else
            quoteOrEstimateType.value = 'estimate'

      }

      const closeBulkEditModal = async () => {
        const result = await confirmWizardCloseBulkEdit.confirm()
        if(result) showBulkEditModal.value = false
      }

        return {
          loadingData,
          canSeePrice,
          confirmWizardSave,
          confirmWizardCloseBulkEdit,
          refWizard,
          saveAndCloseTheWizard,
          cloneEstimate,
          sendEstimateClick,
          deleteEstimate,
            itemId,
            locations,
            summary,
            buttons: buttonsWithBulkEdit,
            header,
            showWizard,
            showWizardModal,
            closeWizard,
            showRequestModal,
            setSortLocations,
            getHeaderData,
            getLocationItems,
            quantityItems,
            isEditable,
            // removeItem
            // showSpecificationModal
            showSubmitOrderConfirmation,
            submitOrderToStarline,
            showDeleteConfirmation,
            showBulkEditModal,
            onBulkeditBtnClick,
            isCloning,
          isUnavailable,
            afterCloneRedirecting,
          key,
            showAddNewAccessories,
          quoteOrEstimateType,
          showQuoteOrEstimateModal,
          showAddNewAccessoriesStep,
          additionalAccessories,
          handleBulkEditSuccess,
          availableProductTypes,
          selectedProductType,
          showBulkEditProductSelection,
          showSubmitWizardClosureConfirmation,
          preCloseWizard,
          showCloseWizardConfirmation,
          confirmWizardClose,
          closeBulkEditModal,
          bulkDeleteBtnClick,
          bulkEditStore,
          confirmBulkDelete,
          acceptEstimate,
          rejectEstimate,
          locationsCollapsed,
          cloneMultipleTimes,
          cloningProgress,
          isSelectCustomer,
          convertToJob,
          placeOrder,
          convertingToJob,
          handleProductTypeSelection,

        }

    },
})
</script>

<style scoped lang="scss">
.estimate {
    position: relative;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__summary {
        padding: 50px 0;
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;
        @media(max-width: 768px) {
            padding: 10px ;
        }
    }
    &__summary {
        width: 100%;
    }
    &__loader {
        margin-top: 60px;
        display: block;
        ::v-deep svg {
            font-size: 50px;
        }
    }
    &__items {
        margin: 30px auto;
      @media(max-width:768px) {
        margin: 0 auto;
      }
    }
    &__content {
        margin-top: 30px;
        min-height: calc(100vh - 234px - 335px - 100px);
        @media(max-width: 768px) {
            margin-top: 10px;
            min-height: auto;
        }
    }
}
.cloning-loader .loading-text {
  font-family: Sansation;
}
::v-deep .modal-wrapper.show{
  min-width: 100vw !important;
}
::v-deep .wizard__item {
    height: 100%;
}
::v-deep .cloning-loader svg {
  color: white;
}
::v-deep .wizard-modal>.modal {
    width: calc(100% - 60px);
    max-width: 1140px;
    height: calc(100% - 60px);
    max-height: 700px;
    padding: 0 !important;
    @media(max-width: 768px) {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        max-height: none;
    }
}
::v-deep .estimate {
    padding: 0;
    width: 100%;
}
::v-deep .estimate__item {
    height: 100%;
    padding: 15px 17px;
}
::v-deep  .help_topic_modal {
    height: 100%;
    top: 0;
    min-width: 100%;
}
.header__wrapper.container,.container, .container_content{
  height: unset !important;
}
::v-deep .header__wrapper.container{
padding : 0 !important;
}

::v-deep .estimate__content.container{
  padding : 0 !important;
}

.bulk-edit-product-type-selection {
  z-index: 11;
}

::v-deep .bulk-edit-modal .modal {
  max-width: unset;
}

.locked-wrapper{
  z-index: 999999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.95);
  .locked-content{
    margin: 100px auto;
    width: 50%;
    min-width: 300px;
    max-width: 95%;
    position: relative;
    text-align: center;
    background: rgba(255, 255, 255, 0.95);
    padding: 35px;
  }
}
</style>
