<template>
  <div class="content__job-customer_details">
    <h3 class="content__heading">Pricing</h3>

    <div class="tw-grid-2">
      <div>
        <div>Pricelist:
        <span v-if="priceList?.status">
          ({{priceList.status}})
        </span>
        </div>
        <SelectDropdown
          :list="priceLists"
          :list-is-object="true"
          type="id"
          :small="true"
          :selected="priceList"
          class="dropdown_price"
          @select="changePricelist($event)"
        >
        </SelectDropdown>
      </div>

    </div>
      <div class="tw-flex tw-justify-between">
        <div>
          <div>Default Margin:</div>
          <Input style="width:100px; margin-bottom: 15px"
                 class="font-weight-bold"
                 small
                 :value="defaultMargin"
                 @input="value => changeDefaultMargin(value)"
          />
        </div>
      <div>
        <button class="btn btn_default" type="button" v-if="priceList?.status === 'Modified'"
                @click="changePricelist(priceList)">
          <svg-icon name="solid/ban"></svg-icon>
          Reset</button>
        <button class="btn btn_default" type="button" v-if="priceList?.status === 'Expired'">
          <svg-icon name="solid/ban"></svg-icon>
          Reset</button>
      </div>

    </div>
    <div class="content__line"></div>
    <div class="product_margins" v-if="productMargins && productMargins.length > 0">
    <div style="margin-bottom: 10px">Product margins:</div>
      <div v-for="(margin, k) in productMargins" :key="`margin${k}`" class="pricing__input">
        <div class="text-left">{{margin.item.name}}
        <span :title="`Modified from original ${margin.original_margin}%`" v-if="+margin.margin !== +margin.original_margin">*</span>
        </div>
        <div><Input
          class="font-weight-bold"
          type="number"
          small
          :value="margin?.margin"
          @input="value => margin.margin = value"
        /></div>

<!--        <div class="pricing__input__cancel">-->
<!--  &lt;!&ndash;        <svg-icon name="solid/times"></svg-icon>&ndash;&gt;-->
<!--        </div>-->
      </div>
    </div>
    <div class="content__line"></div>
    <div>
      <div>Installation Fee</div>
      <Input class="font-weight-bold"
             type="number"
             :min="0"
             :value="installationFee"
             @input="value => installationFee = value"
      />
    </div>
    <div class="pricing__discount">

      <div>
        <div>Type of discount:</div>
        <SelectDropdown
          :list="discounts"
          :selected="discountType"
          @select="discountType = $event"
        >
        </SelectDropdown>
      </div>
      <div v-if="discountType == 'Price'">
        <div>Final price:</div>
        <Input class="font-weight-bold"
               :value="discountValue"
               @input="value => discountValue = value"
        />
      </div>
      <div v-else-if="discountType == 'Amount'">
        <div>Discount, AUD:</div>
        <Input class="font-weight-bold"
               type="number"
               :value="discountValue"
               @input="value => discountValue = value"
        />
      </div>
      <div v-else-if="discountType == 'Percent'">
        <div>Discount, %:</div>
        <Input class="font-weight-bold"
               type="number"
               :min="0"
               :max="100"
               :value="discountValue"
               @input="value => discountValue = value"
        />
      </div>
    </div>
    <div class="content__line"></div>

    <div class="content__save" :class="activeMenuItem == 2 && 'content__save--products'">
      <div>
        <ButtonMain @click="saveChanges">
          <svg-icon name="solid/check"/>
          Recalculate prices
        </ButtonMain>
      </div>
    </div>

  </div>
</template>

<script>
import { reactive, ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import Input from "@/components/inputs/Input";
import Checkbox from "@/components/inputs/Checkbox";
import SelectDropdown from "@/components/dropdown/SelectDropdown";
import {useRoute, useRouter} from "vue-router";
import {dateForRequest, timeForRequest} from "@/helpers/datetime";
import ButtonMain from "@/components/buttons/ButtonMain";
import dealersApi from "@/services/dealers.service"
import lookupsApi from "@/services/lookups.service"
import jobsApi from "@/services/jobs/job"
import _ from "lodash";

export default ({
  components: {
    SelectDropdown,
    Input,
    Checkbox,
    ButtonMain
  },
  props: ['job'],
  setup(props, {emit}) {

    const priceLists = ref([])
    const discounts = ref([])
    const productMargins = ref(null)

    const priceList = ref(null)
    const defaultMargin = ref(null)
    const discountType = ref(null)
    const discountValue = ref(null)
    const installationFee = ref(null)

    const store = useStore()

    watch(priceList, () => {
      combineMargins()
    })

    watch(productMargins, () => {
      combineMargins()
    }, {deep: true})

    watch(defaultMargin, () => {
      combineMargins()
    })
    function combineMargins(){
      if (!priceList.value || !productMargins.value){
        return
      }
      let plState = null

      if (+defaultMargin.value !== +priceList.value.margin){
        plState = 'Modified'
      }
      _.forEach(productMargins.value, m => {

        const plMargin = priceList.value?.product_margins.find(x => {
          return x.product_type_id === m.item.type &&
            x.product_style_id === m.item.style_id &&
            x.product_grade_id === m.item.grade_id
        })
        m.original_margin = (+plMargin?.margin || +priceList.value?.margin || 0)
        if (m.original_margin !== +m.margin)
          plState = 'Modified'

      })
      if (priceList.value.state === 'deleted')
        plState = 'Removed'
      priceList.value.status = plState
    }

    function changeDefaultMargin(val){
      const old = defaultMargin.value
      defaultMargin.value = val
      _.forEach(productMargins.value ?? [], i => {
        if (+i.margin === +old){
          i.margin = val
        }
      })

    }

    async function loadPriceLists(){
      const result = await jobsApi.getPriceLists(props.job.container_id)
      priceLists.value = result.data
    }

    async function loadDiscounts(){
      const result = await lookupsApi.getDiscountTypes()
      discounts.value = result
    }

    async function loadMargins(){
      const result = await jobsApi.getMargins(props.job.container_id)
      productMargins.value = result
    }

    async function initialise(){
      store.commit('preloader/preloader', true);
      loadDiscounts()
      await loadPriceLists()
      await loadMargins()

      priceList.value = priceLists.value.find(x => x.id === props.job.pricelist.id)
      defaultMargin.value = props.job.container.default_margin
      discountType.value = props.job.discount_type
      discountValue.value = props.job.discount_value
      installationFee.value = props.job.installation_fee

//      changePricelist(priceList.value)
      store.commit('preloader/preloader', false);
    }

    initialise()

    function changePricelist(pricelist){
      priceList.value = pricelist
      defaultMargin.value = pricelist.margin
      if (pricelist.product_margins){
        _.forEach(productMargins.value, (m) => {
          let mm = pricelist.product_margins.find(x => {
            return x.product_type_id == m.item.type &&
              x.product_style_id == m.item.style_id &&
              x.product_grade_id == m.item.grade_id
          })
          if (mm){
            m.margin = +mm.margin
          }else{
            m.margin = +pricelist.margin
          }
        })
      }
    }

    let saveChanges = async () => {
      let parameters = {
        "pricelist_id": priceList.value.id,
        "discount_type": discountType.value,
        "discount_value": discountValue.value,
        "installation_fee" : installationFee.value,
        "default_margin": defaultMargin.value,
        "product_margin_list": productMargins.value.map(function(margin) {
          return {id: margin.id, margin: margin.margin}
        })
      }
      const result = await jobsApi.setMargins(props.job.container_id, parameters)
      if (result.success){
        emit('updatePricing')
      }
    }
    return {
      priceLists,
      discounts,

      defaultMargin,
      priceList,
      discountValue,
      discountType,
      productMargins,
      installationFee,
      saveChanges,
      changeDefaultMargin,
      changePricelist

    }
  },
})
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

.select_dropdown {
  z-index: auto;
}

.content {
  &__job-customer_details {
    height: calc(100% - 80px);
    padding: 30px 20px 30px 20px;
    width: 100%;
    max-width: 780px;
    font-size: 13px;
    color: rgba(28, 40, 51, 0.7);
    overflow-y: scroll!important;
    position: relative;

    .details_input {
      width: 350px;
      padding-bottom: 15px;
    }
    .pricing {
      &__input {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        input:last-of-type {
          width: 80px;
          flex-shrink: 0;
          margin: 0 15px 0 10px;
          padding: 5px;
        }
        &__cancel {
          width: 13px;
          font-size: 20px;
          color: rgba(28, 40, 51, 0.8);
          cursor: pointer;
        }
      }
      &__discount {
        display: flex;
        align-items: flex-end;
        padding-top: 15px;
        gap: 15px;

        width: 300px;
        max-width: 100%;
        padding-bottom: 15px;
      }
    }
  }
}
::v-deep .simple_modal__container {
  padding: 0;
  width: 500px;
}
.product_margins{
  display: flex;
  width: 350px;
  max-width: 100%;
  flex-direction: column;

  .pricing__input{
    display: flex;
    width: 100%;
    justify-content: right;

    input{
      width: 120px;
    }
  }
}


.schedule_modal {
  &__calendar {
    display: flex;
    align-items: center;
    color: #59B692;
    margin-left: 10px;
    font-size: 24px;
    cursor: pointer;
    div {
      margin-left: 4px;
      font-size: 12px;
      color: #1C2833;
    }
  }
  &__time {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 15px;
    > a {
      font-size: 15px;
    }
    span {
      margin-right: 3px;
    }
  }
  &__body {
    width: 420px;
    ::v-deep .vuecal {
      &__cell {
        &--selected {
          background: rgba(11, 53, 83, 0.2);
        }
        &:hover {
          cursor: pointer;
          background: rgba(11, 53, 83, 0.1);
        }
        &--has-events {
          background: rgba(89, 182, 146, 0.2);
          &:hover {
            background: rgba(89, 182, 146, 0.4)
          }
          &.vuecal__cell--selected {
            background: rgba(89, 182, 146, 0.4)
          }
        }
      }
    }
  }
}
</style>
