<template>
    <div class="actions" >
        <template v-for="item in buttons"  :key="item.name" >
            <Button
                class="actions__item"
                :class="[`btn_${item.className}`]"
                :name="[`btn_${item.className}`]"
                :data="item"
                v-if="item.title && !item.hide"
                @click="$emit(`${item.event}`)"
                @bulkeditBtnClick="$emit('bulkeditBtnClick')"
                @bulkDeleteBtnClick="$emit('bulkDeleteBtnClick')"
            />
        </template>
        <div class="top__actions">
          <div class="top__actions-container" @click="showDropdown = !showDropdown">
            <svg-icon-qouting data="fas fa-ellipsis-h" />
          </div>
          <div class="actions__dropdown" v-show="showDropdown" ref="actionDropdown">
            <Button
                 :class="[`btn_${item.className}`]"
                 v-for="item in buttons"
                  :data="item"
                 :key="item.event"
                 @click="$emit(`${item.event}`), showDropdown = !showDropdown"
            >
            </Button>
          </div>
        </div>
    </div>
</template>

<script>
import Button from '../../form/Button.vue'
import { ref} from 'vue'
export default ({
    props: ['data'],
    components: {
        Button
    },
    watch: {
      showDropdown() {
        const el = this.$refs.actionDropdown
        if(!el) return

        this.$nextTick(function() {
          const {x, width, left, right} = el.getBoundingClientRect()

          if(x > width) {
            el.style.left = "auto"
            el.style.right = 0
          }else {
            el.style.left = 0
            el.style.right = "auto"
          }
        })
      }
    },
    setup(props) {
      const buttons = getData(props.data)
      const showDropdown = ref(false)
        function getData(data) {
            const array = Array.isArray(data) && data || [data]

            return array.map((el) => {
                return {
                    title: el.name || el.title,
                    className: el.name && el.name.split(' ').join('-').toLowerCase(),
                    ...el
                }
            })
        }

        return {
            buttons,
          showDropdown,
        }
    },
})
</script>


<style scoped lang="scss">
.actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__item {
        margin-right: 15px;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
::v-deep .btn {
    //.btn {
        &_main {
            width: 270px;
            height: 40px !important;
          background: var(--PrimaryButtonColour);
          color: var(--PrimaryButtonTextColour);
          white-space: nowrap;

        }
      &_bulk-edit {
        background: #F1C40F!important;
        padding-top: 5px!important;
        padding-bottom: 5px!important;
        color: #000;
      }
        &_primary {
            min-width: 161px;
            height: 30px;
            text-transform:capitalize;
        }
        &_secondary {
          border: 2px solid var(--PrimaryColour);
        }
      &_additional {
        background: none;
        text-decoration: underline;
        color: var(--PrimaryColour);
      }
    //}
}
.top {
  &__actions {
    width: 50px;
    height: 36px;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #000;
    font-size: 22px;
    font-weight: normal;
    margin-left: 4px;
    border: 1px solid black;
    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.actions {
  &__dropdown {
    position: absolute;
    // right: 0;
    right: auto;
    left: 0;
    top: 36px;
    min-width: 140px;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.05), rgba(11, 53, 83, 0.05)), #FFFFFF;
    border: 1px solid rgba(11, 53, 83, 0.2);
    box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.1);
    z-index: 10;

    @media (min-width: 640px) {
        right: 0;
        left: auto;
    }

    > * {
      width: 100%;
    }
    //.btn {
    //  border: none;
    //  background: transparent;
    //  color: var(--PrimaryColour);
    //  text-decoration: none;
    //  padding: 10px;
    //}
  }

}
</style>
