<template>
    <PageHeader :alwaysShow="true" :offset="20" elementClass="dealer-ui-order-preview">
        <div class="container tw-px-2 md:tw-px-0">
            <div class="md:tw-flex tw-items-center tw-py-2">
                <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-w-full tw-items-center">
                    <div class="tw-flex">
                        <router-link :to="data.status === 'Draft' && data.state === 'Job' ? '/my-jobs' : headerData.backLink.url" class="link__back">
                            <svg-icon-qouting class="estimate__btn_back" data="fas fa-arrow-left" />
                        </router-link>

                        <div class="header__title tw-ml-2">
                            <span>{{ qpStateName }} {{ data.container_id }}</span>
                        </div>
                    </div>

                    <div class="tw-row-start-2 md:tw-row-start-1 md:tw-col-start-2 md:tw-flex tw-flex-col tw-gap-2 md:tw-flex-row tw-items-baseline" :class="[showMenu? 'tw-flex': 'tw-hidden']">
                        <div class="header__date text_right tw-text-right mr-20md">
                            <div class="">
                                <svg-icon-qouting class="date__icon" data="fas fa-clock" />
                                <span>{{ formatDate(data.date, false) }}</span>
                            </div>
                        </div>
                        <div class="text_right tw-text-right mr-20md" v-if="isQuoting && data.requested_on">
                          <div class="">
                            <svg-icon-qouting class="date__icon" data="fas fa-clock" />
                            <span>Requested on: {{ formatDate(data.requested_on, false) }}</span>
                          </div>
                        </div>

                        <div class="tw-flex tw-gap-2 tw-flex-col md:tw-flex-row" v-if="!isQuoting">
                            <div class="header__status tw-flex align-items-center">
                                <span class="status__label">
                                    Status:
                                </span>
                                <span class="status__value status"> {{ stateName }}</span>
                            </div>
                          <div class="tw-flex"
                               v-if="!isQuoting && data.status === 'Open' && headerData.container.latest_quote_or_estimate && headerData.container.latest_quote_or_estimate.length > 0"
                               :title="headerData.container.latest_quote_or_estimate[0].type + ' sent on ' + formatter.formatDate(headerData.container.latest_quote_or_estimate[0].sent_on) +
           '. Status: ' + headerData.container.latest_quote_or_estimate[0].status "
                          >
                            <div class="tw-pr-2">
                              <template v-if="headerData.container.latest_quote_or_estimate[0].type === 'Quote'">
                                <svg-icon name="solid/tape" v-if="headerData.container.latest_quote_or_estimate[0].status === 'Sent'"/>
                              </template>
                              <template v-else-if="headerData.container.latest_quote_or_estimate[0].type === 'Estimate'">
                                <svg-icon name="solid/clipboard-list" v-if="headerData.container.latest_quote_or_estimate[0].status === 'Sent'"/>
                              </template>
                              <svg-icon name="solid/eye" v-if="headerData.container.latest_quote_or_estimate[0].status === 'Opened'"/>
                              <svg-icon name="solid/handshake" v-if="headerData.container.latest_quote_or_estimate[0].status === 'Approved'"/>
                              <svg-icon name="solid/thumbs-down" v-if="headerData.container.latest_quote_or_estimate[0].status === 'Rejected'"/>
                            </div>
                            <span class="status__label" v-if="!isQuoting">
                              {{ headerData.container.latest_quote_or_estimate[0].type }} {{ headerData.container.latest_quote_or_estimate[0].status }}
                            </span>
                          </div>
                          <div class="tw-flex" v-else-if="data.status === 'Open' && !isQuoting">
                            <span>Not sent</span>
                          </div>

                          <div v-if="data.state === 'Job' && data.status !== 'Open' && data.status !== 'Cancelled'">
                              <router-link :to="{name: 'order-item', params: { id: data.container_id}}" class="font-weight-bold color-green">OR{{data.container_id}}</router-link>
                            </div>
                            <template v-if="headerData.jobSettings">
                                <div v-if="headerData.jobSettings.check_measure_date">
                                    <svg-icon name="solid/ruler-combined" title="Check measure date"></svg-icon>
                                    {{formatDate(headerData.jobSettings.check_measure_date)}}
                                </div>
                                <div v-if="headerData.jobSettings.installation_date">
                                    <svg-icon name="solid/screwdriver-wrench" title="Installation date"></svg-icon>
                                    {{formatDate(headerData.jobSettings.installation_date)}}
                                </div>
                            </template>

                            <div class="header__status" v-if="!isQuoting && !headerData.jobSettings">
                                <span class="status__label">Reference:</span>

                                <div class="edit-ref tw-flex tw-items-center tw-gap-2" v-click-outside="cancelEditRef">
                                    <div v-if="showEditRef" ref="inputWrapper" class="edit-ref-min-width">
                                        <InputGroup class="testing"  :data="{label: '', placeholder: 'Reference', type: 'text', error: editRefError? ' ': null}" v-model="reference" :focusOnMount="true" @keyup.enter="submitRefEdit" @keyup.esc="cancelEditRef" />

                                    </div>
                                    <span v-else class="status__value" ref="refTextLabel"> {{ reference }}</span>
                                    <div v-if="showEditRef && !refUpdating" class="tw-flex tw-gap-2">
                                        <svg-icon-qouting @click="submitRefEdit" class="tw-cursor-pointer" data="fas fa-check" />
                                        <svg-icon-qouting @click="cancelEditRef" class="tw-cursor-pointer" data="fas fa-times" />
                                    </div>
                                    <svg-icon-qouting v-if="!showEditRef && !refUpdating && ['Open', 'Draft'].includes(data.status) " @click="editRef" class="tw-cursor-pointer" data="fas fa-pen-to-square" />
                                    <Spinner v-if="refUpdating" class="btn__loader" color="var(--PrimaryButtonTextColour)"  />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="tw-ml-auto tw-flex tw-gap-2 md:tw-flex-row-reverse">
                        <div v-if="headerData.jobSettings" class="md:tw-flex tw-flex-col tw-pl-2 tw-gap-2 md:tw-flex-row tw-items-baseline tw-justify-end tw-flex-auto">
                            <div v-if="headerData.jobSettings.modals" class="tw-flex tw-gap-2 md:tw-flex-row tw-items-baseline tw-justify-end tw-flex-auto">
                                <div v-for="s in headerData.jobSettings.modals" :key="s.name" :title="s.name" class="settings_icon" :class="{warning: s.warning}" @click="$emit('jobModal', s.modal)">
                                <svg-icon :name="'solid/' + s.icon" />
                                </div>
                            </div>
                        </div>
                      <div v-else-if="isConvertAvailable" class="tw-flex tw-gap-2 md:tw-flex-row tw-items-baseline tw-justify-end tw-flex-auto">
                          <div title="Convert to a Job" class="settings_icon" @click="$emit('convertToJob')">
                            <svg-icon :name="'solid/user'" />
                          </div>
                      </div>

                        <button class="btn-action d-md-none" @click="showMenu = !showMenu">
                            <span v-show="!showMenu">
                                <svg-icon-qouting data="fas fa-chevron-down" />
                            </span>

                            <span v-show="showMenu">
                                <svg-icon-qouting data="fas fa-chevron-up" />
                            </span>

                        </button>
                    </div>
                </div>

                <div v-if="!isQuoting && data.related_job_id" class="md:tw-flex tw-flex-col tw-pl-2 tw-gap-2 md:tw-flex-row tw-items-baseline tw-justify-end tw-flex-auto">
                    <div>
                    <router-link :to="{name: 'job', parameters: {id: data.container_id}}"
                    class="link-green tw-cursor-pointer"
                    >
                        <svg-icon-qouting data="fas fa-user" />
                        JB{{data.container_id}}</router-link>
                    </div>
                </div>

            </div>
        </div>
    </PageHeader>

</template>

<script>
import Dropdown from './header/Dropdown.vue'
import Buttons from '../elements/Buttons.vue'
import InputGroup from '../../form/InputGroup.vue'
import { useFormatter } from '../../../composables/formatter'
import {ref, onMounted, onBeforeUnmount, computed, watch, nextTick} from 'vue'
import PageHeader from '../../elements/PageHeader.vue'
import dealerOrderService from './../../../services/dealerOrder.service'
import Spinner from '../../elements/Loader.vue'
import vClickOutside from 'click-outside-vue3'
import {dateCalcFormat} from "@/helpers/datetime";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex"
import {isQuestion} from "eslint-plugin-vue/lib/utils/indent-utils";

export default ({
    props: ['data', 'summary', 'className', 'headerData', 'buttons', 'quantityItems', 'isEditable', 'isQuoting'],
    emits: ['jobModal'],
    components: {
        Dropdown,
        Buttons,
        PageHeader,
        InputGroup,
        Spinner,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    setup(props) {
        const formatter = useFormatter()
        const route = useRoute()
        const router = useRouter()
        const store = useStore()

        const lastScrollPosition = ref(0)
        const key = ref(0)
        const headerClassName = ref('')

        const reference = ref(props.data.reference)
        const showEditRef = ref(false)
        const refUpdating = ref(false)
        const updatedReference = ref(props.data.reference)
        const editRefError = ref(false)

        const stateName = computed(() => {
          const localStore = props.data.state === 'Job' ? store.state.jobs : store.state.orders
          if(!localStore) return ''
          const s = localStore.statusList.find(x => x.name === props.data.status)
          return s.value ?? s.name
        })

        const isConvertAvailable = computed(() => {
          return !props.isQuoting &&
           !props.data.related_job_id &&
            props.data.status !== 'Awaiting Payment' &&
            props.data.status !== 'Completed' &&
            props.data.status !== 'Dispatched'
        })

      const qpStateName = computed(() => {
        if (props.isQuoting){
          if (!props.data.is_requested)
            return 'Draft'
          if (props.isEditable){
            return 'Estimate'
          }
          return 'Job'
        }

        return props.data.state
      })
        const submitRefEdit = async () => {
            // if(!reference.value) {
            //     editRefError.value = true
            //     return
            // }

            editRefError.value = false

            refUpdating.value = true
          // console.log(route)

          // const res = await dealerOrderService.updateOrderReference(props.data.container_id,reference.value)
            const res = await dealerOrderService.updateOrderReference(route.params.id,reference.value)
            refUpdating.value = false
            showEditRef.value = false
            updatedReference.value = reference.value
            if(res.error || res.message) {
                reference.value = props.data.reference || ''
            }
            if (route.params.id === 'new'){
              await router.replace({ name: 'order-item', params: {
                  id:  res.order_id
                }
              })
            }
        }

        const inputWrapper = ref(null)
        const refTextLabel = ref(null)

        const cancelEditRef = (e) => {
            reference.value = updatedReference.value
            showEditRef.value = false
        }

        const editRef = () => {
            editRefError.value = false
            const width = refTextLabel.value.clientWidth
            showEditRef.value = true
            nextTick(() => {
                inputWrapper.value.style.width  = `${width*1.5}px`
            })
        }
        // onMounted(() => {
        //     window.addEventListener('scroll', logicStickyHeader)

        // })

        // onBeforeUnmount(() => {
        //     window.removeEventListener('scroll', logicStickyHeader)
        // })

        function logicStickyHeader() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if(currentScrollPosition > 50 &&
            currentScrollPosition < lastScrollPosition.value) headerClassName.value = 'sticky'
            else headerClassName.value = ''

            lastScrollPosition.value = currentScrollPosition
        }

        const actions = computed(() => {
          let array = props.buttons.map(el => el)
          array.forEach((el) => el.event = el.name && el.name.split(' ').join('').toLowerCase() + 'BtnClick')
          array.forEach((el) => el.hide = el.name === 'Send estimate' && !props.isEditable)
          array.forEach((el) => el.hide = el.name === 'Submit order' && props.data.state !== 'Draft' && props.data.status !== 'Draft')
          return array
        })

        watch(() => props.isEditable, (val) => {
          if(!val) key.value ++
        })
        watch(() =>  props.data.state, (val) => {
           key.value ++
        })

        const showMenu = ref(false)

      function formatDate(date, showTime = true){
          return dateCalcFormat(date, showTime, false)
      }

      return {
            formatter,
            headerClassName,
            actions,
            key,
            showMenu,
            reference,
            showEditRef,
            submitRefEdit,
            refUpdating,
            editRef,
            cancelEditRef,
            inputWrapper,
            refTextLabel,
            editRefError,
          formatDate,
        stateName,
        qpStateName,
        isConvertAvailable
        }
    },
})
</script>

<style lang="scss">
    .dealer-ui-order-preview.page-header-global {
        max-width: unset;
        padding: 0;
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;
        box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
    }
</style>

<style scoped lang="scss">
 .dealer-ui-order-preview.page-header-global {
     @media(max-width: 768px) {

         .header__title {
             font-size: 21px;
         }
         .header__status {
             font-size: 13px;
         }

         .header__date {
             font-size: 13px;
         }
      }

    .btn-action {
        border: none;
        background: rgba(11, 53, 83, 0.3);
        color: white;
        width: 32px;
        height: 32px;
        cursor: pointer;
        transition: all .2s linear;
        &:hover {
            background: rgba(11, 53, 83, 0.2);
        }
    }
      .col-gap-1 {
          column-gap: 5px;
      }

      .col-gap-2 {
          column-gap: 10px;
      }
      .icons__wrapper.estimate__btn_back {
          display: flex;
          align-items: center;
      }
    .link__back {
        margin: 0;
        color: var(--LayoutIconPrimary);;
    }
    .header__status {
        margin-top: 0!important;

        .status__value.status {
            color: #BF8A00;
        }
    }
}
.estimate {
    &__header {
        min-height: 165px;
        padding: 20px 0;
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;
        @media(max-width:768px) {
          padding : 10px;
        }
      ::v-deep .actions__item {
        margin-right: 0;
      }
      ::v-deep .dropdown {
        order: -1;
      }
      .header__primary {
        margin-top: 8px;
      }
        &.sticky {
            position: fixed;
            top: 50px;
            width: 100%;
            z-index: 11;
            min-height: auto;
            padding: 0;
            &.hidden {
                display: none;
            }
        }
    }
}
.sticky {
    .header {
        &.container {
         padding: 16px 12px;
        }
        &__status {
            display: none;
        }
    }
    .header__wrapper {
        ::v-deep .dropdown__btn, ::v-deep .btn {
            height: 36px;
        }
        ::v-deep .dropdown__items {
            top: 40px;
        }
        &.container {
            padding: 12px 0;
            @media(max-width: 1200px) {
                padding: 12px;
            }
        }
    }
}
.header {
    width: 100%!important;
    position: relative!important;
    height: auto!important;
    &__sticky_block {
        min-height: 244px;
        width: 100%;
    }
    &__content {
        display: flex;
        justify-content: space-between;
      flex-wrap: wrap;
    }
    &__info {
        text-align: left;
    }
    &__title {
        font-weight: bold;
        font-size: 31px;
        line-height: 100%;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        font-family: Sansation;
        & > span {
            margin-right: 20px;
        }
    }
    &__date {
        font-size: 15px;
        line-height: 80%;
        color: rgba(28, 40, 51, 0.6);
        margin-bottom: 2px;
        @media(max-width: 768px) {
            margin-top: 10px;
        }
    }
    &__status {
        margin-top: 12px;
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 100%;
        &:first-of-type {
            margin-top: 18px;
        }
    }
    &__actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

}

 .settings_icon{
   color: var(--PrimaryButtonTextColour);
   background-color: var(--PrimaryButtonColour);
   padding: 5px;
   font-size: 1.2em;
   cursor: pointer;
   width: 40px;
   height: 40px;
   border-radius: 5px;
   display: flex;
   justify-content: center;
   flex-direction: column-reverse;
   align-items: center;

   &.warning{
       background-color: coral !important;
   }
 }

.status {
    &__label {
        color: rgba(28, 40, 51, 0.6);
    }
    &__value {
        font-weight: bold;
        display: block;
        margin-left: 3px;
    }
}
.actions {
    justify-content: flex-start;
  &__submit {
    margin-bottom: 0;
    flex-wrap: nowrap;
    margin-top: 4px;
    ::v-deep {
      .top__actions {
        display: flex;
        .btn_main {
          display: none;
        }
        .btn_primary {
          display: none;
        }
      }
      //> .btn_primary {
      //  display: none;
      //}
      > .btn_secondary {
        display: none;
      }
      > .btn_additional {
        display: none;
      }
    }
  }
}
.date__icon {
    margin-right: 5px;
}
 @media(max-width: 768px) {
    .sticky {
        display: none;
        .header {
            &__actions {
                flex-direction: column;
                align-items: flex-start;
                .dropdown {
                    margin-top: 20px;
                }
            }
        }
    }
    .header {
        &__content {
            flex-direction: column;
        }
        &__actions {
            margin-top: 20px;
            align-items: flex-start;
        }
    }
}

.header__wrapper.container{
  height: unset !important;
}

.edit-ref-min-width {
    min-width: 120px;
}

:deep .edit-ref {
    input {
        height: auto;
        font-size: 15px;
        padding: 0 4px;
        font-weight: normal;
    }
}

</style>
