<template>
  <SimpleModal class="schedule_modal"
               close-text="Back to open jobs"
               @close="$emit('close')">
    <div class="schedule_modal__header" :class="calendarIsOpened ?  'schedule_modal__header--calendar': ''">
      <slot name="header">
        <h4 class="header__title">When is check measure scheduled with the customer?</h4>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="schedule_modal__input">
            <div class="schedule_modal__label">Date: </div>
            <CalendarDate
              @change-date-value="changeDateValue"
              @calendar-is-opened="function(value) {calendarIsOpened = value}"
              :calendar-is-opened="calendarIsOpened"
              :date-value="dateValue"
              :is-error-date="isErrorDate"
            />
          </div>
          <div class="schedule_modal__input">
            <div class="schedule_modal__label">Time:</div>
            <CalendarTime
              @change-time-value="changeTimeValue"
              @am="function() {activeIsAm = true}"
              @pm="function() {activeIsAm = false}"
              :active-is-am="activeIsAm"
              :time-value="timeValue"
              :is-error-time="isErrorTime"
            />
          </div>
        </div>
      </slot>
    </div>
    <div class="schedule_modal__body" v-show="calendarIsOpened">
      <slot name="body">
        <Calendar
          @pass-date="chooseDate"
          :events="checkMeasureCalendars"
        />
      </slot>
    </div>
    <div class="schedule_modal__footer" :class="calendarIsOpened ?  'schedule_modal__footer--calendar': ''">
      <slot name="footer">
        <Textarea
          placeholder="Notes"
          @input="changeNoteValue"
        ></Textarea>
        <ButtonMain @click="acceptSchedule">
          <svg-icon name="solid/check"/>
          Schedule
        </ButtonMain>
      </slot>
    </div>
  </SimpleModal>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import ButtonMain from "@/components/buttons/ButtonMain";
import SelectDropdown from "@/components/dropdown/SelectDropdown";
import SimpleModal from "@/components/modals/SimpleModal"
import Textarea from "@/components/inputs/Textarea"
import Input from "@/components/inputs/Input";
import Calendar from "@/components/commons/Calendar";
import CalendarTime from "@/components/commons/CalendarTime";
import CalendarDate from "@/components/commons/CalendarDate";
import LinkReturn from "@/components/links/LinkReturn";
import {useStore} from "vuex";
import {dateForRequest, timeForRequest, validateDate,validateTime} from "@/helpers/datetime";

export default ({
  components: {
    ButtonMain,
    SelectDropdown,
    SimpleModal,
    Textarea,
    LinkReturn,
    Calendar,
    CalendarTime,
    CalendarDate,
    Input
  },
  props: ['id'],
  emits: ['close'],
  setup(props, {emit}) {
    const store = useStore()
    const isErrorTime = ref(false)
    const isErrorDate = ref(false)
    const activeIsAm = ref(true)
    const dateValue = ref('')
    const timeValue = ref('')
    const noteValue = ref('')
    const checkMeasureCalendars = computed(() => store.getters['jobs/checkMeasureCalendars'])
    const calendarIsOpened = ref(false)
    let changeDateValue = (value) => {
      isErrorDate.value = false
      dateValue.value = value
    }
    let changeTimeValue = (value) => {
      timeValue.value = value

      let hours = null
      let minutes = null
      const split = value.split(":")

      if(split.length === 2) {
        hours = parseInt(split[0].trim())
        minutes = parseInt(split[1].trim())
        isErrorTime.value = hours && hours > 12 || minutes && minutes > 60
      } else {
        isErrorTime.value = false
      }
    }
    let changeNoteValue = (value) => {
      noteValue.value = value
    }
    let chooseDate = (value) => {
      dateValue.value = value.toLocaleDateString('ru-RU')
    }
    let acceptSchedule = async () => {
      if(!dateValue.value || !timeValue.value) {
        if (!timeValue.value) {
          isErrorTime.value = true
        }
        if (!dateValue.value){
          isErrorDate.value = true
        }
      } else {

        isErrorDate.value = !validateDate(dateValue.value)
        isErrorTime.value = !validateTime(timeValue.value)

        if(isErrorDate.value || isErrorTime.value) return

        let date = dateForRequest(dateValue.value)
        let time = timeForRequest(timeValue.value, activeIsAm.value)
       let response = await store.dispatch('jobs/changeJobsStatus',
          {id: props.id, status: 'update-check-measuring-date', parameters: {
              "date" : date,
              "time" : time,
              "notes" : noteValue.value
            }} )
       if(response && response.data.success)  emit('close')
      }
    }
    onMounted(async () => {
      await store.dispatch('jobs/getCheckMeasureCalendars')
    })
    return {
      acceptSchedule,
      calendarIsOpened,
      dateValue,
      timeValue,
      noteValue,
      changeDateValue,
      changeTimeValue,
      changeNoteValue,
      activeIsAm,
      chooseDate,
      checkMeasureCalendars,
      isErrorTime,
      isErrorDate
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
::v-deep .simple_modal__container {
  padding: 0;
  width: 500px;
}
.schedule_modal {
  &__header {
    padding: 40px 40px 16px;
    background-color: white;
    z-index: 1;
    &--calendar {
      box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
    }
    .header {
      &__title {
        font-weight: bold;
        padding-right: 10px;
        font-size: 25px;
        line-height: 100%;
        color: #1C2833;
        padding-bottom: 30px;
      }
    }
  }
  &__input {
    &:first-child input{
      width: 150px;
    }
    &:last-child input{
      width: 80px;
    }
  }
  &__label {
    font-size: 15px;
    line-height: 100%;
    padding-bottom: 10px;
    letter-spacing: 0.02em;
    color: rgba(28, 40, 51, 0.8)
  }
  &__body {
    padding: 0px 40px 12px;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;
    box-shadow: 0px -3px 3px rgba(28, 40, 51, 0.2);
    ::v-deep .vuecal {
      &__cell {
        &--selected {
          background: rgba(11, 53, 83, 0.2);
        }
        &:hover {
          cursor: pointer;
          background: rgba(11, 53, 83, 0.1);
        }
        &--has-events {
          background: rgba(89, 182, 146, 0.2);
          &:hover {
            background: rgba(89, 182, 146, 0.4)
          }
          &.vuecal__cell--selected {
            background: rgba(89, 182, 146, 0.4)
          }
        }
      }
    }
  }
  &__footer {
    padding: 4px 40px 50px;
    border-radius: 2px;
    ::v-deep button {
      height: 40px;
    }
    &--calendar {
      box-shadow: 0px -3px 3px rgba(28, 40, 51, 0.2);
      padding-top: 20px;

    }
    textarea {
      margin-bottom: 20px;
      min-height: 100px;
    }
  }
}
@media (max-width: 768px) {
  ::v-deep .simple_modal__container {
    padding: 0;
    width: 100%
  }
  .schedule_modal {
    &__header {
      padding: 20px 20px 0px;
      .header {
        &__title {
          font-weight: bold;
          padding-right: 0px;
          font-size: 21px;
          padding-bottom: 40px;
        }
      }
    }
    &__input {
      padding-bottom: 30px;
      display: flex;
      align-items: center;
    }
    &__label {
      width: 50px;
      padding-bottom: 0px;
    }
    &__body {
      padding: 10px 20px 30px;
    }
    &__footer {
      padding: 0px 20px 50px;
      &--calendar {
        padding-top: 30px;
      }
    }
  }
}

</style>
