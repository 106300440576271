import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export function useOptionLogic(props) {
        const store = useStore()

        const layoutAppliesTo = props.items && props.items.appliesTo == 'layout'
        const currentKeyForValues = computed(() => `${props.currentSection}`)

        // if(props.items) getPrice()
        function getData(options, values, groupName = 'default') {
            //key for ui object
            const valuesKey = currentKeyForValues.value
            // set object item
            if(!values.value[valuesKey]) values.value[valuesKey] = 0
            const choices = getChoices(groupName)
            // first get options for ui
            options.value = choices && choices.map(el => {
                return {
                    label: el.name,
                    value: el.id  || el.name,
                    key: el.name,
                    disabled: false,
                    ...el,
                }
            })
            if(!choices) return
            // set selected to ui object
            const selected = getDefaultItem(choices)
            if(selected)  values.value[valuesKey] = selected.id || selected.name
        }

        function getData1(options, values, groupName = 'default') {
          // set object item
          const choices = getChoices(groupName)
          // first get options for ui
          options.value = choices && choices
            .map(el => {
            return {
              label: el.name,
              value: el.id  || el.name,
              key: el.name,
              disabled: false,
              ...el,
            }
          })


          if(!choices) return
          // set selected to ui object
          const selected = getDefaultItem(choices)
          if(selected)  values.value = selected//.id || selected.name
        }


        function selectItem(selectedId, groupName = 'default') {
            const sections = props.selection

            if(layoutAppliesTo) {
               sections.forEach((item, index) => {
                    selectLogic(item, selectedId, groupName, index)
                })
            } else {
                //set value for current section
                const currentSection = props.currentSection
                const item = sections[currentSection]
                selectLogic(item, selectedId, groupName, currentSection)
            }
        }

        function layoutAppliesToLogic() {
            const sections = props.items.sections
        }

        function selectLogic(sectionItem, selectedId, groupName, indexCurrentSection) {

            const choices = getChoices(groupName, sectionItem)
            if (choices.length == 0)
              return

            let selectedValue = choices.find(el => el == selectedId  ||
              (el.name && el.name == selectedId?.name || selectedId?.value?.name))
           if(props.items.optionCode === "MIDMUL"){
             selectedValue = choices.find(el => el.id == selectedId)
           }
           if (selectedValue) {

             //get last selected item and remove
             const lastSelectedValue = choices.find(el => el.default)
             if (lastSelectedValue) delete lastSelectedValue.default

             //set value for selected value
             selectedValue.default = true
             //set price to currentSection
           }

            getPrice(indexCurrentSection)
        }

        // function getCurrentSection(){
        //   const sectionIndex = layoutAppliesTo ? 0 : props.currentSection
        //   return props.selection[sectionIndex]
        // }

        function getChoices(groupName, sectionItem) {
          const sectionIndex = props.currentSection
            const section = sectionItem || props.selection[sectionIndex]
            const option = section.options[props.items.optionCode]
            const group = option?.option_settings && option?.option_settings?.groups ? option?.option_settings?.groups[groupName] : {}
            return group.choices || []
        }

        function getPrice(data, useStore=true) {
            const sectionsPrice = useStore? (store.state.wizard.price || []): []
            const index = !Array.isArray(data) && data
            const sections = props.items && props.items.sections || data
            if(index === 0 || index) {
                const optionName = props.items.optionName
                const optionCode = props.items.optionCode
                //get current option
                let option = sections[index].options[optionCode]
                if (props.items && props.items.optionCode == optionCode)
                  option = props.selection[index].options[optionCode]
                //set price only for current section/optionName
                sectionsPrice[index][optionCode] = sumGroupsItem(optionCode, option)
            } else {
                // store.commit('wizard/setField', { field: 'price', value: sectionsPrice})
                useStore && store.commit('wizard/setField', { field: 'price', value: null})
                // in all section in all groups sum defaults item
                sections.forEach((section, index) => {
                    if(!sectionsPrice[index]) sectionsPrice.push({})
                    for(const key in section.options) {
                        let option = section.options[key]
                        const optionCode = option.option_code
                        if (props.items && props.items.optionCode == optionCode)
                          option = props.selection[index].options[optionCode]

                        sectionsPrice[index][optionCode] = sumGroupsItem(optionCode, option)
                    }
                })
            }
            useStore && store.commit('wizard/setField', { field: 'price', value: sectionsPrice})
            return sectionsPrice;
        }


        function sumGroupsItem(optionCode, option) {
            let sum = 0
            const settings = option?.option_settings
            if(!settings) return sum

            const groups = settings.groups || settings.values
            const choice_price = settings.choice_price

            switch(optionCode) {
              case 'ACC': {
                  settings.forEach(el => {
                    sum += el.price
                  })
                break
              }

                case 'LFTT':
                case 'PSHP': {
                    const qty =  Object.keys(settings.settings).filter(el => settings.settings[el] == true).length
                    sum += qty * choice_price
                    break
                }
                case 'PRTS':
                case 'HOPH': {
                    const qty = settings.choices.filter(el => el.selected).length
                    sum += qty * choice_price

                    break
                }
                case 'ADD': {
                  Object.keys(groups).forEach($key => {
                        groups[$key].choices.forEach(el => {
                            sum += (el.default || el.selected) && el.price || 0
                        })
                    })
                    break
                }
                case 'HNG': {
                    if(!groups) break
                    // const groupHingeType = groups.find(el => el.name == 'hinge_type')
                    // const groupHingeQTY = groups.find(el => el.name == 'hinge_qty')
                    const groupHingeType = groups.hinge_type
                    const groupHingeQTY = groups.hinge_qty

                    //default item hinge type
                    const defaultItemGroupHingeType = getDefaultItem(groupHingeType.choices)
                    //default item hinge qty
                    const defaultItemGroupHingeQTY = getDefaultItem(groupHingeQTY.choices)

                    //get count '1 hinges'
                    const countHingeQTY = defaultItemGroupHingeQTY && defaultItemGroupHingeQTY.components[0].qty || 1
                    //hinge type item * count hinge qty
                    sum += (defaultItemGroupHingeType?.price ?? 0) * parseInt(countHingeQTY)
                    break
                }
                case 'MIDR':
                case 'MULL': {
                    if(!groups) break
                    const selectedItems = groups.filter(el => el.selected)
                    if(selectedItems) {
                        selectedItems.forEach(el => {
                            sum += el.choice_price
                        })
                    }
                    break
                }
              case 'MIDMUL': {
                const selectedType = getDefaultItem(settings.groups.default.choices)
                if (selectedType){
                  let values = settings.groups[selectedType.id].choices
                  const selectedItems = values.filter(el => el.selected)

                  if(selectedItems) {
                    selectedItems.forEach(el => {
                      sum += el.choice_price
                    })
                  }
                }

                break
              }
                case 'PET' : {
                    if(!groups) break
                    //const groupSize = groups.find(el => el.name == 'size')
                    const groupSize = groups.size

                    //default item hinge type
                    const defaultItemGroupSize = getDefaultItem(groupSize.choices)

                    if (defaultItemGroupSize && (defaultItemGroupSize.price || 0 != 0)) {

//                        const groupFlap = groups.find(el => el.name == 'flap')
                        const groupFlap = groups.flap

                        const defaultItemGroupFlap = getDefaultItem(groupFlap.choices)

                        sum += (defaultItemGroupSize.price || 0) + (defaultItemGroupFlap.price || 0)

                    }
                    break;
                }
                default: {
                    if(!groups) break

                    Object.keys(groups).forEach($key => {
                        const defaultItem = getDefaultItem(groups[$key].choices)
                        if(defaultItem) sum += defaultItem.price || defaultItem.choice_price || 0
                      }
                    )

                    // groups.forEach(group => {
                    //     const defaultItem = getDefaultItem(group.choices)
                    //     if(defaultItem) sum += defaultItem.price || defaultItem.choice_price || 0
                    // })
                }
            }
// console.log(optionCode + '  == ' + sum)
            return sum
        }

        function selectColor(colorId, currentItem, groupName) {
            const section = props.selection[props.currentSection]
            const choices = getChoices(groupName, section)
            if(choices){
              const item = choices.find(el => el.id == currentItem || el.name == currentItem)
              if(item) item.colour = colorId
            }else if(groupName && section.options[groupName]) {
              section.options[groupName].option_settings[currentItem].values.forEach((value) => {
                value.colour = colorId
              })
            }
        }

        function getColorGroup(groupName) {
            const currentChoices = getChoices(groupName)
            const defaultItem = getDefaultItem(currentChoices)
            return defaultItem?.colour_group
        }

        function getDefaultItem(choices) {
            if(!choices) return
            let item = choices.find(el => el.default || el.selected)
            if(!item) item = choices.find(el => el.is_default || el.is_selected)
            return item
        }

        function setPriceForASingleChoice(index, optionCode, option) {
            const sectionsPrice = store.state.wizard.price || []
            sectionsPrice[index][optionCode] = sumGroupsItem(optionCode, option)
        }

        return {
            layoutAppliesTo,
            getData,
          getData1,
            selectItem,
            currentKeyForValues,
            getPrice,
            selectColor,
            getColorGroup,
          sumGroupsItem,
          // getCurrentSection,
          setPriceForASingleChoice
        }
}
