<template>
    <RequestTemplate
        title="Please verify your email"
        desc="In order to request an estimate please complete your email verification process. Please check Spam (or Junk) folder in your inbox. If you don’t receive confirmation email please check it is correct in My Account section.">
        <div class="email-field">
          <InputGroup type="text"
                      :data="{ label: 'Registered Email Address:', placeholder: '', type: 'text', required: true, error: '', value: user.email, disabled: true}"
                      v-model="user.email"
          />
          <p class="note">(If the email is incorrect, please <router-link to="/update-email-address" class="link-green">UPDATE IT by clicking here</router-link> before proceeding)</p>
        </div>
      <Button class="resend__btn" :data="btnResend" @click="resend"/>
    </RequestTemplate>
</template>

<script>
import Button from '../../form/Button.vue'
import RequestTemplate from './RequestTemplate'
import api from '../../../services/request.service'
import { notify } from "@kyvg/vue3-notification"
import InputGroup from '../../form/InputGroup'

import {reactive, ref} from 'vue'
export default ({
    components: {
        Button,
        RequestTemplate,
      InputGroup
    },
    setup(props, { emit }) {
        const btnResend = reactive({ title: 'Re-SEND Confirmation', type: 'primary', loading: false })
        const user = ref(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')))

        async function resend() {
            const result = await api.resendVerifyEmail()
            btnResend.loading = false
            if(result.success) {
                notify({
                    type: 'success',
                    title: 'check your mail'
                })
                emit('close')
            }
        }

        return {
            btnResend,
          user,
            resend
        }
    },
})
</script>


<style scoped lang="scss">
.template {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__btn {
        margin-top: 20px;
    }
    &__title {
        font-weight: bold;
        font-size: 25px;
        line-height: 100%;
    }
    &__icon {
        // color: yellow;
        // display: block;
    }
    &__desc {
        font-size: 15px;
        line-height: 140%;
    }
}
.email-field{
  max-width: 90%;
  margin: auto;
  padding-bottom: 30px;
}

.note :deep{
  font-size: 0.7rem;
}

</style>
