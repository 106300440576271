<script setup>

import { defineProps, defineEmits, ref } from 'vue'

const props = defineProps({
    items: {
        type: Array,
    },
    selected: {
        type: Object,
    },
    disabled: {
        type: Boolean,
        default: false,
    }
})

const emits = defineEmits(['onSelected'])

const show = ref(false)
const itemSelected = (item) => {
    emits('onSelected', item)
    show.value = false
}

</script>

<template>
    <div class="tw-relative" :class="{'tw-pointer-events-none tw-opacity-50': disabled}">
        <div v-if="show" class="tw-fixed tw-inset-0 tw-z-1" @click="show = false"></div>
        <div @click="show = !show" class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-text-sm dp-green">
            <span class="tw-leading-none tw-font-bold">{{ selected?.name && selected?.id >= 0? `Select all ${selected?.name}`: selected? selected?.name: 'Select' }}</span>
            <span class="tw-transition-all tw-transform tw-translate-0" :class="{'tw-rotate-180': show}">
                <svg-icon-qouting class="chevron tw-text-xs" data="fas fa-chevron-down" />
            </span>
        </div>
        
        <div v-if="show" class="tw-absolute tw-bg-white tw-mt-2 tw-py-2 tw-right-0 tw-shadow-sm tw-border tw-border-solid tw-border-gray-200 tw-text-sm tw-w-max tw-divide-y tw-divide-solid tw-divide-gray-200">
            <div v-for="item in props.items" :key="item.id" class="tw-cursor-pointer">
                <div class="tw-py-1.5 hover:tw-bg-gray-100 tw-px-2 tw-transition-all" @click="itemSelected(item)">{{ item.id >= 0? `Select all ${item.name}`: item?.name }}</div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.dp-green {
    color: rgba(89, 182, 146, 0.8);;
    border-bottom: 1px dashed rgba(89, 182, 146, 0.8);
}

</style>